import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import type { ResidentHistoryData } from 'common_parts';
import React from 'react';

type OwnProps = {
  data: ResidentHistoryData[];
  token?: string;
};

type ParentProps = {
  data: ResidentHistoryData;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}));

const SiblingRow = (props: ParentProps): JSX.Element => {
  const { data } = props;
  const {
    start,
    end,
    address,
    postalCode,
    visa,
  } = data;

  return (
    <TableRow>
      <TableCell align="center">{postalCode}</TableCell>
      <TableCell align="center">{address}</TableCell>
      <TableCell align="center">{start}</TableCell>
      <TableCell align="center">{end}</TableCell>
      <TableCell align="center">{visa}</TableCell>
    </TableRow>
  );
};

const ResidentHistoryTable = (props: OwnProps): JSX.Element => {
  const { data } = props;
  const classes = useStyles();
  const cellClass = `${classes.head} ${classes.body}`;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={cellClass} align="center">Postal Code</TableCell>
            <TableCell className={cellClass} align="center">Address</TableCell>
            <TableCell className={cellClass} align="center">Start</TableCell>
            <TableCell className={cellClass} align="center">End</TableCell>
            <TableCell className={cellClass} align="center">Visa</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((resident, index) => (
            <SiblingRow key={index} data={resident} />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ResidentHistoryTable;
