import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { WithStyles } from '@material-ui/core/styles/withStyles';
/* eslint-disable import/no-extraneous-dependencies */
import {
  ApplicantFormData,
  GeneralData,
  SpouseData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import RegisterFormApi from '../../../apis/registerForm';
import {
  AppContainerStyle,
  commonStyle,
} from '../../../assets/styles';
import IntlMessage from '../../../intl';
import RegisterFormMessages from '../../../intl/messages/views/RegisterForm';
import { AppState } from '../../../modules';
import { CanadaVisitState } from '../../../modules/canadaVisit';
import { ChildState } from '../../../modules/child';
import { EducationState } from '../../../modules/education';
import {
  GeneralState,
  positionType,
  setGeneral,
} from '../../../modules/general';
import { LanguageState } from '../../../modules/language';
import { LocaleState } from '../../../modules/locale';
import { ParentState } from '../../../modules/parent';
import { PersonState } from '../../../modules/person';
import { PreviousSpouseState } from '../../../modules/previousSpouse';
import { ResidentHistoryState } from '../../../modules/residentHistory';
import { SiblingState } from '../../../modules/sibling';
import { TravelHistoryState } from '../../../modules/travelHistory';
import { WorkExperienceState } from '../../../modules/workExperience';
import Title from '../../application/Title';
import EditableLabel from '../parts/EditableLabel';

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
  person: state.person,
  general: state.general,
  parent: state.parent,
  sibling: state.sibling,
  education: state.education,
  travelHistory: state.travelHistory,
  residentHistory: state.residentHistory,
  workExperience: state.workExperience,
  canadaVisitInfo: state.canadaVisitInfo,
  language: state.language,
  children: state.children,
  previousSpouse: state.previousSpouse,
});

interface Props extends WithStyles<typeof commonStyle> {
  handleBack: () => void;
  locale: LocaleState;
  person: PersonState;
  general: GeneralState;
  parent: ParentState;
  sibling: SiblingState;
  education: EducationState;
  travelHistory: TravelHistoryState;
  residentHistory: ResidentHistoryState;
  workExperience: WorkExperienceState;
  canadaVisitInfo: CanadaVisitState;
  language: LanguageState;
  children: ChildState;
  previousSpouse: PreviousSpouseState;
  setGeneralData: typeof setGeneral;
}

const ThanksForm = (props: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [isSending, setSendingStatus] = useState(false);
  const {
    locale,
    person,
    general,
    parent,
    sibling,
    education,
    travelHistory,
    residentHistory,
    workExperience,
    canadaVisitInfo,
    language,
    children,
    previousSpouse,
    handleBack,
    classes,
    setGeneralData,
  } = props;
  const intl = new IntlMessage(locale.langCode);

  const handleOpen = () => {
    setOpen(true);
  };

  const setGeneralWrapper = (position: positionType, label: keyof GeneralData) => (value: any) => {
    setGeneralData(position, label, value);
  };

  const sendData = async () => {
    setSendingStatus(true);

    const { marriageCondition, visaType } = person.condition;
    const isMarried = marriageCondition === 'married'
      || marriageCondition === 'divorcedAndCurrentlyMarried';

    let data: ApplicantFormData = {
      visaType,
      marriageCondition,
      general: general.applicant,
      father: parent.applicant.father,
      mother: parent.applicant.mother,
      siblings: sibling.applicant.siblings,
      educations: education.applicant.educations,
      travelHistory:
      travelHistory.applicant.travelHistory,
      residentHistory:
      residentHistory.applicant.residentHistory,
      workExperiences: workExperience.applicant.workExperiences,
      canadaVisitInfo: canadaVisitInfo.applicant,
      language: language.applicant,
      children: children.children,
      previousSpouses: previousSpouse.applicant.previousSpouses,
    };

    if (isMarried) {
      const spouse: SpouseData = {
        general: general.spouse,
        father: parent.spouse.father,
        mother: parent.spouse.mother,
        siblings: sibling.spouse.siblings,
        educations: education.spouse.educations,
        travelHistory:
        travelHistory.spouse.travelHistory,
        residentHistory:
        residentHistory.spouse.residentHistory,
        workExperiences: workExperience.spouse.workExperiences,
        canadaVisitInfo: canadaVisitInfo.spouse,
        language: language.spouse,
        previousSpouses: previousSpouse.spouse.previousSpouses,
      };

      data = {
        ...data,
        spouse,
      };
    }

    const result = await RegisterFormApi.create(data);
    if (result.message === 'success') {
      handleOpen();
    } else {
      // error
    }
    setSendingStatus(false);
  };

  return (
    <div style={AppContainerStyle}>
      <Title text={intl.format(RegisterFormMessages.thanks)} />
      <Typography variant="body1" gutterBottom>
        {intl.format(RegisterFormMessages.confirmation)}
      </Typography>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        <p style={{ fontWeight: 'bold', margin: 0 }}>
          {intl.format(RegisterFormMessages.email)}
        </p>
        <EditableLabel
          data={general.applicant.email}
          setTargetAction={setGeneralWrapper('applicant', 'email')}
        />
      </div>
      <div className={classes.sectionBottom}>
        <Button className={classes.backButton} onClick={handleBack}>
          {intl.format(RegisterFormMessages.back)}
        </Button>
        <Button variant="contained" color="primary" onClick={sendData} disabled={isSending}>
          {
            isSending
              ? <CircularProgress size={24} />
              : intl.format(RegisterFormMessages.register)
          }
        </Button>
      </div>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={isOpen}
      >
        <DialogTitle id="alert-dialog-title">
          {intl.format(RegisterFormMessages.registered)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.format(RegisterFormMessages.registeredMessage)}
          </DialogContentText>
          <DialogContentText>
            {intl.format(RegisterFormMessages.closePage)}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connect(
  mapStateToProps,
  { setGeneralData: setGeneral },
)(withStyles(commonStyle)(ThanksForm));
