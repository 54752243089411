import {
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';

interface CardHeaderProps {
  title: string;
  edit?: any;
}

const EditableCardHeader = (props: CardHeaderProps): JSX.Element => {
  const { title, edit } = props;

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      {
        edit
        && (
          <Grid item style={{ marginTop: '5px' }}>
            <IconButton size="small" aria-label="edit" onClick={edit}>
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        )
      }
    </Grid>
  );
};

export default EditableCardHeader;
