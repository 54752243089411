import Divider from '@material-ui/core/Divider/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import { LanguageData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies
import React from 'react';
import { ChangeEventWithName } from '../../../abbreviation-types';
import IntlMessage from '../../../intl';
import { LanguageMessages } from '../../../intl/messages';
import { positionType } from '../../../modules/general';
import { LanguageState } from '../../../modules/language';
import { LocaleState } from '../../../modules/locale';
import SubTitle from '../../application/SubTitle';
import Title from '../../application/Title';
import LanguageScore from '../parts/LanguageScore';

interface Props {
  position: positionType;
  locale: LocaleState;
  data: LanguageState;
  setData: (type: keyof LanguageData) => (event: ChangeEventWithName) => void;
}

const LanguageForm = (props: Props): JSX.Element => {
  const {
    data,
    locale,
    position,
    setData,
  } = props;
  const { english, french } = position === 'applicant' ? data.applicant : data.spouse;
  const intl = new IntlMessage(locale.langCode);

  return (
    <div>
      <Title text={intl.format(LanguageMessages.languageInformation)} />
      <SubTitle text={intl.format(LanguageMessages.english)} />
      <div style={{ marginBottom: 12 }}>
        <FormControlLabel
          control={(
            <Radio
              id="testName"
              checked={english && english.testName === 'celpip'}
              onChange={setData('english')}
              value="celpip"
            />
          )}
          label={intl.format(LanguageMessages.celpip)}
        />
        <FormControlLabel
          control={(
            <Radio
              id="testName"
              checked={english && english.testName === 'ielts'}
              onChange={setData('english')}
              value="ielts"
            />
          )}
          label={intl.format(LanguageMessages.ielts)}
        />
        <FormControlLabel
          control={(
            <Radio
              id="testName"
              checked={english && english.testName === 'clb'}
              onChange={setData('english')}
              value="clb"
            />
          )}
          label={intl.format(LanguageMessages.clb)}
        />
        <FormControlLabel
          control={(
            <Radio
              id="testName"
              checked={english && english.testName === 'none'}
              onChange={setData('english')}
              value="none"
            />
          )}
          label={intl.format(LanguageMessages.none)}
        />
      </div>
      <LanguageScore
        languageKey="english"
        setData={setData}
        data={english}
      />
      <Divider variant="fullWidth" style={{ marginTop: 36, marginBottom: 36 }} />
      <SubTitle text={intl.format(LanguageMessages.frenchCLB)} />
      <LanguageScore
        languageKey="french"
        setData={setData}
        data={french}
      />
    </div>
  );
};

export default LanguageForm;
