import { defineMessages } from 'react-intl';
import japanese from '../../locales/ja.json';

const RegisterInfoMessages = defineMessages({
  title: {
    id: 'info.title',
    defaultMessage: japanese['info.title'],
  },
  greeting: {
    id: 'info.greeting',
    defaultMessage: japanese['info.greeting'],
  },
  frogGreeting: {
    id: 'info.frogGreeting',
    defaultMessage: japanese['info.frogGreeting'],
  },
  introduction: {
    id: 'info.introduction',
    defaultMessage: japanese['info.introduction'],
  },
  subtitle: {
    id: 'info.subtitle',
    defaultMessage: japanese['info.subtitle'],
  },
  familyInformation: {
    id: 'info.familyInformation',
    defaultMessage: japanese['info.familyInformation'],
  },
  educationHistory: {
    id: 'info.educationHistory',
    defaultMessage: japanese['info.educationHistory'],
  },
  workHistory: {
    id: 'info.workHistory',
    defaultMessage: japanese['info.workHistory'],
  },
  travelHistory: {
    id: 'info.travelHistory',
    defaultMessage: japanese['info.travelHistory'],
  },
  getStarted: {
    id: 'info.getStarted',
    defaultMessage: japanese['info.getStarted'],
  },
});

export default RegisterInfoMessages;
