/* eslint-disable import/no-extraneous-dependencies */
import {
  ApplicantFormData,
  AuthorityPermission,
  ResponseData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import Request from './request';

const editUrl = 'edit';

export default class EditApi {
  // eslint-disable-next-line max-len
  public static async get(token: string): Promise<ResponseData<{ formData: ApplicantFormData, permission: AuthorityPermission }>> {
    return Request.get(`${editUrl}/${token}`);
  }

  public static async update(
    token: string,
    data: ApplicantFormData,
  ): Promise<ResponseData<ApplicantFormData>> {
    return Request.post(`${editUrl}/${token}`, data);
  }
}
