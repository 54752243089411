import { GeneralData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies

const SET_GENERAL = 'SET_GENERAL';
export type positionType = 'applicant' | 'spouse';

export interface GeneralState {
  applicant: GeneralData;
  spouse: GeneralData;
}

interface SetGeneralAction {
  type: typeof SET_GENERAL;
  position: positionType;
  label: keyof GeneralData;
  payload: any;
}

type GeneralActionTypes = SetGeneralAction;

const initialState: GeneralState = {
  applicant: {
    lastName: '',
    firstName: '',
    middleName: '',
    lastNameInMotherLanguage: '',
    firstNameInMotherLanguage: '',
    middleNameInMotherLanguage: '',
    birthDate: '',
    birthCountry: '',
    birthCity: '',
    height: 0,
    eyeColor: '',
    currentAddress: '',
    email: '',
    homePhone: '',
    cellPhone: '',
    military: false,
    militaryDescription: '',
    criminal: false,
    criminalDescription: '',
    visaRefusal: false,
    visaRefusalDescription: '',
  },
  spouse: {
    lastName: '',
    firstName: '',
    middleName: '',
    lastNameInMotherLanguage: '',
    firstNameInMotherLanguage: '',
    middleNameInMotherLanguage: '',
    birthDate: '',
    birthCountry: '',
    birthCity: '',
    height: 0,
    eyeColor: '',
    currentAddress: '',
    email: '',
    homePhone: '',
    cellPhone: '',
    military: false,
    militaryDescription: '',
    criminal: false,
    criminalDescription: '',
    visaRefusal: false,
    visaRefusalDescription: '',
  },
};

export function GeneralReducer(
  state = initialState,
  action: SetGeneralAction,
): GeneralState {
  switch (action.type) {
    case SET_GENERAL:
      if (action.position === 'applicant') {
        return {
          applicant: {
            ...state.applicant,
            [action.label]: action.payload,
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          ...state.spouse,
          [action.label]: action.payload,
        },
      };
    default:
      return state;
  }
}

export function setGeneral(
  position: positionType,
  label: keyof GeneralData,
  value: any,
): GeneralActionTypes {
  return {
    type: SET_GENERAL,
    position,
    label,
    payload: value,
  };
}
