import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const WorkExperienceMessages = defineMessages({
  companyName: {
    id: 'workExperience.companyName',
    defaultMessage: Japanese['workExperience.companyName'],
  },
  address: {
    id: 'workExperience.address',
    defaultMessage: Japanese['workExperience.address'],
  },
  addressExample: {
    id: 'workExperience.addressExample',
    defaultMessage: Japanese['workExperience.addressExample'],
  },
  workHourPerWeek: {
    id: 'workExperience.workHourPerWeek',
    defaultMessage: Japanese['workExperience.workHourPerWeek'],
  },
  occupation: {
    id: 'workExperience.occupation',
    defaultMessage: Japanese['workExperience.occupation'],
  },
  occupationHelper: {
    id: 'workExperience.occupationHelper',
    defaultMessage: Japanese['workExperience.occupationHelper'],
  },
  annualSalary: {
    id: 'workExperience.annualSalary',
    defaultMessage: Japanese['workExperience.annualSalary'],
  },
  managerOrSupervisor: {
    id: 'workExperience.managerOrSupervisor',
    defaultMessage: Japanese['workExperience.managerOrSupervisor'],
  },
  workExperience: {
    id: 'workExperience.workExperience',
    defaultMessage: Japanese['workExperience.workExperience'],
  },
  dontHaveExperience: {
    id: 'workExperience.dontHaveExperience',
    defaultMessage: Japanese['workExperience.dontHaveExperience'],
  },
  add: {
    id: 'workExperience.add',
    defaultMessage: Japanese['workExperience.add'],
  },
  remove: {
    id: 'workExperience.remove',
    defaultMessage: Japanese['workExperience.remove'],
  },
  sort: {
    id: 'workExperience.sort',
    defaultMessage: Japanese['workExperience.sort'],
  },
  less15hours: {
    id: 'workExperience.less15hours',
    defaultMessage: Japanese['workExperience.less15hours'],
  },
  less30hours: {
    id: 'workExperience.less30hours',
    defaultMessage: Japanese['workExperience.less30hours'],
  },
  above30hours: {
    id: 'workExperience.above30hours',
    defaultMessage: Japanese['workExperience.above30hours'],
  },
  visa: {
    id: 'workExperience.visa',
    defaultMessage: Japanese['workExperience.visa'],
  },
  studyPermit: {
    id: 'workExperience.studyPermit',
    defaultMessage: Japanese['workExperience.studyPermit'],
  },
  workPermit: {
    id: 'workExperience.workPermit',
    defaultMessage: Japanese['workExperience.workPermit'],
  },
  permanentResidence: {
    id: 'workExperience.permanentResidence',
    defaultMessage: Japanese['workExperience.permanentResidence'],
  },
  citizenship: {
    id: 'workExperience.citizenship',
    defaultMessage: Japanese['workExperience.citizenship'],
  },
  noPermit: {
    id: 'workExperience.noPermit',
    defaultMessage: Japanese['workExperience.noPermit'],
  },
});

export default WorkExperienceMessages;
