import { ResidentHistoryData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies

const SET_RESIDENT_HISTORY = 'SET_RESIDENT_HISTORY';
const ADD_ARRAY_RESIDENT = 'ADD_ARRAY_RESIDENT';
const REMOVE_ARRAY_RESIDENT = 'REMOVE_ARRAY_RESIDENT';
const SORT_ARRAY_RESIDENT = 'SORT_ARRAY_RESIDENT';
export type positionType = 'applicant' | 'spouse';

export interface ResidentHistoryState {
  applicant: {
    residentHistory: ResidentHistoryData[];
  };
  spouse: {
    residentHistory: ResidentHistoryData[];
  };
}

interface SetResidentHistoryAction {
  type: typeof SET_RESIDENT_HISTORY;
  position: positionType;
  index: number;
  label: keyof ResidentHistoryData;
  payload: any;
}

interface AddArrayAction {
  type: typeof ADD_ARRAY_RESIDENT;
  position: positionType;
}

interface RemoveArrayAction {
  type: typeof REMOVE_ARRAY_RESIDENT;
  position: positionType;
  index: number;
}

interface SortArrayAction {
  type: typeof SORT_ARRAY_RESIDENT;
  position: positionType;
}

type ResidentHistoryActionTypes =
  SetResidentHistoryAction
  | AddArrayAction
  | RemoveArrayAction
  | SortArrayAction;

const initialState: ResidentHistoryState = {
  applicant: {
    residentHistory: [{
      start: '',
      end: '',
      address: '',
      city: '',
      province: '',
      country: '',
      postalCode: '',
      visa: '',
    }],
  },
  spouse: {
    residentHistory: [{
      start: '',
      end: '',
      address: '',
      city: '',
      province: '',
      country: '',
      postalCode: '',
      visa: '',
    }],
  },
};

function add(residentHistoryData: ResidentHistoryData[]): ResidentHistoryData[] {
  const newArray = residentHistoryData.slice();
  newArray.push({
    start: '',
    end: '',
    address: '',
    city: '',
    province: '',
    country: '',
    postalCode: '',
    visa: '',
  });
  return newArray;
}

function remove(residentHistoryData: ResidentHistoryData[], index: number): ResidentHistoryData[] {
  const newArray = residentHistoryData.slice();
  newArray.splice(index, 1);
  return newArray;
}

/**
 * TODO: Commonality
 * Sort the received ResidentHistoryData array in the following order:
 *   1. "start" has been entered but "end" has not been entered
 *   2. when "start" and "end" are entered, sort "start" date by date
 *   3. "start" has not been entered
 *
 * @param residentHistoryData
 */
function sort(residentHistoryData: ResidentHistoryData[]): ResidentHistoryData[] {
  const newArray = residentHistoryData.slice();

  return newArray.sort((a, b) => {
    if (a.start && !a.end) {
      return -1;
    }
    if (b.start && !b.end) {
      return 1;
    }

    if (!a.start) {
      return 1;
    }
    if (!b.start) {
      return -1;
    }

    return new Date(b.start).getTime() - new Date(a.start).getTime();
  });
}

export function ResidentHistoryReducer(
  state = initialState,
  action: ResidentHistoryActionTypes,
): ResidentHistoryState {
  switch (action.type) {
    case SET_RESIDENT_HISTORY: {
      const {
        index, label, payload, position,
      } = action;
      if (position === 'applicant') {
        const newState = state.applicant.residentHistory.slice();
        newState[index] = {
          ...newState[index],
          [label]: payload,
        };
        return {
          applicant: {
            residentHistory: newState,
          },
          spouse: state.spouse,
        };
      }
      const newState = state.spouse.residentHistory.slice();
      newState[index] = {
        ...newState[index],
        [label]: payload,
      };
      return {
        applicant: state.applicant,
        spouse: {
          residentHistory: newState,
        },
      };
    }
    case ADD_ARRAY_RESIDENT:
      if (action.position === 'applicant') {
        return {
          applicant: {
            residentHistory: add(state.applicant.residentHistory),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          residentHistory: add(state.spouse.residentHistory),
        },
      };
    case REMOVE_ARRAY_RESIDENT:
      if (action.position === 'applicant') {
        return {
          applicant: {
            residentHistory: remove(state.applicant.residentHistory, action.index),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          residentHistory: remove(state.spouse.residentHistory, action.index),
        },
      };
    case SORT_ARRAY_RESIDENT:
      if (action.position === 'applicant') {
        return {
          applicant: {
            residentHistory: sort(state.applicant.residentHistory),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          residentHistory: sort(state.spouse.residentHistory),
        },
      };
    default:
      return state;
  }
}

export function setResidentHistory(
  position: positionType,
  index: number,
  label: keyof ResidentHistoryData,
  value: any,
): ResidentHistoryActionTypes {
  return {
    type: SET_RESIDENT_HISTORY,
    position,
    index,
    label,
    payload: value,
  };
}

export function addArray(
  position: positionType,
): ResidentHistoryActionTypes {
  return {
    type: ADD_ARRAY_RESIDENT,
    position,
  };
}

export function removeArray(
  position: positionType,
  index: number,
): ResidentHistoryActionTypes {
  return {
    type: REMOVE_ARRAY_RESIDENT,
    position,
    index,
  };
}

export function sortArray(
  position: positionType,
): ResidentHistoryActionTypes {
  return {
    type: SORT_ARRAY_RESIDENT,
    position,
  };
}
