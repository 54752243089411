import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
/* eslint-disable import/no-extraneous-dependencies */
import {
  LanguageData,
  LanguageScoreData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, {
  useEffect,
  useState,
} from 'react';
import { ChangeEventWithId } from '../../abbreviation-types';
import RegisterFormApi from '../../apis/registerForm';
import ApplicantDetailsContext from '../../contexts/applicantDetails';
import { positionType } from '../../modules/general';
import { LocaleState } from '../../modules/locale';
import LanguageForm from '../forms/register/Language';
import EditableCardHeader from './EditableCardHeader';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme: Theme) => createStyles({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

interface Props {
  data: LanguageData;
  position: positionType;
}

function createTableBodyDOMs(data?: LanguageScoreData): JSX.Element {
  return data
    ? (
      <TableRow>
        <StyledTableCell>{data.testName ? data.testName.toUpperCase() : ''}</StyledTableCell>
        <StyledTableCell>{data.listeningScore}</StyledTableCell>
        <StyledTableCell>{data.readingScore}</StyledTableCell>
        <StyledTableCell>{data.writingScore}</StyledTableCell>
        <StyledTableCell>{data.speakingScore}</StyledTableCell>
      </TableRow>
    )
    : <TableRow />;
}

const LanguageExamScores = (props: Props): JSX.Element => {
  const { data, position } = props;
  const classes = useStyles();
  const { allData, updateData } = React.useContext(ApplicantDetailsContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [english, setEnglish] = useState<LanguageScoreData>();
  const [french, setFrench] = useState<LanguageScoreData>();
  const locale: LocaleState = { langCode: 'en' };

  useEffect((): void => {
    if (data) {
      setEnglish(data.english);
      setFrench(data.french);
    }
  }, [data, isOpen]);

  const handleEdit = (): void => {
    setIsOpen(true);
  };

  const handleCancel = (): void => {
    if (data) {
      setEnglish(data.english);
      setFrench(data.french);
    }

    setIsOpen(false);
  };

  const handleSave = async (): Promise<void> => {
    const language: LanguageData = {
      english: english || {},
      french: french || {},
    };

    // Send data to DB
    if (position === 'applicant') {
      const payroll = {
        ...allData,
        language,
      };

      updateData(payroll);

      if (payroll._id) {
        await RegisterFormApi.update(payroll._id, payroll);
      } else {
        // eslint-disable-next-line no-console
        console.error('payroll._id is null | undefined !!');
      }
    } else if (allData && allData.spouse) {
      const payroll = {
        ...allData,
        spouse: {
          ...allData.spouse,
          language,
        },
      };

      updateData(payroll);

      if (payroll._id) {
        await RegisterFormApi.update(payroll._id, payroll);
      } else {
        // eslint-disable-next-line no-console
        console.error('payroll._id is null | undefined !!');
      }
    }

    setIsOpen(false);
  };

  // eslint-disable-next-line max-len
  const handleChange = (type: keyof LanguageData): (event: ChangeEventWithId) => void => (event: ChangeEventWithId): void => {
    const id = event.target.id || '';
    const { value } = event.target;

    const label = id && id.split('-')[0];
    const prevValue = (type === 'english') ? english : french;
    const action = (type === 'english') ? setEnglish : setFrench;

    if (label === 'testName') {
      action({
        ...prevValue,
        [label]: value,
      } as LanguageScoreData);
    } else {
      action({
        ...prevValue,
        [label]: Number(value),
      });
    }
  };

  return (
    <>
      <EditableCardHeader title="Language" edit={handleEdit} />
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>English Test Name</StyledTableCell>
              <StyledTableCell>Listening</StyledTableCell>
              <StyledTableCell>Reading</StyledTableCell>
              <StyledTableCell>Writing</StyledTableCell>
              <StyledTableCell>Speaking</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && createTableBodyDOMs(english)}
          </TableBody>
        </Table>
      </Paper>

      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>French Test Name</StyledTableCell>
              <StyledTableCell>Listening</StyledTableCell>
              <StyledTableCell>Reading</StyledTableCell>
              <StyledTableCell>Writing</StyledTableCell>
              <StyledTableCell>Speaking</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && createTableBodyDOMs(french)}
          </TableBody>
        </Table>
      </Paper>
      {
        isOpen
        && (
          <Dialog open={isOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Language</DialogTitle>
            <DialogContent>
              {
                data
                && (
                  <LanguageForm
                    position={position}
                    locale={locale}
                    data={{
                      applicant: {
                        english: english || {},
                        french: french || {},
                      },
                      spouse: {
                        english: english || {},
                        french: french || {},
                      },
                    }}
                    setData={handleChange}
                  />
                )
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
};

export default LanguageExamScores;
