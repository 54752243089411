export function getURLParams(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

export function getModeParam(): string {
  return getURLParams().get('mode') || '';
}

export function isFrogMode(): boolean {
  return !!getModeParam().match(/^frog$/);
}
