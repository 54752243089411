import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';
import React from 'react';
import { connect } from 'react-redux';
import { ChangeEventWithName } from '../../../abbreviation-types';
import IntlMessage from '../../../intl';
import AdministrativeDivisionsMessages from '../../../intl/messages/components/forms/parts/AdministrativeDivisions';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
});

interface Props {
  setData: (event: ChangeEventWithName) => void;
  city?: string;
  province?: string;
  country?: string;
  locale: LocaleState;
}

const AdministrativeDivisions = (props: Props) => {
  const {
    setData, city, province, country, locale,
  } = props;
  const intl = new IntlMessage(locale.langCode);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            name="city"
            label={intl.format(AdministrativeDivisionsMessages.city)}
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={setData}
            value={city}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="province"
            label={intl.format(AdministrativeDivisionsMessages.province)}
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={setData}
            value={province}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="country"
            label={intl.format(AdministrativeDivisionsMessages.country)}
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={setData}
            value={country}
          />
        </Grid>
      </Grid>
    </>
  );
};

AdministrativeDivisions.defaultProps = {
  city: '',
  province: '',
  country: '',
};

export default connect(mapStateToProps, {})(AdministrativeDivisions);
