import {
  MenuItem,
  Select,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import {
  WithStyles,
  withStyles,
} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router';
import { commonStyle } from '../../assets/styles';
import IntelMessage, { convertStringToLocaleLabel } from '../../intl';
import { HeaderMessages } from '../../intl/messages';
import { AppState } from '../../modules';
import {
  changeLocale,
  LocaleState,
} from '../../modules/locale';
import {
  getURLParams,
  isFrogMode,
} from '../../utils/params';

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
});

interface Props extends RouteComponentProps<{}>, WithStyles<typeof commonStyle> {
  locale: LocaleState;
  sendData: typeof changeLocale;
}

const frogCompanyName = 'Frog Creator Production Inc.';

const Header = (props: Props) => {
  const {
    classes,
    locale,
    sendData,
    history,
  } = props;
  const intl = new IntelMessage(locale.langCode);

  const moveToFirstPage = () => {
    const queryString = getURLParams().toString();
    const params = queryString ? `?${queryString}` : '';
    history.push(`/${params}`);
  };

  const changeLanguage = (event: React.ChangeEvent<{ value: unknown }>) => {
    sendData(convertStringToLocaleLabel(event.target.value));
  };

  const typographyStyle = (): CSSProperties => (
    isFrogMode() ? { cursor: 'pointer', color: 'rgb(247, 246,240)' } : { cursor: 'pointer' }
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" classes={{ colorPrimary: classes.appBarColorDefault }}>
        <Toolbar variant="dense">
          <Typography
            variant="subtitle1"
            color="inherit"
            onClick={moveToFirstPage}
            style={typographyStyle()}
          >
            {isFrogMode() ? frogCompanyName : intl.format(HeaderMessages.companyName)}
          </Typography>
          <Select
            id="language-select"
            value={locale.langCode}
            onChange={changeLanguage}
            style={{ color: 'inherit', marginLeft: 'auto' }}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="ja">日本語</MenuItem>
            <MenuItem value="zh-cmn-Hans">简体中文</MenuItem>
          </Select>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default connect(
  mapStateToProps,
  { sendData: changeLocale },
)(withStyles(commonStyle)(withRouter(Header)));
