import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const ApplicantMessages = defineMessages({
  applicant: {
    id: 'applicant.applicant',
    defaultMessage: Japanese['applicant.applicant'],
  },
  parentsLabel: {
    id: 'applicant.parentsLabel',
    defaultMessage: Japanese['applicant.parentsLabel'],
  },
  father: {
    id: 'applicant.father',
    defaultMessage: Japanese['applicant.father'],
  },
  mother: {
    id: 'applicant.mother',
    defaultMessage: Japanese['applicant.mother'],
  },
  applicantSection: {
    id: 'applicant.applicantSection',
    defaultMessage: Japanese['applicant.applicantSection'],
  },
  applicantCompleted: {
    id: 'applicant.applicantCompleted',
    defaultMessage: Japanese['applicant.applicantCompleted'],
  },
  back: {
    id: 'applicant.back',
    defaultMessage: Japanese['applicant.back'],
  },
  next: {
    id: 'applicant.next',
    defaultMessage: Japanese['applicant.next'],
  },
  goNext: {
    id: 'applicant.goNext',
    defaultMessage: Japanese['applicant.goNext'],
  },
  spouse: {
    id: 'applicant.spouse',
    defaultMessage: Japanese['applicant.spouse'],
  },
  children: {
    id: 'applicant.children',
    defaultMessage: Japanese['applicant.children'],
  },
});

export default ApplicantMessages;
