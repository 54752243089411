import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { format, isValid } from 'date-fns';
/* eslint-disable import/no-extraneous-dependencies */
import { ResidentHistoryData } from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, {
  useEffect,
  useState,
} from 'react';
import { ChangeEventWithName } from '../../../abbreviation-types';
import RegisterFormApi from '../../../apis/registerForm';
import ApplicantDetailsContext from '../../../contexts/applicantDetails';
import { positionType } from '../../../modules/general';
import { PRESENT } from '../../../utils/constants';
import { sortHistorical } from '../../../utils/sort';
import EditableCardHeader from '../EditableCardHeader';
import ResidentHistoryForm from './ResidentHistoryForm';
import ResidentHistoryTable from './ResidentHistoryTable';

type OwnProps = {
  position: positionType;
  residentHistory?: ResidentHistoryData[];
};

const initResidentHistory = {
  start: '',
  end: '',
  address: '',
  city: '',
  province: '',
  country: '',
  postalCode: '',
  visa: '',
};

const ResidentHistory = (props: OwnProps): JSX.Element => {
  const {
    position,
    residentHistory,
  } = props;
  const { allData, updateData } = React.useContext(ApplicantDetailsContext);
  const residentHistoryInitialState: ResidentHistoryData[] = [];
  const [
    residentHistoryData,
    setResidentHistoryData,
  ] = useState<ResidentHistoryData[]>(residentHistoryInitialState);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoadingStatus] = useState<boolean>(false);

  useEffect((): void => {
    if (residentHistory) setResidentHistoryData([...residentHistory]);
  }, [residentHistory, isOpen]);

  const handleEdit = (): void => {
    setIsOpen(true);
  };

  const handleCancel = (): void => {
    if (residentHistory) setResidentHistoryData(residentHistory);
    setIsOpen(false);
  };

  const handleSave = async (): Promise<void> => {
    // Send data to DB
    let payroll = { ...allData };
    if (position === 'applicant') {
      payroll = {
        ...allData,
        residentHistory: residentHistoryData,
      };
    }
    if (position === 'spouse' && allData && allData.spouse) {
      payroll = {
        ...allData,
        spouse: {
          ...allData.spouse,
          residentHistory: residentHistoryData,
        },
      };
    }

    updateData(payroll);

    try {
      setLoadingStatus(true);
      if (payroll._id) {
        await RegisterFormApi.update(payroll._id, payroll);
      } else {
        // eslint-disable-next-line no-console
        console.error('payroll._id is null | undefined !!');
      }
    } catch (e) {
      // TODO: Error Handling
      // if reached this area, the result is probably 404 status
      alert('Some error has occurred: ApplicantParentsUpdate applicant');
    } finally {
      setLoadingStatus(false);
    }

    setIsOpen(false);
  };

  const handleChange = (index: number) => (event: ChangeEventWithName): void => {
    const name = event.target.name || '';
    const { value } = event.target;

    if (!name) return;

    const newResidentHistoryData = [...residentHistoryData];
    newResidentHistoryData[index] = {
      ...newResidentHistoryData[index],
      [name]: value,
    };

    setResidentHistoryData(newResidentHistoryData);
  };

  const setDuration = (index: number) => (name: 'start' | 'end') => (date: Date | typeof PRESENT | null) => {
    const newResidentHistoryData = [...residentHistoryData];

    if (!date) {
      newResidentHistoryData[index] = {
        ...newResidentHistoryData[index],
        [name]: null,
      };

      setResidentHistoryData(newResidentHistoryData);
    } else if (date === PRESENT) {
      newResidentHistoryData[index] = {
        ...newResidentHistoryData[index],
        [name]: date,
      };

      setResidentHistoryData(newResidentHistoryData);
    } else if (isValid(date)) {
      newResidentHistoryData[index] = {
        ...newResidentHistoryData[index],
        [name]: format(date, 'yyyy-MM-dd'),
      };
      setResidentHistoryData(newResidentHistoryData);
    }
  };

  const addArrayData = (): void => {
    setResidentHistoryData([...residentHistoryData, initResidentHistory]);
  };

  const sortArrayData = (): void => {
    const newArray = [...residentHistoryData];
    const sortedArray = sortHistorical(newArray);

    setResidentHistoryData(sortedArray);
  };

  const removeArrayData = (index: number): void => {
    const newArray = [...residentHistoryData];
    newArray.splice(index, 1);

    setResidentHistoryData(newArray);
  };

  return (
    <>
      <EditableCardHeader title="Resident History" edit={handleEdit} />
      <ResidentHistoryTable data={residentHistoryData} />
      {
        isOpen
        && (
          <Dialog open={isOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit ResidentHistory</DialogTitle>
            <DialogContent>
              <ResidentHistoryForm
                data={residentHistoryData}
                addArrayData={addArrayData}
                sortArrayData={sortArrayData}
                removeArrayData={removeArrayData}
                setData={handleChange}
                setDuration={setDuration}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} disabled={isLoading}>Cancel</Button>
              <Button onClick={handleSave} color="primary" disabled={isLoading}>Save</Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
};

export default ResidentHistory;
