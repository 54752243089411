import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import type { ParentData } from 'common_parts';
import React from 'react';
import IntlMessage from '../../../intl';
import { NameMessages } from '../../../intl/messages';

type OwnProps = {
  father: ParentData;
  mother: ParentData;
  token?: string;
};

type ParentProps = {
  relationLabel: string;
  data: ParentData;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}));

const ParentRow = (props: ParentProps): JSX.Element => {
  const { relationLabel, data } = props;
  const {
    firstName,
    firstNameInMotherLanguage,
    lastName,
    lastNameInMotherLanguage,
    middleName,
    middleNameInMotherLanguage,
    originalFamilyName,
    originalFamilyNameInMotherLanguage,
    email,
    birthCountry,
    birthCity,
    currentAddress,
    birthDate,
    deathDate,
    marriageCondition,
  } = data;
  const intl = new IntlMessage('en');

  return (
    <TableRow>
      <TableCell align="center">{relationLabel}</TableCell>
      <TableCell align="center">
        <ruby>
          {firstNameInMotherLanguage}
          <rt>{firstName}</rt>
          {
            middleName && middleNameInMotherLanguage
            && (
              <>
                (
                {middleNameInMotherLanguage}
                <rt>{middleName}</rt>
                )
              </>
            )
          }
          {lastNameInMotherLanguage}
          <rt>{lastName}</rt>
          {
            originalFamilyName && originalFamilyNameInMotherLanguage
            && (
              <>
                [
                {originalFamilyNameInMotherLanguage}
                <rt>{intl.format(NameMessages.originalFamilyName)}</rt>
                ]
              </>
            )
          }
        </ruby>
      </TableCell>
      <TableCell align="center">{birthDate}</TableCell>
      <TableCell align="center">
        {birthCity ? `${birthCity},` : ''}
        {birthCountry}
      </TableCell>
      <TableCell align="center">{email}</TableCell>
      <TableCell align="center">{currentAddress}</TableCell>
      <TableCell align="center">{marriageCondition}</TableCell>
      <TableCell align="center">{deathDate}</TableCell>
    </TableRow>
  );
};

const ParentsInformation = (props: OwnProps): JSX.Element => {
  const { father, mother } = props;
  const classes = useStyles();
  const cellClass = `${classes.head} ${classes.body}`;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={cellClass} align="center">Relation</TableCell>
            <TableCell className={cellClass} align="center">Name</TableCell>
            <TableCell className={cellClass} align="center">Date of Birth</TableCell>
            <TableCell className={cellClass} align="center">Place of Birth</TableCell>
            <TableCell className={cellClass} align="center">Email</TableCell>
            <TableCell className={cellClass} align="center">Present Address</TableCell>
            <TableCell className={cellClass} align="center">Marriage Condition</TableCell>
            <TableCell className={cellClass} align="center">Date of Death, if applicable</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ParentRow relationLabel="Father" data={father} />
          <ParentRow relationLabel="Mother" data={mother} />
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ParentsInformation;
