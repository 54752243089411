import {
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import IntlMessage from '../../../intl';
import { RegisterTypeMessages } from '../../../intl/messages';

import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';
import ErrorMessage from '../../application/ErrorMessage';

const mapStateToProps = (state: AppState): {
  locale: LocaleState;
} => ({
  locale: state.locale,
});

interface Props {
  locale: LocaleState;
  // TODO: change to marriageTypes
  value?: string;
  setValue: (stepName: React.ChangeEvent<{ name: string; value: unknown }>) => void;
  required?: boolean;
}

const MarriageCondition = (props: Props): JSX.Element => {
  const {
    locale,
    value = '',
    setValue,
    required = false,
  } = props;
  const intl = new IntlMessage(locale.langCode);

  return (
    <div>
      <FormControlLabel
        control={(
          <Radio
            name="marriageCondition"
            checked={value === 'neverMarried'}
            onChange={setValue}
            value="neverMarried"
          />
        )}
        label={intl.format(RegisterTypeMessages.neverMarried)}
      />
      <FormControlLabel
        control={(
          <Radio
            name="marriageCondition"
            checked={value === 'married'}
            onChange={setValue}
            value="married"
          />
        )}
        label={intl.format(RegisterTypeMessages.married)}
      />
      <FormControlLabel
        control={(
          <Radio
            name="marriageCondition"
            checked={value === 'divorced'}
            onChange={setValue}
            value="divorced"
          />
        )}
        label={intl.format(RegisterTypeMessages.divorced)}
      />
      <FormControlLabel
        control={(
          <Radio
            name="marriageCondition"
            checked={value === 'divorcedAndCurrentlyMarried'}
            onChange={setValue}
            value="divorcedAndCurrentlyMarried"
          />
        )}
        label={intl.format(RegisterTypeMessages.remarried)}
      />
      {
        required && !value
        && <ErrorMessage text={intl.format(RegisterTypeMessages.errorMarriageCondition)} />
      }
    </div>
  );
};

export default connect(mapStateToProps, {})(MarriageCondition);
