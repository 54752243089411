import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router';
import ApplicantForm from '../components/forms/register/Applicant';
import ChildrenForm from '../components/forms/register/Children';
import SpouseForm from '../components/forms/register/Spouse';
import ThanksForm from '../components/forms/register/Thanks';
import { AppState } from '../modules';
import { PersonState } from '../modules/person';
import { getURLParams } from '../utils/params';

const mapStateToProps = (state: AppState) => ({
  person: state.person,
});

interface PathParamsType {
  param1: string;
}

interface Props extends RouteComponentProps<PathParamsType> {
  person: PersonState;
}

interface State {
  activeStep: number;
  forms: any[];
}

class RegisterForm extends Component<Props, State> {
  public state = {
    activeStep: 0,
    forms: [] as any[],
  };

  public constructor(props: Props) {
    super(props);

    const { forms } = this.state;
    // initialization
    forms.splice(0, forms.length);
    forms.push((<ApplicantForm handleNext={this.handleNext} />));
  }

  public componentDidMount() {
    const { person, history } = this.props;
    const { visaType, marriageCondition, hasChild } = person.condition;

    if (!visaType || !marriageCondition || hasChild === undefined) {
      const queryString = getURLParams().toString();
      const params = queryString ? `?${queryString}` : '';
      history.push(`/${params}`);
    }

    if (marriageCondition === 'married' || marriageCondition === 'divorcedAndCurrentlyMarried') {
      this.setState(state => ({
        forms: [
          ...state.forms,
          (<SpouseForm handleNext={this.handleNext} handleBack={this.handleBack} />),
        ],
      }));
    }

    if (hasChild) {
      this.setState(state => ({
        forms: [
          ...state.forms,
          (<ChildrenForm handleNext={this.handleNext} handleBack={this.handleBack} />),
        ],
      }));
    }

    this.setState(state => ({
      forms: [
        ...state.forms,
        <ThanksForm handleBack={this.handleBack} />,
      ],
    }));
  }

  private handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  private handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  private getForm = (step: number) => {
    const { forms } = this.state;
    const { [step]: result } = forms;

    return result;
  };

  public render() {
    const { activeStep } = this.state;
    const formDOM = this.getForm(activeStep);

    return (
      <React.Fragment>
        {formDOM}
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, {})(RegisterForm));
