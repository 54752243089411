/* eslint-disable import/no-extraneous-dependencies */
import {
  ApplicantEditingAuthorityData,
  AuthorityPermission,
  EditingAuthorityResponse,
  ResponseData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import Request from './request';

export default class PublishApi {
  public static async get(id: string): Promise<ResponseData<EditingAuthorityResponse>> {
    return Request.get(`publish/${id}`);
  }

  public static async getAll(): Promise<ResponseData<ApplicantEditingAuthorityData[]>> {
    return Request.get('publish');
  }

  public static async create(applicantId: string): Promise<ResponseData<EditingAuthorityResponse>> {
    return Request.post('publish', { applicantId });
  }

  public static async update(
    applicantId: string,
    permission: AuthorityPermission,
  ): Promise<ResponseData<EditingAuthorityResponse>> {
    return Request.put('publish', { applicantId, permission });
  }

  public static async delete(id: string): Promise<ResponseData<string>> {
    return Request.delete(`publish/${id}`);
  }
}
