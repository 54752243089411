import {
  withStyles,
  WithStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import Hidden from '@material-ui/core/Hidden';
import MobileStepper from '@material-ui/core/MobileStepper/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { format, isValid } from 'date-fns';
/* eslint-disable import/no-extraneous-dependencies */
import {
  CanadaVisitData,
  ChildData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, {
  ChangeEvent,
  Component,
} from 'react';
import { connect } from 'react-redux';
import { ChangeEventWithName } from '../../../abbreviation-types';
import { commonStyle } from '../../../assets/styles';
import IntlMessage from '../../../intl';
import { ChildrenMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import {
  addArray,
  ChildState,
  removeArray,
  setCanadaVisitOriginalChild,
  setCanadaVisitRecentChild,
  setChild,
} from '../../../modules/child';
import { LocaleState } from '../../../modules/locale';
import { PersonState } from '../../../modules/person';
import Title from '../../application/Title';
import ChildrenList from './ChildrenList';

const mapStateToProps = (state: AppState) => ({
  person: state.person,
  locale: state.locale,
  data: state.children,
});

type TargetElements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export interface Props extends WithStyles<typeof commonStyle> {
  handleNext: () => void;
  handleBack: () => void;
  person: PersonState;
  locale: LocaleState;
  data: ChildState;
  setChildData: typeof setChild;
  addArrayData: typeof addArray;
  removeArrayData: typeof removeArray;
  setCanadaVisitOriginalData: typeof setCanadaVisitOriginalChild;
  setCanadaVisitRecentData: typeof setCanadaVisitRecentChild;
}

class ChildrenForm extends Component<Props> {
  private setData = (index: number) => (event: ChangeEventWithName) => {
    const { value, name } = event.target;
    const { setChildData } = this.props;

    if (Object.prototype.hasOwnProperty.call(event.target, 'checked')) {
      setChildData(index, (name as keyof ChildData), value === 'true');
    } else {
      setChildData(index, (name as keyof ChildData), value);
    }
  };

  private setBirthDate = (index: number) => (date: Date | null) => {
    if (isValid(date)) {
      const { setChildData } = this.props;
      const name = 'birthDate' as keyof ChildData;
      setChildData(index, (name as keyof ChildData), date ? format(date, 'yyyy-MM-dd') : null);
    }
  };

  // eslint-disable-next-line max-len
  private setCanadaVisitData = (type: keyof CanadaVisitData, index: number) => (event: ChangeEvent<TargetElements>) => {
    const { value, id } = event.target;
    const label = id.split('-')[0];
    const { setCanadaVisitOriginalData, setCanadaVisitRecentData } = this.props;
    const action = type === 'originalEntry' ? setCanadaVisitOriginalData : setCanadaVisitRecentData;

    if (Object.prototype.hasOwnProperty.call(event.target, 'checked')) {
      action(index, label, value === 'true');
    } else {
      action(index, label, value);
    }
  };

  private setCanadaVisitDateData = (type: keyof CanadaVisitData, index: number, name: string) => (date: Date | null) => {
    if (isValid(date)) {
      const { setCanadaVisitOriginalData, setCanadaVisitRecentData } = this.props;
      const action = type === 'originalEntry' ? setCanadaVisitOriginalData : setCanadaVisitRecentData;

      action(index, name, date ? format(date, 'yyyy-MM-dd') : null);
    }
  };

  public render() {
    const {
      classes,
      handleBack,
      handleNext,
      person,
      locale,
      data,
      addArrayData,
      removeArrayData,
    } = this.props;

    const { visaType, marriageCondition } = person.condition;
    const intl = new IntlMessage(locale.langCode);

    return (
      <div>
        <Grid container>
          <Hidden xsDown>
            <Grid item xs={12} sm={3}>
              <nav className={classes.control}>
                <p>{intl.format(ChildrenMessages.applicant)}</p>
                {
                  (
                    marriageCondition === 'married'
                    || marriageCondition === 'divorcedAndCurrentlyMarried'
                  )
                  && <p>{intl.format(ChildrenMessages.spouse)}</p>
                }
                <p>{intl.format(ChildrenMessages.children)}</p>
              </nav>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={9} className={classes.formBg}>
            <div className={classes.main}>
              <Title text={intl.format(ChildrenMessages.childInformation)} />
              <ChildrenList
                visaType={visaType}
                data={data}
                locale={locale}
                addArrayData={addArrayData}
                removeArrayData={removeArrayData}
                setCanadaVisitData={this.setCanadaVisitData}
                setData={this.setData}
                setBirthDate={this.setBirthDate}
                setCanadaVisitDateData={this.setCanadaVisitDateData}
              />
              <div>
                <div className={classes.sectionBottom}>
                  <Hidden xsDown>
                    <div>
                      <Button
                        onClick={handleBack}
                        className={classes.backButton}
                      >
                        {intl.format(ChildrenMessages.back)}
                      </Button>
                      <Button variant="contained" color="primary" onClick={handleNext}>
                        {intl.format(ChildrenMessages.next)}
                      </Button>
                    </div>
                  </Hidden>
                  <Hidden smUp>
                    <MobileStepper
                      variant="dots"
                      steps={1}
                      position="static"
                      activeStep={1}
                      nextButton={(
                        <Button size="small" onClick={handleNext}>
                          {intl.format(ChildrenMessages.next)}
                          <KeyboardArrowRight />
                        </Button>
                      )}
                      backButton={(
                        <Button size="small" onClick={handleBack}>
                          <KeyboardArrowLeft />
                          {intl.format(ChildrenMessages.back)}
                        </Button>
                      )}
                    />
                  </Hidden>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  {
    setChildData: setChild,
    addArrayData: addArray,
    removeArrayData: removeArray,
    setCanadaVisitOriginalData: setCanadaVisitOriginalChild,
    setCanadaVisitRecentData: setCanadaVisitRecentChild,
  },
)(withStyles(commonStyle)(ChildrenForm));
