const SET_CONDITION = 'SET_CONDITION';

export type visaTypes = 'study' | 'immigration';
export type marriageTypes = 'neverMarried' | 'married' | 'divorced' | 'divorcedAndCurrentlyMarried';

export interface PersonCondition {
  visaType?: visaTypes;
  marriageCondition?: marriageTypes;
  hasChild?: boolean;
}

export interface PersonState {
  condition: PersonCondition;
}

interface SendPersonConditionAction {
  type: typeof SET_CONDITION;
  label: keyof PersonCondition;
  payload: PersonCondition;
}

type PersonActionTypes = SendPersonConditionAction;

const initialState: PersonState = {
  condition: {
    visaType: undefined,
    marriageCondition: undefined,
    hasChild: undefined,
  },
};

export function PersonReducer(
  state = initialState,
  action: PersonActionTypes,
): PersonState {
  switch (action.type) {
    case SET_CONDITION:
      return {
        condition: {
          ...state.condition,
          [action.label]: action.payload,
        },
      };
    default:
      return state;
  }
}

export function setCondition(label: keyof PersonCondition, value: any): PersonActionTypes {
  return {
    type: SET_CONDITION,
    label,
    payload: value,
  };
}
