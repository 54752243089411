import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  content: {
    fontFamily: '-apple-system,BlinkMacSystemFont, Segoe UI, Helvetica Neue, Yu Gothic, YuGothic, Meiryo, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
    padding: '0 24px 24px',
  },
});

export interface Props {
  open: boolean;
  title: string;
  content: string;
  onClose: () => void;
}

const SimpleDialog = (props: Props): JSX.Element => {
  const {
    open,
    title,
    content,
    onClose,
  } = props;
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent className={classes.content}>{content}</DialogContent>
    </Dialog>
  );
};

export default SimpleDialog;
