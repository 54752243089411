import type {
  EducationData,
  PersonalHistoryData,
  ResidentHistoryData,
  WorkExperienceData,
} from 'common_parts';
import React from 'react';
import { getFormattedDate } from '../../../utils/format';
import { sortHistorical } from '../../../utils/sort';
import EditableCardHeader from '../EditableCardHeader';
import {
  createPersonalHistoryBase,
  fillInHistoryGaps,
} from './functions';
import PersonalHistoryTable from './PersonalHistoryTable';

type Props = {
  birthday?: string,
  educationData?: EducationData[];
  residentHistoryData?: ResidentHistoryData[];
  workExperienceData?: WorkExperienceData[];
};

const PersonalHistory = (props: Props) => {
  const {
    birthday,
    educationData,
    residentHistoryData,
    workExperienceData,
  } = props;
  const basePersonalHistory = createPersonalHistoryBase(educationData, workExperienceData);
  const sortedBasePersonalHistory = sortHistorical(basePersonalHistory) as PersonalHistoryData[];
  const tenYearsAgo = new Date();
  tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10);
  const eighteenthBirthday = new Date(`${birthday} 12:00:00`);
  eighteenthBirthday.setFullYear(eighteenthBirthday.getFullYear() + 18);
  const olderLimitDate = eighteenthBirthday >= tenYearsAgo ? eighteenthBirthday : tenYearsAgo;

  const personalHistory = fillInHistoryGaps(
    sortedBasePersonalHistory,
    residentHistoryData || [],
    getFormattedDate(new Date()),
    getFormattedDate(olderLimitDate),
  );

  return (
    <>
      <EditableCardHeader title="Personal History (Histories Summary)" />
      <PersonalHistoryTable
        data={personalHistory}
      />
    </>
  );
};

PersonalHistory.defaultProps = {
  educationData: [],
  residentHistoryData: [],
  workExperienceData: [],
};

export default PersonalHistory;
