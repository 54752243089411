import { combineReducers } from 'redux';
import { AuthReducer } from './auth';
import { CanadaVisitReducer } from './canadaVisit';
import { ChildReducer } from './child';
import { EducationReducer } from './education';
import { GeneralReducer } from './general';
import { LanguageReducer } from './language';
import { LocaleReducer } from './locale';
import { ParentReducer } from './parent';
import { PersonReducer } from './person';
import { ResidentHistoryReducer } from './residentHistory';
import { SiblingReducer } from './sibling';
import { TravelHistoryReducer } from './travelHistory';
import { WorkExperienceReducer } from './workExperience';
import { PreviousSpouseReducer } from './previousSpouse';

const rootReducer = combineReducers({
  person: PersonReducer,
  locale: LocaleReducer,
  general: GeneralReducer,
  parent: ParentReducer,
  sibling: SiblingReducer,
  education: EducationReducer,
  travelHistory: TravelHistoryReducer,
  residentHistory: ResidentHistoryReducer,
  workExperience: WorkExperienceReducer,
  canadaVisitInfo: CanadaVisitReducer,
  language: LanguageReducer,
  children: ChildReducer,
  previousSpouse: PreviousSpouseReducer,
  auth: AuthReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
