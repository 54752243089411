import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import React from 'react';
import { connect } from 'react-redux';
import IntlMessage from '../../../intl';
import { PlaceMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
});

interface Props {
  label: string;
  category: 'birth' | '';
  setData: (stepName: React.ChangeEvent<{ name: string; value: unknown }>) => void;
  country?: string;
  city?: string;
  locale: LocaleState;
}

const Place = (props: Props) => {
  const {
    label, category, setData, country, city, locale,
  } = props;
  const intl = new IntlMessage(locale.langCode);

  let countryId = 'country';
  let cityId = 'city';
  if (category) {
    countryId = `${category}Country`;
    cityId = `${category}City`;
  }

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormLabel style={{ fontSize: '0.8em' }}>{label}</FormLabel>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '8px' }}>
        <Grid item xs={12} sm={4}>
          <TextField
            name={countryId}
            label={intl.format(PlaceMessages.country)}
            variant="outlined"
            fullWidth
            onChange={setData}
            value={country}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={cityId}
            label={intl.format(PlaceMessages.city)}
            variant="outlined"
            fullWidth
            onChange={setData}
            value={city}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, {})(Place);
