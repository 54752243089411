/* eslint-disable import/no-extraneous-dependencies */
import { ResponseData } from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import Request from './request';

export default class UserApi {
  public static async me(): Promise<ResponseData<boolean>> {
    return Request.get('user/me');
  }
}
