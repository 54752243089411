import { SiblingData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies

const SET_SIBLING = 'SET_SIBLING';
const SET_IS_NOTHING_SIBLING = 'SET_IS_NOTHING_SIBLING';
const ADD_ARRAY_SIBLING = 'ADD_ARRAY_SIBLING';
const REMOVE_ARRAY_SIBLING = 'REMOVE_ARRAY_SIBLING';
export type positionType = 'applicant' | 'spouse';

export interface SiblingState {
  applicant: {
    isNothing: boolean;
    siblings: SiblingData[];
  };
  spouse: {
    isNothing: boolean;
    siblings: SiblingData[];
  };
}

interface SetSiblingAction {
  type: typeof SET_SIBLING;
  position: positionType;
  index: number;
  label: keyof SiblingData;
  payload: any;
}

interface SetIsNothingAction {
  type: typeof SET_IS_NOTHING_SIBLING;
  position: positionType;
  payload: any;
}

interface AddArrayAction {
  type: typeof ADD_ARRAY_SIBLING;
  position: positionType;
}

interface RemoveArrayAction {
  type: typeof REMOVE_ARRAY_SIBLING;
  position: positionType;
  index: number;
}

type SiblingActionTypes =
  | SetSiblingAction
  | SetIsNothingAction
  | AddArrayAction
  | RemoveArrayAction;

const initialState: SiblingState = {
  applicant: {
    isNothing: false,
    siblings: [{
      lastName: '',
      firstName: '',
      middleName: '',
      lastNameInMotherLanguage: '',
      firstNameInMotherLanguage: '',
      middleNameInMotherLanguage: '',
      birthDate: '',
      birthCountry: '',
      birthCity: '',
      marriageCondition: '',
      currentAddress: '',
      email: '',
      relationship: '',
    }],
  },
  spouse: {
    isNothing: false,
    siblings: [{
      lastName: '',
      firstName: '',
      middleName: '',
      lastNameInMotherLanguage: '',
      firstNameInMotherLanguage: '',
      middleNameInMotherLanguage: '',
      birthDate: '',
      birthCountry: '',
      birthCity: '',
      marriageCondition: '',
      currentAddress: '',
      email: '',
      relationship: '',
    }],
  },
};

function add(siblingData: SiblingData[]): SiblingData[] {
  const newArray = siblingData.slice();
  newArray.push({
    lastName: '',
    firstName: '',
    middleName: '',
    lastNameInMotherLanguage: '',
    firstNameInMotherLanguage: '',
    middleNameInMotherLanguage: '',
    birthDate: '',
    birthCountry: '',
    birthCity: '',
    marriageCondition: '',
    currentAddress: '',
    email: '',
    relationship: '',
  });
  return newArray;
}

function remove(siblingData: SiblingData[], index: number): SiblingData[] {
  const newArray = siblingData.slice();
  newArray.splice(index, 1);
  return newArray;
}

export function SiblingReducer(
  state = initialState,
  action: SiblingActionTypes,
): SiblingState {
  switch (action.type) {
    case SET_SIBLING: {
      const {
        index, label, payload, position,
      } = action;
      if (position === 'applicant') {
        const newState = state.applicant.siblings.slice();
        newState[index] = {
          ...newState[index],
          [label]: payload,
        };
        return {
          applicant: {
            isNothing: state.applicant.isNothing,
            siblings: newState,
          },
          spouse: state.spouse,
        };
      }
      const newState = state.spouse.siblings.slice();
      newState[index] = {
        ...newState[index],
        [label]: payload,
      };
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: state.spouse.isNothing,
          siblings: newState,
        },
      };
    }
    case SET_IS_NOTHING_SIBLING:
      if (action.position === 'applicant') {
        return {
          applicant: {
            isNothing: action.payload,
            siblings: state.applicant.siblings,
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: action.payload,
          siblings: state.spouse.siblings,
        },
      };
    case ADD_ARRAY_SIBLING:
      if (action.position === 'applicant') {
        return {
          applicant: {
            isNothing: state.applicant.isNothing,
            siblings: add(state.applicant.siblings),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: state.spouse.isNothing,
          siblings: add(state.spouse.siblings),
        },
      };
    case REMOVE_ARRAY_SIBLING:
      if (action.position === 'applicant') {
        return {
          applicant: {
            isNothing: state.applicant.isNothing,
            siblings: remove(state.applicant.siblings, action.index),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: state.spouse.isNothing,
          siblings: remove(state.spouse.siblings, action.index),
        },
      };
    default:
      return state;
  }
}

export function setSibling(
  position: positionType,
  index: number,
  label: keyof SiblingData,
  value: any,
): SiblingActionTypes {
  return {
    type: SET_SIBLING,
    position,
    index,
    label,
    payload: value,
  };
}

export function setIsNothing(
  position: positionType,
  value: any,
): SiblingActionTypes {
  return {
    type: SET_IS_NOTHING_SIBLING,
    position,
    payload: value,
  };
}

export function addArray(
  position: positionType,
): SiblingActionTypes {
  return {
    type: ADD_ARRAY_SIBLING,
    position,
  };
}

export function removeArray(
  position: positionType,
  index: number,
): SiblingActionTypes {
  return {
    type: REMOVE_ARRAY_SIBLING,
    position,
    index,
  };
}
