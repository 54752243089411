import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const AdministrativeDivisionsMessages = defineMessages({
  city: {
    id: 'place.city',
    defaultMessage: Japanese['place.city'],
  },
  province: {
    id: 'place.province',
    defaultMessage: Japanese['place.province'],
  },
  country: {
    id: 'place.country',
    defaultMessage: Japanese['place.country'],
  },
});

export default AdministrativeDivisionsMessages;
