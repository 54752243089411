import { LocaleLabel } from '../intl';

const CHANGE_LOCALE = 'CHANGE_LOCALE';

export interface LocaleState {
  langCode: LocaleLabel;
}

interface ChangeLocaleAction {
  type: typeof CHANGE_LOCALE;
  payload: LocaleLabel;
}

export function LocaleReducer(
  state: LocaleState = {
    langCode: 'en',
  },
  action: ChangeLocaleAction,
): LocaleState {
  if (action.type === CHANGE_LOCALE) {
    return {
      langCode: action.payload,
    };
  }
  return state;
}

export function changeLocale(newLangCode: LocaleLabel): ChangeLocaleAction {
  return {
    type: CHANGE_LOCALE,
    payload: newLangCode,
  };
}
