import { Theme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, {
  ChangeEvent,
  Component,
} from 'react';
import { connect } from 'react-redux';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router';
import AuthApi from '../apis/auth';
import { AppContainerStyle } from '../assets/styles';
import SimpleDialog from '../components/application/SimpleDialog';
import { AppState } from '../modules';
import { setIsLoggedIn } from '../modules/auth';
import { getURLParams } from '../utils/params';

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

interface Props extends RouteComponentProps<{}> {
  theme: Theme;
  isLoggedIn: boolean;
  setIsLoggedInRedux: typeof setIsLoggedIn;
}

interface State {
  email: string;
  password: string;
  isErrorDialogOpen: boolean;
}

class Login extends Component<Props, State> {
  public state = {
    email: '',
    password: '',
    isErrorDialogOpen: false,
  };

  private handlerClick = async (): Promise<void> => {
    const { history, setIsLoggedInRedux } = this.props;
    const { email, password } = this.state;

    try {
      const response = await AuthApi.login({
        email,
        password,
      });

      if (response.message === 'success') {
        setIsLoggedInRedux(true);

        const urlParams = getURLParams();
        const redirectURL = urlParams.get('redirect');

        history.push(redirectURL || '/home');
      } else {
        // Login Failed
        setIsLoggedInRedux(false);
        this.setState({
          isErrorDialogOpen: true,
        });
      }
    } catch (e) {
      // Login Failed
      setIsLoggedInRedux(false);
      this.setState({
        isErrorDialogOpen: true,
      });
    }
  };

  private handleChange = (event: ChangeEvent<{ name: string; value?: unknown }>) => {
    const { email, password, isErrorDialogOpen } = this.state;
    const { value, name } = event.target;

    this.setState({
      email,
      password,
      isErrorDialogOpen,
      [name]: value,
    });
  };

  private handleDialog = (): void => {
    this.setState({
      isErrorDialogOpen: false,
    });
  };

  public render() {
    const { theme } = this.props;
    const { email, password, isErrorDialogOpen } = this.state;

    return (
      <div style={AppContainerStyle}>
        <div
          style={{
            maxWidth: 440,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0 auto',
          }}
        >
          <Avatar style={{ backgroundColor: theme.palette.primary.main }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h5">Log in</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={this.handleChange}
            value={email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={this.handleChange}
            value={password}
            onKeyUp={event => {
              if (event.key === 'Enter') {
                this.handlerClick();
              }
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={this.handlerClick}
          >
            Log In
          </Button>
        </div>
        <SimpleDialog
          onClose={this.handleDialog}
          open={isErrorDialogOpen}
          title="Login Failed"
          content="Email and Password do not match."
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, { setIsLoggedInRedux: setIsLoggedIn })(withRouter(Login));
