import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const PersonalHistoryMessage = defineMessages({
  nodataWarningLabel: {
    id: 'personalHistory.nodataWarningLabel',
    defaultMessage: Japanese['personalHistory.nodataWarningLabel'],
  },
});

export default PersonalHistoryMessage;
