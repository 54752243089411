import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from '@material-ui/core';
/* eslint-disable import/no-extraneous-dependencies */
import {
  ApplicantFormData,
  SpouseData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, { Component } from 'react';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router';
import ApplicantApi from '../apis/applicant';
import { AppContainerStyle } from '../assets/styles';
import ApplicantDetailsHeader from '../components/console/ApplicantDetailsHeader';
import ApplicantPublishSwitch from '../components/console/ApplicantPublishSwitch';
import CanadaEntryHistory from '../components/console/CanadaEntryHistory';
import ChildrenInformation from '../components/console/ChildInformation';
import EditableCardHeader from '../components/console/EditableCardHeader';
import EditDialog from '../components/console/EditDialog';
import EducationHistory from '../components/console/EducationHisotry';
import FamilyStructure from '../components/console/FamilyStructure';
import LanguageExamScores from '../components/console/LanguageExamScores';
import ParentsInformation from '../components/console/ParentsInformation';
import PersonalHistory from '../components/console/PersonalHistory';
import PersonInformation from '../components/console/PersonInformation';
import PreviousSpouse from '../components/console/PreviousSpouse';
import ResidentHistory from '../components/console/ResidentHistory';
import SiblingsInformation from '../components/console/SiblingsInformation';
import TravelHistory from '../components/console/TravelHistory';
import WorkExperience from '../components/console/WorkExperience';
import ApplicantDetailsContext from '../contexts/applicantDetails';
import { positionType } from '../modules/general';

interface State {
  applicant: ApplicantFormData | null;
  loading: boolean;
  isOpen: boolean;
  position: positionType;
}

const createPersonFiled = (
  data: ApplicantFormData | SpouseData,
  position: positionType,
  hasPreviousSpouse: boolean,
): JSX.Element => {
  const {
    general,
    educations,
    language,
    workExperiences,
    travelHistory,
    canadaVisitInfo,
    mother,
    father,
    siblings,
    residentHistory,
    previousSpouses,
  } = data;
  const { birthDate } = general;
  const canadaEntryHistory = (!canadaVisitInfo || canadaVisitInfo === 'none') ? {} : canadaVisitInfo;

  return (
    <div key={`${data._id}`}>
      <Card style={{ marginBottom: 12 }}>
        <CardContent>
          <ParentsInformation position={position} mother={mother} father={father} />
        </CardContent>
      </Card>
      <Card style={{ marginBottom: 12 }}>
        <CardContent>
          <SiblingsInformation position={position} siblings={siblings} />
        </CardContent>
      </Card>
      {
        hasPreviousSpouse
        && (
          <Card style={{ marginBottom: 12 }}>
            <CardContent>
              <PreviousSpouse position={position} previousSpouses={previousSpouses} />
            </CardContent>
          </Card>
        )
      }
      {
        language
        && (
          <Card style={{ marginBottom: 12 }}>
            <CardContent>
              <LanguageExamScores data={language} position={position} />
            </CardContent>
          </Card>
        )
      }
      <Card style={{ marginBottom: 12 }}>
        <CardContent>
          <PersonalHistory
            birthday={birthDate}
            educationData={educations === 'none' || !educations ? [] : educations}
            residentHistoryData={residentHistory || []}
            workExperienceData={workExperiences === 'none' ? [] : workExperiences}
          />
        </CardContent>
      </Card>
      <Card style={{ marginBottom: 12 }}>
        <CardContent>
          <EducationHistory data={educations} position={position} />
        </CardContent>
      </Card>
      <Card style={{ marginBottom: 12 }}>
        <CardContent>
          <WorkExperience data={workExperiences} position={position} />
        </CardContent>
      </Card>
      <Card style={{ marginBottom: 12 }}>
        <CardContent>
          <ResidentHistory position={position} residentHistory={residentHistory} />
        </CardContent>
      </Card>
      <Card style={{ marginBottom: 12 }}>
        <CardContent>
          <TravelHistory data={travelHistory} position={position} />
        </CardContent>
      </Card>
      <Card style={{ marginBottom: 12 }}>
        <CardContent>
          <CanadaEntryHistory data={canadaEntryHistory} position={position} />
        </CardContent>
      </Card>
    </div>
  );
};

class ApplicantDetails extends Component<RouteComponentProps<{ id: string }>, State> {
  public state: State = {
    applicant: null,
    loading: true,
    isOpen: false,
    position: 'applicant',
  };

  public async componentDidMount(): Promise<void> {
    const { match } = this.props;

    const responseData = await ApplicantApi.get(match.params.id);
    const applicant = responseData.data;

    this.setState({
      loading: false,
      applicant,
    });
  }

  public render(): JSX.Element {
    const {
      applicant,
      loading,
      isOpen,
      position,
    } = this.state;
    const { visaType, marriageCondition } = applicant || {};
    const hasPreviousSpouse = marriageCondition === 'divorced' || marriageCondition === 'divorcedAndCurrentlyMarried';

    return (
      <div style={AppContainerStyle}>
        <ApplicantDetailsHeader applicant={applicant} />
        <ApplicantPublishSwitch applicant={applicant} />
        {loading && <CircularProgress size={24} className="loading-spinner" />}
        {
          applicant
          && (
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <EditableCardHeader title="Family Structure" />
                <FamilyStructure formData={applicant} />
              </CardContent>
            </Card>
          )
        }
        {
          applicant
          && (
            <div>
              <Card style={{ marginBottom: 12 }}>
                <CardContent>
                  <EditableCardHeader
                    title="Applicant Information"
                    edit={this.handleEdit('applicant')}
                  />
                  <PersonInformation data={applicant.general} />
                </CardContent>
              </Card>
            </div>
          )
        }
        {
          applicant
          && (
            <div>
              <ApplicantDetailsContext.Provider
                value={{ allData: applicant, updateData: this.updateData }}
              >
                {createPersonFiled(applicant, 'applicant', hasPreviousSpouse)}
              </ApplicantDetailsContext.Provider>
            </div>
          )
        }
        {
          applicant
          && !!applicant.spouse
          && (
            <>
              <Typography variant="h5" style={{ marginBottom: 20 }}>Spouse Detail</Typography>
              <Card style={{ marginBottom: 12 }}>
                <CardContent>
                  <EditableCardHeader
                    title="Spouse Information"
                    edit={this.handleEdit('spouse')}
                  />
                  <PersonInformation data={applicant.spouse.general} />
                </CardContent>
              </Card>
            </>
          )
        }
        {
          applicant
          && !!applicant.spouse
          && (
            <div>
              <ApplicantDetailsContext.Provider
                value={{ allData: applicant, updateData: this.updateData }}
              >
                {createPersonFiled(applicant.spouse, 'spouse', true)}
              </ApplicantDetailsContext.Provider>
            </div>
          )
        }
        {
          applicant
          && !!applicant.children
          && (
            <>
              <Typography variant="h5" style={{ marginBottom: 20 }}>Children Detail</Typography>
              <ApplicantDetailsContext.Provider
                value={{ allData: applicant, updateData: this.updateData }}
              >
                <Card style={{ marginBottom: 12 }}>
                  <CardContent>
                    {
                      applicant
                      && (
                        <ChildrenInformation data={applicant.children} visaType={visaType} />
                      )
                    }
                  </CardContent>
                </Card>
              </ApplicantDetailsContext.Provider>
            </>
          )
        }
        {
          applicant
          && (
            <ApplicantDetailsContext.Provider
              value={{ allData: applicant, updateData: this.updateData }}
            >
              <EditDialog
                isOpen={isOpen}
                handleClose={this.handleClose}
                applicant={applicant}
                visaType={visaType}
                position={position}
              />
            </ApplicantDetailsContext.Provider>
          )
        }
      </div>
    );
  }

  private updateData = (data: ApplicantFormData): void => {
    this.setState({
      loading: false,
      applicant: data,
    });
  };

  private handleEdit = (position: positionType): () => void => (): void => {
    this.setState({ isOpen: true, position });
  };

  private handleClose = (): void => {
    this.setState({ isOpen: false });
  };
}

export default withRouter(ApplicantDetails);
