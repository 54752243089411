import { PRESENT } from './constants';

export function sortHistorical(targetArray: any[]): any[] {
  return targetArray.sort((a: any, b: any): number => {
    if (a.start && (!a.end || a.end === PRESENT)) {
      return -1;
    }
    if (b.start && (!b.end || b.end === PRESENT)) {
      return 1;
    }

    if (!a.start) {
      return 1;
    }
    if (!b.start) {
      return -1;
    }

    if (a.end === b.end) {
      return new Date(b.start).getTime() - new Date(a.start).getTime();
    }

    return new Date(b.end).getTime() - new Date(a.end).getTime();
  });
}
