import Button, { ButtonProps } from '@material-ui/core/Button';
import { LocationDescriptor } from 'history';
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { getURLParams } from '../../utils/params';

interface ButtonLinkProps extends ButtonProps {
  to: LocationDescriptor;
}

const ButtonLink = (props: ButtonLinkProps) => {
  const {
    children, to, variant, color,
  } = props;
  const queryString = getURLParams().toString();
  const params = queryString ? `?${queryString}` : '';

  return (
    <Button
      color={color}
      variant={variant}
      component={forwardRef<HTMLAnchorElement, Partial<ButtonLinkProps>>(
        (linkProps, ref) => (
          <Link
            ref={ref as any}
            className={linkProps.className}
            color={linkProps.color}
            to={`${to}${params}`}
          >
            {linkProps.children}
          </Link>
        ),
      )}
    >
      {children}
    </Button>
  );
};

export default ButtonLink;
