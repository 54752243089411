import { FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch/Switch';
import TextField from '@material-ui/core/TextField/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import React, {
  ChangeEvent,
  Component,
} from 'react';
import { ChangeEventWithName } from '../../../abbreviation-types';
import IntlMessage from '../../../intl';
import { EducationMessages } from '../../../intl/messages';
import {
  addArray,
  EducationState,
  positionType,
  removeArray,
  setIsNothing,
  sortArray,
} from '../../../modules/education';
import { LocaleState } from '../../../modules/locale';
import Caption from '../../application/Caption';
import Title from '../../application/Title';
import Duration from '../parts/Duration';
import Place from '../parts/Place';

interface Props {
  position: positionType;
  data: EducationState;
  locale: LocaleState;
  setIsNothingData: typeof setIsNothing | ((position: positionType, value: any) => void); // eslint-disable-line @typescript-eslint/no-explicit-any, max-len
  addArrayData: typeof addArray | ((position: positionType) => void);
  removeArrayData: typeof removeArray | ((position: positionType, index: number) => void);
  sortArrayData: typeof sortArray | ((position: positionType) => void);
  setData: (index: number) => (event: ChangeEventWithName) => void;
  setDuration: (index: number) => (name: 'start' | 'end') => (date: Date | null) => void;
}

class Education extends Component<Props> {
  public componentDidMount(): void {
    this.sortData();
  }

  private handleChangeSwitch = (event: ChangeEvent<HTMLInputElement>): void => {
    const { setIsNothingData, position } = this.props;
    setIsNothingData(position, event.target.checked);
  };

  private add = (): void => {
    const { addArrayData, position } = this.props;
    addArrayData(position);
  };

  private remove = (index: number): () => void => (): void => {
    const { removeArrayData, position } = this.props;
    removeArrayData(position, index);
  };

  private sortData = (): void => {
    const { sortArrayData, position } = this.props;
    sortArrayData(position);
  };

  public render(): JSX.Element {
    const {
      data,
      position,
      locale,
      setData,
      setDuration,
    } = this.props;
    const {
      isNothing,
      educations,
    } = position === 'applicant' ? data.applicant : data.spouse;
    const { length } = educations;
    const list = [];
    const intl = new IntlMessage(locale.langCode);

    for (let i = 0; i < length; i += 1) {
      list.push(
        <React.Fragment key={i}>
          <Card style={{ marginBottom: 12 }}>
            <CardContent>
              <Duration
                setData={setDuration(i)}
                start={educations[i].start}
                end={educations[i].end}
              />
              <TextField
                name="name"
                label={intl.format(EducationMessages.nameOfSchool)}
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={setData(i)}
                value={educations[i].name}
              />
              <Place
                category=""
                label={intl.format(EducationMessages.placeOfSchool)}
                setData={setData(i)}
                country={educations[i].country}
                city={educations[i].city}
              />
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <TextField
                    name="major"
                    label={intl.format(EducationMessages.major)}
                    variant="outlined"
                    margin="normal"
                    onChange={setData(i)}
                    value={educations[i].major}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    variant="outlined"
                    style={{ marginTop: 12, marginBottom: 12 }}
                    fullWidth
                  >
                    <InputLabel htmlFor="type">
                      {intl.format(EducationMessages.typeOfIssued)}
                    </InputLabel>
                    <Select
                      name="type"
                      native
                      value={educations[i].type}
                      onChange={setData(i)}
                      inputProps={{
                        id: 'type',
                      }}
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <option value="" />
                      <option value="One year Post-Secondary Credential">
                        {intl.format(EducationMessages.oneYearPostSecondary)}
                      </option>
                      <option value="Two year Post-Secondary Credential">
                        {intl.format(EducationMessages.twoYearPostSecondary)}
                      </option>
                      <option value="Three or more year Post-Secondary Credential">
                        {intl.format(EducationMessages.threeYearPostSecondary)}
                      </option>
                      <option value="Master Degree">
                        {intl.format(EducationMessages.masterDegree)}
                      </option>
                      <option value="PHD / Doctoral Degree">
                        {intl.format(EducationMessages.doctoralDegree)}
                      </option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    variant="outlined"
                    style={{ marginTop: 12, marginBottom: 12 }}
                    fullWidth
                  >
                    <InputLabel htmlFor="visa">
                      {intl.format(EducationMessages.visa)}
                    </InputLabel>
                    <Select
                      name="visa"
                      native
                      label={intl.format(EducationMessages.visa)}
                      value={educations[i].visa}
                      onChange={setData(i)}
                      inputProps={{
                        id: 'visa',
                      }}
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <option aria-label="-" value="" />
                      <option value="Student">
                        {intl.format(EducationMessages.studyPermit)}
                      </option>
                      <option value="Work">
                        {intl.format(EducationMessages.workPermit)}
                      </option>
                      <option value="Permanent Residence">
                        {intl.format(EducationMessages.permanentResidence)}
                      </option>
                      <option value="Citizen">
                        {intl.format(EducationMessages.citizenship)}
                      </option>
                      <option value="No Permit">
                        {intl.format(EducationMessages.noPermit)}
                      </option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={1}
                  style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                >
                  <IconButton aria-label="Delete" onClick={this.remove(i)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </React.Fragment>,
      );
    }

    return (
      <div>
        <Title text={intl.format(EducationMessages.education)} />
        <Caption text={intl.format(EducationMessages.afterGraduating)} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Switch
                  checked={isNothing}
                  onChange={this.handleChangeSwitch}
                  value
                />
              )}
              label={intl.format(EducationMessages.dontHaveExperience)}
            />
          </Grid>
        </Grid>
        {!isNothing && list}
        {
          !isNothing
          && (
            <div>
              <Button color="primary" onClick={this.add}>
                {intl.format(EducationMessages.add)}
              </Button>
              <Button color="inherit" onClick={this.sortData}>
                {intl.format(EducationMessages.sort)}
              </Button>
            </div>
          )
        }
      </div>
    );
  }
}

export default Education;
