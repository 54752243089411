import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/styles/withStyles';
import { format, isValid } from 'date-fns';
/* eslint-disable import/no-extraneous-dependencies */
import { TravelHistoryData } from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, {
  useEffect,
  useState,
} from 'react';
import { ChangeEventWithName } from '../../abbreviation-types';
import EditApi from '../../apis/edit';
import PublicEditContext from '../../contexts/publicEdit';
import { positionType } from '../../modules/general';
import { LocaleState } from '../../modules/locale';
import { sortHistorical } from '../../utils/sort';
import EditableCardHeader from '../console/EditableCardHeader';
import * as TravelHistoryForm from '../forms/register/TravelHistory';

interface Props {
  data?: TravelHistoryData[] | 'none';
  position: positionType;
  token: string;
}

const useStyles = makeStyles((theme: Theme): StyleRules => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme: Theme): StyleRules => createStyles({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const TravelHistory = (props: Props): JSX.Element => {
  const { data, position, token = '' } = props;
  const classes = useStyles();
  const { allData, updateData } = React.useContext(PublicEditContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [travelHistories, setTravelHistories] = useState<TravelHistoryData[]>([]);
  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  const locale: LocaleState = { langCode: 'en' };
  const initTravelHistory = {
    start: '',
    end: '',
    country: '',
    city: '',
    purpose: '',
  };

  useEffect((): void => {
    if (data && data !== 'none') setTravelHistories(data);
  }, [data, isOpen]);

  const handleEdit = (): void => {
    setIsOpen(true);
  };

  const handleCancel = (): void => {
    if (data && data !== 'none') setTravelHistories(data);

    setIsOpen(false);
  };

  const handleSave = async (): Promise<void> => {
    // Send data to DB
    if (position === 'applicant') {
      const payroll = {
        ...allData,
        travelHistory: travelHistories,
      };
      updateData(payroll);
      if (token) {
        try {
          setLoadingStatus(true);
          await EditApi.update(token, payroll);
        } catch (e) {
          // TODO: Error Handling
          // if reached this area, the result is probably 404 status
          alert('Some error has occurred: TravelHistory applicant');
        } finally {
          setLoadingStatus(false);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('token is null | undefined !!');
      }
    } else if (allData && allData.spouse) {
      const payroll = {
        ...allData,
        spouse: {
          ...allData.spouse,
          travelHistory: travelHistories,
        },
      };
      updateData(payroll);
      if (token) {
        try {
          setLoadingStatus(true);
          await EditApi.update(token, payroll);
        } catch (e) {
          // TODO: Error Handling
          // if reached this area, the result is probably 404 status
          alert('Some error has occurred: TravelHistoryUpdate spouse');
        } finally {
          setLoadingStatus(false);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('token is null | undefined !!');
      }
    }

    setIsOpen(false);
  };

  // eslint-disable-next-line max-len
  const handleChange = (index: number): (event: ChangeEventWithName) => void => (event: ChangeEventWithName): void => {
    const { value, name } = event.target;
    const newTravelHistories = travelHistories.slice();

    if (!name) return;

    newTravelHistories[index] = {
      ...newTravelHistories[index],
      [name]: value,
    };

    setTravelHistories(newTravelHistories);
  };

  const setDuration = (index: number) => (name: 'start' | 'end') => (date: Date | null) => {
    if (isValid(date)) {
      const newTravelHistories = travelHistories.slice();
      newTravelHistories[index] = {
        ...newTravelHistories[index],
        [name]: date ? format(date, 'yyyy-MM-dd') : null,
      };

      setTravelHistories(newTravelHistories);
    }
  };

  // eslint-disable-next-line no-shadow, @typescript-eslint/no-unused-vars
  const addArrayData = (position: positionType): void => {
    const newArray = travelHistories.slice();
    newArray.push(initTravelHistory);

    setTravelHistories(newArray);
  };

  // eslint-disable-next-line no-shadow, @typescript-eslint/no-unused-vars
  const removeArrayData = (position: positionType, index: number): void => {
    const newArray = travelHistories.slice();
    newArray.splice(index, 1);

    setTravelHistories(newArray);
  };

  // eslint-disable-next-line no-shadow, @typescript-eslint/no-unused-vars
  const sortArrayData = (position: positionType): void => {
    const newArray = travelHistories.slice();
    const sortedArray = sortHistorical(newArray);

    setTravelHistories(sortedArray);
  };

  return (
    <>
      <EditableCardHeader title="Travel History" edit={handleEdit} />
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Country</StyledTableCell>
              <StyledTableCell align="center">City</StyledTableCell>
              <StyledTableCell align="center">Start</StyledTableCell>
              <StyledTableCell align="center">End</StyledTableCell>
              <StyledTableCell align="center">Purpose</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              (
                data
                && data === 'none'
                && (
                  <TableRow>
                    None
                  </TableRow>
                )
              ) || (
                data
                && Array.isArray(data)
                && data.map((travelHistory): JSX.Element => (
                  <TableRow key={`${travelHistory.country}${travelHistory.city}`}>
                    <StyledTableCell align="center">{travelHistory.country}</StyledTableCell>
                    <StyledTableCell align="center">{travelHistory.city}</StyledTableCell>
                    <StyledTableCell align="center">{travelHistory.start}</StyledTableCell>
                    <StyledTableCell align="center">{travelHistory.end}</StyledTableCell>
                    <StyledTableCell align="center">{travelHistory.purpose || ''}</StyledTableCell>
                  </TableRow>
                ))
              )
            }
          </TableBody>
        </Table>
      </Paper>
      {
        isOpen
        && (
          <Dialog open={isOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Travel History</DialogTitle>
            <DialogContent>
              {
                data
                && (
                  <TravelHistoryForm.default
                    position={position}
                    data={{
                      applicant: {
                        travelHistory: travelHistories,
                      },
                      spouse: {
                        travelHistory: travelHistories,
                      },
                    }}
                    locale={locale}
                    addArrayData={addArrayData}
                    removeArrayData={removeArrayData}
                    sortArrayData={sortArrayData}
                    setData={handleChange}
                    setDuration={setDuration}
                  />
                )
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} disabled={isLoading}>
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary" disabled={isLoading}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
};

export default TravelHistory;
