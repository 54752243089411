import { ApplicantFormData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies
import { createContext } from 'react';

export interface ApplicantDetailsContextData {
  allData: ApplicantFormData;
  updateData: (data: ApplicantFormData) => void;
}

const ApplicantDetailsContext = createContext<ApplicantDetailsContextData>({
  allData: {} as unknown as ApplicantFormData,
  updateData: (data): void => {
    alert(data);
  },
});

export default ApplicantDetailsContext;
