import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React, {
  ChangeEvent,
  Component,
} from 'react';
import { connect } from 'react-redux';
import IntlMessage from '../../../intl';
import { EditableLabelMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';

interface State {
  isEdit: boolean;
}

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
});

interface Props {
  label?: string;
  data: string;
  setTargetAction: (value: any) => any;
  locale: LocaleState;
}

class EditableLabel extends Component<Props, State> {
  public state = {
    isEdit: false,
  };

  private changeEditStatus = () => {
    const { isEdit } = this.state;

    this.setState({
      isEdit: !isEdit,
    });
  };

  private changeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { setTargetAction } = this.props;
    setTargetAction(event.currentTarget.value);
  };

  public render() {
    const { isEdit } = this.state;
    const { label, data, locale } = this.props;
    const intl = new IntlMessage(locale.langCode);

    return isEdit ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
        }}
      >
        <TextField
          id={`editable-${label}`}
          label={label}
          value={data}
          onChange={this.changeValue}
          margin="normal"
          variant="outlined"
          style={{ marginRight: 16, width: '100%', maxWidth: 300 }}
        />
        <Button variant="contained" color="primary" onClick={this.changeEditStatus}>
          {intl.format(EditableLabelMessages.confirm)}
        </Button>
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
        }}
      >
        <TextField
          disabled
          id={`editable-${label}`}
          label={label}
          value={data}
          margin="normal"
          variant="outlined"
          style={{ marginRight: 16, width: '100%', maxWidth: 300 }}
        />
        <Button variant="outlined" color="primary" onClick={this.changeEditStatus}>
          {intl.format(EditableLabelMessages.edit)}
        </Button>
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(EditableLabel);
