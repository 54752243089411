/* eslint-disable import/no-extraneous-dependencies */
import {
  LoginCredential,
  ResponseData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import Request from './request';

export default class AuthApi {
  public static async login(data: LoginCredential): Promise<ResponseData<any>> {
    return Request.post('auth', data);
  }
}
