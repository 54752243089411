import { FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import { ResidentHistoryData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies
import {
  format,
  isValid,
} from 'date-fns';
import React, {
  ChangeEvent,
  Component,
} from 'react';
import { connect } from 'react-redux';
import { ChangeEventWithName } from '../../../abbreviation-types';
import IntlMessage from '../../../intl';
import { ResidentHistoryMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';
import {
  addArray,
  positionType,
  removeArray,
  ResidentHistoryState,
  setResidentHistory,
  sortArray,
} from '../../../modules/residentHistory';
import { PRESENT } from '../../../utils/constants';
import Caption from '../../application/Caption';
import Title from '../../application/Title';
import AdministrativeDivisions from '../parts/AdministrativeDivisions';
import DurationWithPresent from '../parts/DurationWithPresent';

type TargetElements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
  data: state.residentHistory,
});

export interface Props {
  position: positionType;
  data: ResidentHistoryState;
  locale: LocaleState;
  setResidentHistoryData: typeof setResidentHistory;
  addArrayData: typeof addArray;
  removeArrayData: typeof removeArray;
  sortArrayData: typeof sortArray;
}

class ResidentHistory extends Component<Props, {}> {
  public componentWillUnmount() {
    this.sortData();
  }

  private add = () => {
    const { addArrayData, position } = this.props;
    addArrayData(position);
  };

  private remove = (index: number) => () => {
    const { removeArrayData, position } = this.props;
    removeArrayData(position, index);
  };

  private setData = (index: number) => (event: ChangeEvent<TargetElements>) => {
    const { value, id } = event.target;
    const { setResidentHistoryData, position } = this.props;

    if (Object.prototype.hasOwnProperty.call(event.target, 'checked')) {
      setResidentHistoryData(position, index, (id as keyof ResidentHistoryData), value === 'true');
    } else {
      setResidentHistoryData(position, index, (id as keyof ResidentHistoryData), value);
    }
  };

  private setDataWithName = (index: number) => (event: ChangeEventWithName) => {
    const { value, name } = event.target;
    const { setResidentHistoryData, position } = this.props;

    setResidentHistoryData(position, index, (name as keyof ResidentHistoryData), value);
  };

  private setDuration = (index: number) => (name: 'start' | 'end') => (date: Date | typeof PRESENT | null) => {
    const { setResidentHistoryData, position } = this.props;

    if (!date) {
      setResidentHistoryData(position, index, name, null);
    } else if (date === PRESENT) {
      setResidentHistoryData(position, index, name, date);
    } else if (isValid(date)) {
      setResidentHistoryData(position, index, name, format(date, 'yyyy-MM-dd'));
    }
  };

  private sortData = () => {
    const { sortArrayData, position } = this.props;
    sortArrayData(position);
  };

  public render() {
    const { data, position, locale } = this.props;
    const {
      residentHistory,
    } = position === 'applicant' ? data.applicant : data.spouse;
    const { length } = residentHistory;
    const list = [];
    const intl = new IntlMessage(locale.langCode);

    for (let i = 0; i < length; i += 1) {
      list.push(
        <React.Fragment key={i}>
          <Card style={{ marginBottom: 12 }}>
            <CardContent>
              <DurationWithPresent
                setData={this.setDuration(i)}
                start={residentHistory[i].start}
                end={residentHistory[i].end}
              />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <TextField
                    id="address"
                    label={intl.format(ResidentHistoryMessages.address)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    onChange={this.setData(i)}
                    value={residentHistory[i].address}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="postalCode"
                    label={intl.format(ResidentHistoryMessages.postalCode)}
                    variant="outlined"
                    margin="normal"
                    onChange={this.setData(i)}
                    value={residentHistory[i].postalCode}
                  />
                </Grid>
              </Grid>
              <AdministrativeDivisions
                city={residentHistory[i].city}
                province={residentHistory[i].province}
                country={residentHistory[i].country}
                setData={this.setDataWithName(i)}
              />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormControl variant="outlined" style={{ marginTop: 12, width: '100%' }} fullWidth>
                    <InputLabel htmlFor="visa">
                      {intl.format(ResidentHistoryMessages.visa)}
                    </InputLabel>
                    <Select
                      name="visa"
                      native
                      label={intl.format(ResidentHistoryMessages.visa)}
                      value={residentHistory[i].visa}
                      onChange={this.setDataWithName(i)}
                      inputProps={{
                        name: 'visa',
                      }}
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <option aria-label="-" value="" />
                      <option value="Student">
                        {intl.format(ResidentHistoryMessages.studyPermit)}
                      </option>
                      <option value="Work">
                        {intl.format(ResidentHistoryMessages.workPermit)}
                      </option>
                      <option value="Permanent Residence">
                        {intl.format(ResidentHistoryMessages.permanentResidence)}
                      </option>
                      <option value="Citizen">
                        {intl.format(ResidentHistoryMessages.citizenship)}
                      </option>
                      <option value="No Permit">
                        {intl.format(ResidentHistoryMessages.noPermit)}
                      </option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <IconButton aria-label="Delete" onClick={this.remove(i)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            </CardContent>
          </Card>
        </React.Fragment>,
      );
    }

    return (
      <div>
        <Title text={intl.format(ResidentHistoryMessages.residentHistory)} />
        <Caption text={intl.format(ResidentHistoryMessages.forPast10Years)} />
        {list}
        <div>
          <Button color="primary" onClick={this.add}>
            {intl.format(ResidentHistoryMessages.add)}
          </Button>
          <Button color="inherit" onClick={this.sortData}>
            {intl.format(ResidentHistoryMessages.sort)}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  {
    setResidentHistoryData: setResidentHistory,
    addArrayData: addArray,
    removeArrayData: removeArray,
    sortArrayData: sortArray,
  },
)(ResidentHistory);
