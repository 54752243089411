import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputBase,
  TablePagination,
  TableSortLabel,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import {
  DeleteForeverTwoTone,
  Search,
} from '@material-ui/icons';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
/* eslint-disable import/no-extraneous-dependencies */
import {
  ApplicantEditingAuthorityData,
  ApplicantFormData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router';
import ApplicantApi from '../apis/applicant';
import PublishApi from '../apis/publish';
import { AppContainerStyle } from '../assets/styles';
import calcAge from '../utils/calculation';
import { getFormattedDateTime } from '../utils/format';

type TableCellClickEvent = React.MouseEvent<HTMLTableCellElement, MouseEvent>;
type InputChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;
type HeaderId = 'firstName' | 'lastName' | 'email' | 'age' | 'typeOfApplication' | 'received';
type HeaderContent = { id: HeaderId; label: string };

const HeaderContents: HeaderContent[] = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'email', label: 'Email' },
  { id: 'age', label: 'Age' },
  { id: 'typeOfApplication', label: 'Type of Application' },
  { id: 'received', label: 'Received' },
];
const listDescription = `
The colored rows in the list are those with outdated public editing permissions.
Yellow is more than 7 days old, red is more than 14 days old.
Review the permissions.
`;

function sort(
  target: ApplicantFormData[],
  orderBy: HeaderId,
  order: 'asc' | 'desc',
): ApplicantFormData[] {
  const result = target.sort((a, b) => {
    let compareA;
    let compareB;
    switch (orderBy) {
      case 'firstName':
        compareA = a.general.firstName;
        compareB = b.general.firstName;
        break;
      case 'lastName':
        compareA = a.general.lastName;
        compareB = b.general.lastName;
        break;
      case 'email':
        compareA = a.general.email;
        compareB = b.general.email;
        break;
      case 'age':
        // 誕生日が小さいほど年齢は大きくなるため逆に代入している TODO: まともなロジックに修正
        compareA = b.general.birthDate;
        compareB = a.general.birthDate;
        break;
      case 'typeOfApplication':
        compareA = a.visaType || '';
        compareB = b.visaType || '';
        break;
      default:
        compareA = a.createdAt || '';
        compareB = b.createdAt || '';
    }

    if (compareA < compareB) return -1;
    if (compareA > compareB) return 1;
    return 0;
  });

  if (order === 'desc') result.reverse();

  return result;
}

function getEditAuth(
  publishes: ApplicantEditingAuthorityData[],
  applicantId: string,
): ApplicantEditingAuthorityData | undefined {
  return publishes.find((publish) => publish.applicantId === applicantId);
}

function calcOpenDaysAsPublic(editAuth?: ApplicantEditingAuthorityData): number {
  if (!editAuth?.updatedAt) return 0;
  const { updatedAt } = editAuth;

  let today = new Date();
  today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
  const diff = today.getTime() - (new Date(updatedAt)).getTime();

  return Math.floor(diff / 1000 / 60 / 60 / 24) + 1;
}

const Home = (props: RouteComponentProps): JSX.Element => {
  const [applicants, setApplicants] = useState<ApplicantFormData[]>([]);
  const [totalApplicantsCount, setTotalApplicantsCount] = useState<number>(0);
  const [publishes, setPublishes] = useState<ApplicantEditingAuthorityData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [targetId, setTargetId] = useState<string>('');
  const [targetName, setTargetName] = useState<string>('');
  const [inputName, setInputName] = useState<string>('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchWord, setSearchWord] = useState('');
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('desc');
  const [orderColumn, setOrderColumn] = useState<HeaderId>('received');

  const fetchApplicants = useCallback(async (offset = 0): Promise<void> => {
    const { data } = await ApplicantApi.getRange(offset, rowsPerPage);
    setApplicants((prev) => ([...prev, ...data.applicants]));
    setTotalApplicantsCount(data.total);
  }, [rowsPerPage, setApplicants, setTotalApplicantsCount]);

  const fetchEditingAuthorities = async (): Promise<void> => {
    const responseData = await PublishApi.getAll();
    setPublishes(responseData.data);
  };

  const refreshList = async (): Promise<void> => {
    setLoading(true);
    await fetchApplicants();
    await fetchEditingAuthorities();
    setLoading(false);
  };

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      await refreshList();
    };
    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      try {
        await fetchApplicants(applicants.length);
      } catch (error) {
        if (error.message === 'Request aborted') {
          // TODO: handling "Request aborted" (just ignoring it now)
        } else {
          throw error;
        }
      }
    };

    if (applicants.length < totalApplicantsCount) {
      fetch();
    }
  }, [setLoading, fetchApplicants, applicants.length, totalApplicantsCount]);

  const clickHandler = (event: TableCellClickEvent): void => {
    const { history } = props;
    const dataId = event.currentTarget.parentElement
      ? event.currentTarget.parentElement.attributes.getNamedItem('data-id')
      : '';

    if (dataId) {
      history.push(`/applicant/${dataId.value}`);
    }
  };

  const handleDialogOpen = (event: TableCellClickEvent): void => {
    const dataId = event.currentTarget.parentElement
      ? event.currentTarget.parentElement.attributes.getNamedItem('data-id')
      : '';
    const dataName = event.currentTarget.parentElement
      ? event.currentTarget.parentElement.attributes.getNamedItem('data-name')
      : '';

    if (dataId && dataName) {
      setModalOpen(true);
      setTargetId(dataId.value);
      setTargetName(dataName.value);
    }
  };

  const handleDialogClose = (): void => {
    setModalOpen(false);
    setTargetId('');
    setTargetName('');
    setInputName('');
  };

  const deleteHandler = async (): Promise<void> => {
    if (!targetId || !targetName) {
      handleDialogClose();
      return;
    }

    if (targetName === inputName) {
      handleDialogClose();
      setLoading(true);
      await ApplicantApi.delete(targetId);
      await refreshList();
    }
  };

  const inputNameChangeHandler = (event: InputChangeEvent): void => {
    const { value } = event.target;
    setInputName(value);
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const inputFilterWordHandler = (event: InputChangeEvent): void => {
    const { value } = event.target;
    setSearchWord(value);
    setPage(0); // 検索文字変更時にページを最初に戻す
  };

  const orderHandler = (column: HeaderId): () => void => (): void => {
    if (column !== orderColumn) {
      setOrderColumn(column);
      setOrderDirection('asc');
      return;
    }

    setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
  };

  const sortedAndFilteredApplicants = sort(applicants, orderColumn, orderDirection)
    .filter((applicant) => {
      const regExp = new RegExp(searchWord, 'i');
      return applicant.general.firstName.match(regExp)
        || applicant.general.lastName.match(regExp)
        || applicant.general.email.match(regExp);
    });

  return (
    <div style={AppContainerStyle}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" style={{ display: 'flex', alignItems: 'center' }}>
          All Customers List
          <Tooltip title={listDescription} style={{ paddingLeft: '8px' }}>
            <HelpTwoToneIcon />
          </Tooltip>
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <Search />
          <InputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={inputFilterWordHandler}
          />
        </div>
      </div>
      {
        (loading || applicants.length < totalApplicantsCount)
        && <CircularProgress size={24} className="loading-spinner" />
      }
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
        component="div"
        count={sortedAndFilteredApplicants.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Table>
        <TableHead>
          <TableRow>
            {
              HeaderContents.map((header) => (
                <TableCell key={header.id}>
                  <TableSortLabel
                    active={header.id === orderColumn}
                    direction={orderColumn !== header.id ? 'asc' : orderDirection}
                    onClick={orderHandler(header.id)}
                  >
                    {header.label}
                  </TableSortLabel>
                </TableCell>
              ))
            }
            <TableCell>Delete?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            sortedAndFilteredApplicants
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((applicant) => {
                const publishedSpan = calcOpenDaysAsPublic(getEditAuth(publishes, applicant._id || ''));

                let rowColorCode = '#ffffff';
                if (publishedSpan >= 7) rowColorCode = '#ff9800';
                if (publishedSpan >= 14) rowColorCode = '#f44336';
                const isWarningLine = publishedSpan >= 7;
                const textColor = isWarningLine ? '#ffffff' : 'rgba(0, 0, 0, .87)';

                return (
                  <TableRow
                    data-id={applicant._id}
                    data-name={applicant.general.firstName}
                    hover
                    key={`${applicant._id}`}
                    style={{ cursor: 'pointer', backgroundColor: rowColorCode }}
                  >
                    <TableCell
                      onClick={clickHandler}
                      scope="row"
                      style={{ color: textColor, fontWeight: isWarningLine ? 'bold' : 'normal' }}
                    >
                      {applicant.general.firstName}
                    </TableCell>
                    <TableCell
                      onClick={clickHandler}
                      style={{ color: textColor, fontWeight: isWarningLine ? 'bold' : 'normal' }}
                    >
                      {applicant.general.lastName}
                    </TableCell>
                    <TableCell
                      onClick={clickHandler}
                      style={{ color: textColor, fontWeight: isWarningLine ? 'bold' : 'normal' }}
                    >
                      {applicant.general.email}
                    </TableCell>
                    <TableCell
                      align="right"
                      onClick={clickHandler}
                      style={{ color: textColor, fontWeight: isWarningLine ? 'bold' : 'normal' }}
                    >
                      {calcAge(new Date(applicant.general.birthDate))}
                    </TableCell>
                    <TableCell
                      onClick={clickHandler}
                      style={{
                        textTransform: 'capitalize',
                        color: textColor,
                        fontWeight: isWarningLine ? 'bold' : 'normal',
                      }}
                    >
                      {applicant.visaType}
                    </TableCell>
                    <TableCell
                      onClick={clickHandler}
                      style={{ color: textColor, fontWeight: isWarningLine ? 'bold' : 'normal' }}
                    >
                      {getFormattedDateTime(applicant.createdAt)}
                    </TableCell>
                    <TableCell align="center" style={{ verticalAlign: 'inherit' }} onClick={handleDialogOpen}>
                      <DeleteForeverTwoTone />
                    </TableCell>
                  </TableRow>
                );
              })
          }
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
        component="div"
        count={sortedAndFilteredApplicants.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Dialog
        open={isModalOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you absolutely sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
            <br />
            This will permanently delete:&nbsp;
            <b>{targetName}</b>
            <br />
            <br />
            Please type in the name of the applicant to confirm.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="targetName"
            label="Applicant FirstName"
            type="text"
            fullWidth
            onChange={inputNameChangeHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteHandler} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(Home);
