import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
/* eslint-disable import/no-extraneous-dependencies */
import {
  ApplicantFormData,
  GeneralData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, {
  useEffect,
  useState,
} from 'react';
import { format, isValid } from 'date-fns';
import { ChangeEventWithName } from '../../abbreviation-types';
import RegisterFormApi from '../../apis/registerForm';
import ApplicantDetailsContext from '../../contexts/applicantDetails';
import { positionType } from '../../modules/general';
import { LocaleState } from '../../modules/locale';
import { visaTypes } from '../../modules/person';
import GeneralForm from '../forms/register/General';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  applicant: ApplicantFormData;
  visaType?: visaTypes;
  position: positionType;
}

const EditDialog = (props: Props): JSX.Element => {
  const {
    isOpen,
    handleClose,
    applicant,
    visaType,
    position,
  } = props;
  const locale: LocaleState = { langCode: 'en' };
  const [generalData, setGeneralData] = useState<GeneralData>({} as GeneralData);
  const { updateData } = React.useContext(ApplicantDetailsContext);

  useEffect((): void => {
    if (position === 'applicant') {
      if (applicant) {
        setGeneralData(applicant.general);
      }
    } else if (applicant && applicant.spouse) {
      setGeneralData(applicant.spouse.general);
    }
  }, [applicant, position, isOpen]);

  const handleCancel = (): void => {
    const { general, spouse } = applicant || {};

    if (position === 'applicant') {
      setGeneralData(general);
    } else if (spouse && spouse.general) {
      setGeneralData(spouse.general);
    }

    handleClose();
  };

  const handleSave = async (): Promise<void> => {
    // Send data to DB
    if (position === 'applicant') {
      const payroll: ApplicantFormData = {
        ...applicant,
        general: generalData,
      };

      updateData(payroll);

      if (payroll._id) {
        await RegisterFormApi.update(payroll._id, payroll);
      } else {
        // eslint-disable-next-line no-console
        console.error('payroll._id is null | undefined !!');
      }
    } else if (applicant) {
      const payroll: ApplicantFormData = {
        ...applicant,
        spouse: {
          ...applicant.spouse,
          general: generalData,
        },
      };

      updateData(payroll);

      if (payroll._id) {
        await RegisterFormApi.update(payroll._id, payroll);
      } else {
        // eslint-disable-next-line no-console
        console.error('payroll._id is null | undefined !!');
      }
    }

    handleClose();
  };

  const handleChange = (event: ChangeEventWithName): void => {
    const name = event.target.name || '';
    const { value } = event.target;

    if (Object.prototype.hasOwnProperty.call(event.target, 'checked')) {
      setGeneralData({
        ...generalData,
        [name]: value === 'true',
      });
      return;
    }

    setGeneralData({
      ...generalData,
      [name]: value,
    });
  };

  const setBirthDate = (date: Date | null) => {
    if (isValid(date)) {
      const name = 'birthDate' as keyof GeneralData;
      setGeneralData({
        ...generalData,
        [name]: date ? format(date, 'yyyy-MM-dd') : null,
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Person Information</DialogTitle>
      <DialogContent>
        {
          applicant
          && (
            <GeneralForm
              locale={locale}
              data={generalData}
              visaType={visaType}
              shouldValidate
              setData={handleChange}
              setBirthDate={setBirthDate}
            />
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
