import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const EducationMessages = defineMessages({
  nameOfSchool: {
    id: 'education.nameOfSchool',
    defaultMessage: Japanese['education.nameOfSchool'],
  },
  placeOfSchool: {
    id: 'education.placeOfSchool',
    defaultMessage: Japanese['education.placeOfSchool'],
  },
  major: {
    id: 'education.major',
    defaultMessage: Japanese['education.major'],
  },
  typeOfIssued: {
    id: 'education.typeOfIssued',
    defaultMessage: Japanese['education.typeOfIssued'],
  },
  education: {
    id: 'education.education',
    defaultMessage: Japanese['education.education'],
  },
  afterGraduating: {
    id: 'education.afterGraduating',
    defaultMessage: Japanese['education.afterGraduating'],
  },
  dontHaveExperience: {
    id: 'education.dontHaveExperience',
    defaultMessage: Japanese['education.dontHaveExperience'],
  },
  add: {
    id: 'education.add',
    defaultMessage: Japanese['education.add'],
  },
  remove: {
    id: 'education.remove',
    defaultMessage: Japanese['education.remove'],
  },
  sort: {
    id: 'education.sort',
    defaultMessage: Japanese['education.sort'],
  },
  oneYearPostSecondary: {
    id: 'education.oneYearPostSecondary',
    defaultMessage: Japanese['education.oneYearPostSecondary'],
  },
  twoYearPostSecondary: {
    id: 'education.twoYearPostSecondary',
    defaultMessage: Japanese['education.twoYearPostSecondary'],
  },
  threeYearPostSecondary: {
    id: 'education.threeYearPostSecondary',
    defaultMessage: Japanese['education.threeYearPostSecondary'],
  },
  masterDegree: {
    id: 'education.masterDegree',
    defaultMessage: Japanese['education.masterDegree'],
  },
  doctoralDegree: {
    id: 'education.doctoralDegree',
    defaultMessage: Japanese['education.doctoralDegree'],
  },
  visa: {
    id: 'education.visa',
    defaultMessage: Japanese['education.visa'],
  },
  studyPermit: {
    id: 'education.studyPermit',
    defaultMessage: Japanese['education.studyPermit'],
  },
  workPermit: {
    id: 'education.workPermit',
    defaultMessage: Japanese['education.workPermit'],
  },
  permanentResidence: {
    id: 'education.permanentResidence',
    defaultMessage: Japanese['education.permanentResidence'],
  },
  citizenship: {
    id: 'education.citizenship',
    defaultMessage: Japanese['education.citizenship'],
  },
  noPermit: {
    id: 'education.noPermit',
    defaultMessage: Japanese['education.noPermit'],
  },
});

export default EducationMessages;
