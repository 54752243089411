import { CanadaVisitData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies

const SET_CANADA_VISIT_ORIGINAL = 'SET_CANADA_VISIT_ORIGINAL';
const SET_CANADA_VISIT_RECENT = 'SET_CANADA_VISIT_RECENT';
export type positionType = 'applicant' | 'spouse';

export interface CanadaVisitState {
  applicant: CanadaVisitData;
  spouse: CanadaVisitData;
}

interface SetCanadaVisitOriginalAction {
  type: typeof SET_CANADA_VISIT_ORIGINAL;
  position: positionType;
  label: string;
  payload: any;
}

interface SetCanadaVisitRecentAction {
  type: typeof SET_CANADA_VISIT_RECENT;
  position: positionType;
  label: string;
  payload: any;
}

type CanadaVisitActionTypes = SetCanadaVisitOriginalAction | SetCanadaVisitRecentAction;

const initialState: CanadaVisitState = {
  applicant: {
    originalEntry: {
      date: '',
      place: '',
      visa: '',
      expiredDate: '',
    },
    recentEntry: {
      date: '',
      place: '',
      visa: '',
      expiredDate: '',
    },
  },
  spouse: {
    originalEntry: {
      date: '',
      place: '',
      visa: '',
      expiredDate: '',
    },
    recentEntry: {
      date: '',
      place: '',
      visa: '',
      expiredDate: '',
    },
  },
};

export function CanadaVisitReducer(
  state = initialState,
  action: CanadaVisitActionTypes,
): CanadaVisitState {
  switch (action.type) {
    case SET_CANADA_VISIT_ORIGINAL:
      if (action.position === 'applicant') {
        return {
          applicant: {
            originalEntry: {
              ...state.applicant.originalEntry,
              [action.label]: action.payload,
            },
            recentEntry: state.applicant.recentEntry,
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          originalEntry: {
            ...state.spouse.originalEntry,
            [action.label]: action.payload,
          },
          recentEntry: state.spouse.recentEntry,
        },
      };
    case SET_CANADA_VISIT_RECENT:
      if (action.position === 'applicant') {
        return {
          applicant: {
            recentEntry: {
              ...state.applicant.recentEntry,
              [action.label]: action.payload,
            },
            originalEntry: state.applicant.originalEntry,
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          recentEntry: {
            ...state.spouse.recentEntry,
            [action.label]: action.payload,
          },
          originalEntry: state.spouse.originalEntry,
        },
      };
    default:
      return state;
  }
}

export function setCanadaVisitOriginal(
  position: positionType,
  label: string,
  value: any,
): CanadaVisitActionTypes {
  return {
    type: SET_CANADA_VISIT_ORIGINAL,
    position,
    label,
    payload: value,
  };
}

export function setCanadaVisitRecent(
  position: positionType,
  label: string,
  value: any,
): CanadaVisitActionTypes {
  return {
    type: SET_CANADA_VISIT_RECENT,
    position,
    label,
    payload: value,
  };
}
