import React, { ChangeEvent } from 'react';
import { format, isValid } from 'date-fns';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Title from '../../application/Title';
import Caption from '../../application/Caption';
import IntlMessage from '../../../intl';
import { PreviousSpouseMessages } from '../../../intl/messages';
import { LocaleState } from '../../../modules/locale';
import Name from '../parts/Name';
import {
  positionType,
  addArray,
  removeArray,
  setData,
  PreviousSpouseState
} from '../../../modules/previousSpouse';
import { PreviousSpouseData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies

interface Props {
  position: positionType;
  locale: LocaleState;
  data: PreviousSpouseState;
  addArrayData: typeof addArray | ((position: positionType) => void);
  removeArrayData: typeof removeArray | ((position: positionType, index: number) => void);
  setData: typeof setData | ((position: positionType, index: number, name: keyof PreviousSpouseData, value: any) => void);
}

interface PreviousSpouseProps {
  locale: LocaleState;
  data: PreviousSpouseData;
  handleChange: (event: ChangeEvent<{ name?: string; value?: unknown }>) => void;
  handleChangeDate: (name: keyof PreviousSpouseData) => (date: Date | null) => void;
  handleRemove: () => void;
}

const PreviousSpouse = (props: PreviousSpouseProps) => {
  const { locale, data, handleChange, handleChangeDate, handleRemove } = props;
  const intl = new IntlMessage(locale.langCode);

  return (
    <Card style={{ marginBottom: 12 }}>
      <CardContent>
        <Name
          label={intl.format(PreviousSpouseMessages.english)}
          language=""
          setData={handleChange}
          firstName={data.firstName}
          lastName={data.lastName}
          middleName={data.middleName}
          originalFamilyName={null}
        />
        <Name
          label={intl.format(PreviousSpouseMessages.motherLanguage)}
          language="InMotherLanguage"
          setData={handleChange}
          firstName={data.firstNameInMotherLanguage}
          lastName={data.lastNameInMotherLanguage}
          middleName={data.middleNameInMotherLanguage}
          originalFamilyName={null}
        />
        <KeyboardDatePicker
          name="dateOfBirth"
          format="yyyy-MM-dd"
          variant="inline"
          inputVariant="outlined"
          margin="normal"
          label={intl.format(PreviousSpouseMessages.dateOfBirth)}
          value={data.birthDate ? `${data.birthDate}T12:00:00Z` : null}
          onChange={handleChangeDate('birthDate')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <Box>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            variant="inline"
            inputVariant="outlined"
            margin="normal"
            style={{ marginRight: 16 }}
            label={intl.format(PreviousSpouseMessages.marriageDate)}
            value={data.marriageDate ? `${data.marriageDate}T12:00:00Z` : null}
            onChange={handleChangeDate('marriageDate')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            variant="inline"
            inputVariant="outlined"
            margin="normal"
            label={intl.format(PreviousSpouseMessages.divorceDate)}
            value={data.divorceDate ? `${data.divorceDate}T12:00:00Z` : null}
            onChange={handleChangeDate('divorceDate')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Box>
        <Box
          style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
        >
          <IconButton aria-label="Delete" onClick={handleRemove}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  )
}

const PreviousSpouses = (props: Props) => {
  const {
    position,
    locale,
    data,
    addArrayData,
    removeArrayData,
    setData,
  } = props;
  const intl = new IntlMessage(locale.langCode);
  const {
    previousSpouses,
  } = position === 'applicant' ? data.applicant : data.spouse;

  const add = (): void => {
    addArrayData(position);
  };

  const remove = (index: number): () => void => (): void => {
    removeArrayData(position, index);
  };

  const set = (index: number) => (event: ChangeEvent<{ name?: string; value?: unknown }>) => {
    const { value, name } = event.target;
    setData(position, index, (name as keyof PreviousSpouseData), value);
  };

  const setDate = (index: number) => (name: keyof PreviousSpouseData) => (date: Date | null) => {
    if (isValid(date)) {
      const value = date ? format(date, 'yyyy-MM-dd') : null;
      setData(position, index, (name as keyof PreviousSpouseData), value);
    }
  };

  return (
    <div>
      <Title text={intl.format(PreviousSpouseMessages.previousSpouse)} />
      <Caption text={intl.format(PreviousSpouseMessages.caption)} />
      {
        previousSpouses.map((prevSpouse, i) => (
          <PreviousSpouse
            locale={locale}
            data={prevSpouse}
            handleChange={set(i)}
            handleChangeDate={setDate(i)}
            handleRemove={remove(i)}
          />
        ))
      }
      <div>
        <Button color="primary" onClick={add}>
          {intl.format(PreviousSpouseMessages.add)}
        </Button>
      </div>
    </div>
  )
}

export default PreviousSpouses;