import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import React, {
  ChangeEvent,
  Component,
} from 'react';
import { connect } from 'react-redux';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router';
import { AppContainerStyle } from '../assets/styles';
import ErrorMessage from '../components/application/ErrorMessage';
import SubTitle from '../components/application/SubTitle';
import Title from '../components/application/Title';
import IntelMessage from '../intl';
import { RegisterTypeMessages } from '../intl/messages';
import { AppState } from '../modules';
import { LocaleState } from '../modules/locale';
import {
  PersonCondition,
  PersonState,
  setCondition,
} from '../modules/person';
import { getURLParams } from '../utils/params';

type TargetElements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

const mapStateToProps = (state: AppState) => ({
  person: state.person,
  locale: state.locale,
});

interface PathParamsType {
  param1: string;
}

interface Props extends RouteComponentProps<PathParamsType> {
  setData: typeof setCondition;
  person: PersonState;
  locale: LocaleState;
}

interface State {
  shouldValidate: boolean;
}

class RegisterType extends Component<Props, State> {
  public state = {
    shouldValidate: false,
  };

  private handleChange = (event: ChangeEvent<TargetElements>) => {
    const { name, value } = event.target;
    const { setData } = this.props;

    if (name === 'hasChild') {
      setData((name as keyof PersonCondition), value === 'true');
    } else {
      setData((name as keyof PersonCondition), value);
    }
  };

  private handleNext = () => {
    const { person, history } = this.props;
    const { visaType, marriageCondition, hasChild } = person.condition;

    const queryString = getURLParams().toString();
    const params = queryString ? `?${queryString}` : '';

    this.setState({ shouldValidate: true });

    if (!visaType || !marriageCondition || hasChild === undefined) {
      return;
    }

    history.push(`/register${params}`);
  };

  public render() {
    const { shouldValidate } = this.state;
    const { locale, person } = this.props;
    const { visaType, marriageCondition, hasChild } = person.condition;
    const intl = new IntelMessage(locale.langCode);

    return (
      <div style={AppContainerStyle}>
        <Title text={intl.format(RegisterTypeMessages.title)} />
        <div>
          <SubTitle text={intl.format(RegisterTypeMessages.visaLabel)} />
          <FormControlLabel
            control={(
              <Radio
                name="visaType"
                checked={visaType === 'study'}
                onChange={this.handleChange}
                value="study"
              />
            )}
            label={intl.format(RegisterTypeMessages.studyVisa)}
          />
          <FormControlLabel
            control={(
              <Radio
                name="visaType"
                checked={visaType === 'immigration'}
                onChange={this.handleChange}
                value="immigration"
              />
            )}
            label={intl.format(RegisterTypeMessages.immigration)}
          />
          {
            shouldValidate && !visaType
            && <ErrorMessage text={intl.format(RegisterTypeMessages.errorVisaType)} />
          }
        </div>
        <Divider variant="fullWidth" style={{ marginTop: 36, marginBottom: 36 }} />
        <div>
          <SubTitle text={intl.format(RegisterTypeMessages.marriageLabel)} />
          <FormControlLabel
            control={(
              <Radio
                name="marriageCondition"
                checked={marriageCondition === 'neverMarried'}
                onChange={this.handleChange}
                value="neverMarried"
              />
            )}
            label={intl.format(RegisterTypeMessages.neverMarried)}
          />
          <FormControlLabel
            control={(
              <Radio
                name="marriageCondition"
                checked={marriageCondition === 'married'}
                onChange={this.handleChange}
                value="married"
              />
            )}
            label={intl.format(RegisterTypeMessages.married)}
          />
          <FormControlLabel
            control={(
              <Radio
                name="marriageCondition"
                checked={marriageCondition === 'divorced'}
                onChange={this.handleChange}
                value="divorced"
              />
            )}
            label={intl.format(RegisterTypeMessages.divorced)}
          />
          <FormControlLabel
            control={(
              <Radio
                name="marriageCondition"
                checked={marriageCondition === 'divorcedAndCurrentlyMarried'}
                onChange={this.handleChange}
                value="divorcedAndCurrentlyMarried"
              />
            )}
            label={intl.format(RegisterTypeMessages.remarried)}
          />
          {
            shouldValidate && !marriageCondition
            && <ErrorMessage text={intl.format(RegisterTypeMessages.errorMarriageCondition)} />
          }
        </div>
        <Divider variant="fullWidth" style={{ marginTop: 36, marginBottom: 36 }} />
        <div>
          <SubTitle text={intl.format(RegisterTypeMessages.childLabel)} />
          <FormControlLabel
            control={(
              <Radio
                name="hasChild"
                checked={hasChild === false}
                onChange={this.handleChange}
                value={false}
              />
            )}
            label={intl.format(RegisterTypeMessages.haveNoChild)}
          />
          <FormControlLabel
            control={(
              <Radio
                name="hasChild"
                checked={hasChild === true}
                onChange={this.handleChange}
                value
              />
            )}
            label={intl.format(RegisterTypeMessages.haveChild)}
          />
          {
            shouldValidate && hasChild === undefined
            && <ErrorMessage text={intl.format(RegisterTypeMessages.errorChildren)} />
          }
        </div>
        <Divider variant="fullWidth" style={{ marginTop: 36, marginBottom: 36 }} />
        <Button variant="contained" color="primary" onClick={this.handleNext}>
          {intl.format(RegisterTypeMessages.goNext)}
        </Button>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, { setData: setCondition })(RegisterType));
