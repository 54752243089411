import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import Helmet from 'react-helmet';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  frogTheme,
  theme,
} from './assets/styles';
import Auth from './components/application/Auth';
import Header from './components/application/Header';
import ScrollToTop from './components/ScrollToTop';
import { isFrogMode } from './utils/params';
import ApplicantDetails from './views/ApplicantDetails';
import Home from './views/Home';
import Login from './views/Login';
import PublicEditForm from './views/PublicEditForm';
import RegisterForm from './views/RegisterForm';
import RegisterInfo from './views/RegisterInfo';
import RegisterType from './views/RegisterType';

const helmetDOM = (): JSX.Element => (
  isFrogMode() ? (
    <Helmet>
      <title>Frog Creator Production Inc.</title>
      <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/frog-favicon.ico`} />
    </Helmet>
  ) : (
    <Helmet>
      <title>COS Immigration Consulting</title>
      <link rel="shortcut icon" type="image/png" href={`${process.env.PUBLIC_URL}/favicon.ico`} />
    </Helmet>
  )
);

const App = () => {
  const themeUsed = isFrogMode() ? frogTheme : theme;

  return (
    <Router>
      {helmetDOM()}
      <ScrollToTop>
        <MuiThemeProvider theme={themeUsed}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
              <Header />
              <div>
                <Switch>
                  <Route exact path="/" component={RegisterInfo} />
                  <Route path="/register-type" component={RegisterType} />
                  <Route path="/register" component={RegisterForm} />
                  <Route path="/login" render={props => <Login theme={themeUsed} {...props} />} />
                  <Route path="/:token([a-zA-Z0-9+!]{24})" component={PublicEditForm} />
                  <Auth>
                    <Switch>
                      <Route path="/home" component={Home} />
                      <Route path="/applicant/:id" component={ApplicantDetails} />
                    </Switch>
                  </Auth>
                  <Route path="*" render={(): JSX.Element => <Redirect to="/" />} />
                </Switch>
              </div>
            </div>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </ScrollToTop>
    </Router>
  );
};

export default App;
