import { ChildData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies

const SET_CHILD = 'SET_CHILD';
const ADD_ARRAY_CHILD = 'ADD_ARRAY_CHILD';
const REMOVE_ARRAY_CHILD = 'REMOVE_ARRAY_CHILD';
const SET_CANADA_VISIT_ORIGINAL_CHILD = 'SET_CANADA_VISIT_ORIGINAL_CHILD';
const SET_CANADA_VISIT_RECENT_CHILD = 'SET_CANADA_VISIT_RECENT_CHILD';

export interface ChildState {
  children: ChildData[];
}

interface SetChildAction {
  type: typeof SET_CHILD;
  index: number;
  label: keyof ChildData;
  payload: any;
}

interface AddArrayAction {
  type: typeof ADD_ARRAY_CHILD;
}

interface RemoveArrayAction {
  type: typeof REMOVE_ARRAY_CHILD;
  index: number;
}

interface SetCanadaVisitOriginalAction {
  type: typeof SET_CANADA_VISIT_ORIGINAL_CHILD;
  index: number;
  label: string;
  payload: any;
}

interface SetCanadaVisitRecentAction {
  type: typeof SET_CANADA_VISIT_RECENT_CHILD;
  index: number;
  label: string;
  payload: any;
}

type ChildActionTypes =
  SetChildAction
  | AddArrayAction
  | RemoveArrayAction
  | SetCanadaVisitOriginalAction
  | SetCanadaVisitRecentAction;

const initialState: ChildState = {
  children: [{
    lastName: '',
    firstName: '',
    middleName: '',
    lastNameInMotherLanguage: '',
    firstNameInMotherLanguage: '',
    middleNameInMotherLanguage: '',
    birthDate: '',
    birthCountry: '',
    birthCity: '',
    marriageCondition: '',
    currentAddress: '',
    email: '',
    originalEntry: {
      date: '',
      place: '',
      visa: '',
      expiredDate: '',
    },
    recentEntry: {
      date: '',
      place: '',
      visa: '',
      expiredDate: '',
    },
  }],
};

function add(arr: ChildData[]): ChildData[] {
  const newArr = arr.slice();
  newArr.push({
    lastName: '',
    firstName: '',
    middleName: '',
    lastNameInMotherLanguage: '',
    firstNameInMotherLanguage: '',
    middleNameInMotherLanguage: '',
    birthDate: '',
    birthCountry: '',
    birthCity: '',
    marriageCondition: '',
    currentAddress: '',
    email: '',
    originalEntry: {
      date: '',
      place: '',
      visa: '',
      expiredDate: '',
    },
    recentEntry: {
      date: '',
      place: '',
      visa: '',
      expiredDate: '',
    },
  });
  return newArr;
}

function remove(arr: ChildData[], index: number): ChildData[] {
  const newArr = arr.slice();
  newArr.splice(index, 1);
  return newArr;
}

export function ChildReducer(
  state = initialState,
  action: ChildActionTypes,
): ChildState {
  const newState = state.children.slice();
  switch (action.type) {
    case SET_CHILD:
      newState[action.index] = {
        ...newState[action.index],
        [action.label]: action.payload,
      };
      return {
        children: newState,
      };
    case ADD_ARRAY_CHILD:
      return {
        children: add(state.children),
      };
    case REMOVE_ARRAY_CHILD:
      return {
        children: remove(state.children, action.index),
      };
    case SET_CANADA_VISIT_ORIGINAL_CHILD:
      newState[action.index] = {
        ...newState[action.index],
        originalEntry: {
          ...newState[action.index].originalEntry,
          [action.label]: action.payload,
        },
      };
      return {
        children: newState,
      };
    case SET_CANADA_VISIT_RECENT_CHILD:
      newState[action.index] = {
        ...newState[action.index],
        recentEntry: {
          ...newState[action.index].recentEntry,
          [action.label]: action.payload,
        },
      };
      return {
        children: newState,
      };
    default:
      return state;
  }
}

export function setChild(
  index: number,
  label: keyof ChildData,
  value: any,
): ChildActionTypes {
  return {
    type: SET_CHILD,
    index,
    label,
    payload: value,
  };
}

export function addArray(): ChildActionTypes {
  return {
    type: ADD_ARRAY_CHILD,
  };
}

export function removeArray(
  index: number,
): ChildActionTypes {
  return {
    type: REMOVE_ARRAY_CHILD,
    index,
  };
}

export function setCanadaVisitOriginalChild(
  index: number,
  label: string,
  value: any,
): ChildActionTypes {
  return {
    type: SET_CANADA_VISIT_ORIGINAL_CHILD,
    index,
    label,
    payload: value,
  };
}

export function setCanadaVisitRecentChild(
  index: number,
  label: string,
  value: any,
): ChildActionTypes {
  return {
    type: SET_CANADA_VISIT_RECENT_CHILD,
    index,
    label,
    payload: value,
  };
}
