import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/styles/withStyles';
import { format, isValid } from 'date-fns';
/* eslint-disable import/no-extraneous-dependencies */
import { EducationData } from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, {
  useEffect,
  useState,
} from 'react';
import { ChangeEventWithName } from '../../../abbreviation-types';
import RegisterFormApi from '../../../apis/registerForm';
import ApplicantDetailsContext from '../../../contexts/applicantDetails';
import { positionType } from '../../../modules/general';
import { LocaleState } from '../../../modules/locale';
import { sortHistorical } from '../../../utils/sort';
import Education from '../../forms/register/Education';
import EditableCardHeader from '../EditableCardHeader';
import EducationSummary from './EducationSummary';

interface Props {
  data?: EducationData[] | 'none';
  position: positionType;
}

const useStyles = makeStyles((theme: Theme): StyleRules => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
}));

const EducationHistory = (props: Props): JSX.Element => {
  const { data, position } = props;
  const classes = useStyles();
  const { allData, updateData } = React.useContext(ApplicantDetailsContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [educations, setEducations] = useState<EducationData[]>([]);
  const [isNothing, setIsNothing] = useState<boolean>(false);
  const locale: LocaleState = { langCode: 'en' };
  const initEducation = {
    start: '',
    end: '',
    name: '',
    country: '',
    city: '',
    major: '',
    type: '',
    visa: '',
  };

  useEffect((): void => {
    if (data && data !== 'none') setEducations(data);
  }, [data, isOpen]);

  const handleEdit = (): void => {
    setIsOpen(true);
  };

  const handleCancel = (): void => {
    if (data && data !== 'none') setEducations(data);

    setIsOpen(false);
  };

  const handleSave = async (): Promise<void> => {
    // Send data to DB
    const newEducations = isNothing ? [initEducation] : educations;

    if (position === 'applicant') {
      const payroll = {
        ...allData,
        educations: newEducations,
      };
      updateData(payroll);
      if (payroll._id) {
        await RegisterFormApi.update(payroll._id, payroll);
      } else {
        // eslint-disable-next-line
        console.error('payroll._id is null | undefined !!');
      }
    } else if (allData && allData.spouse) {
      const payroll = {
        ...allData,
        spouse: {
          ...allData.spouse,
          educations: newEducations,
        },
      };
      updateData(payroll);
      if (payroll._id) {
        await RegisterFormApi.update(payroll._id, payroll);
      } else {
        // eslint-disable-next-line
        console.error('payroll._id is null | undefined !!');
      }
    }

    setIsOpen(false);
  };

  // eslint-disable-next-line max-len
  const handleChange = (index: number): (event: ChangeEventWithName) => void => (event: ChangeEventWithName): void => {
    const { value, name } = event.target;
    const newEducations = educations.slice();

    if (!name) return;

    newEducations[index] = {
      ...newEducations[index],
      [name]: value,
    };
    setEducations(newEducations);
  };

  const setDuration = (index: number) => (name: 'start' | 'end') => (date: Date | null) => {
    if (isValid(date)) {
      const newEducations = educations.slice();
      newEducations[index] = {
        ...newEducations[index],
        [name]: date ? format(date, 'yyyy-MM-dd') : null,
      };
      setEducations(newEducations);
    }
  };

  const setIsNothingData = (position: positionType, value: unknown): void => {
    setIsNothing(!!value);
  };

  const addArrayData = (): void => {
    const newArray = educations.slice();
    newArray.push(initEducation);

    setEducations(newArray);
  };

  // eslint-disable-next-line no-shadow
  const removeArrayData = (position: positionType, index: number): void => {
    const newArray = educations.slice();
    newArray.splice(index, 1);

    setEducations(newArray);
  };

  const sortArrayData = (): void => {
    const newArray = educations.slice();
    const sortedArray = sortHistorical(newArray);

    setEducations(sortedArray);
  };

  return (
    <>
      <EditableCardHeader title="Education" edit={handleEdit} />
      <Paper className={classes.root}>
        <EducationSummary data={data} />
      </Paper>
      {
        isOpen
        && (
          <Dialog open={isOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Education</DialogTitle>
            <DialogContent>
              {
                data
                && (
                  <Education
                    position={position}
                    data={{
                      applicant: {
                        isNothing,
                        educations,
                      },
                      spouse: {
                        isNothing,
                        educations,
                      },
                    }}
                    locale={locale}
                    setIsNothingData={setIsNothingData}
                    addArrayData={addArrayData}
                    removeArrayData={removeArrayData}
                    sortArrayData={sortArrayData}
                    setData={handleChange}
                    setDuration={setDuration}
                  />
                )
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
};

export default EducationHistory;
