import Divider from '@material-ui/core/Divider/Divider';
import { ParentData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import IntlMessage from '../../../intl';
import { ApplicantMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';
import {
  ParentState,
  positionType,
  setFather,
  setMother,
} from '../../../modules/parent';
import Title from '../../application/Title';
import Person from '../parts/Person';

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
  data: state.parent,
});

export interface Props {
  position: positionType;
  locale: LocaleState;
  data: ParentState;
  setFatherData: typeof setFather;
  setMotherData: typeof setMother;
}

const Parent = (props: Props) => {
  const {
    data,
    locale,
    position,
    setFatherData,
    setMotherData,
  } = props;
  const { father, mother } = position === 'applicant' ? data.applicant : data.spouse;
  const intl = new IntlMessage(locale.langCode);

  const setData = (type: 'father' | 'mother') => (event: ChangeEvent<{ name?: string; value?: unknown }>) => {
    const { value, name } = event.target;
    const action = type === 'father' ? setFatherData : setMotherData;

    if (Object.prototype.hasOwnProperty.call(event.target, 'checked')) {
      action(position, (name as keyof ParentData), value === 'true');
    } else {
      action(position, (name as keyof ParentData), value);
    }
  };

  return (
    <div>
      <Title text={intl.format(ApplicantMessages.parentsLabel)} />
      <Person
        relationship={intl.format(ApplicantMessages.father)}
        setData={setData('father')}
        data={father}
      />
      <Divider variant="fullWidth" style={{ marginTop: 36, marginBottom: 36 }} />
      <Person
        relationship={intl.format(ApplicantMessages.mother)}
        setData={setData('mother')}
        data={mother}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  {
    setFatherData: setFather,
    setMotherData: setMother,
  },
)(Parent);
