import React from 'react';
import { PreviousSpouseData } from 'common_parts';
import { positionType, initialPreviousSpouse } from '../../../modules/previousSpouse';
import PreviousSpouse from '../../forms/register/PreviousSpouse';
interface Props {
  position: positionType;
  data: PreviousSpouseData[];
  setData: (data: PreviousSpouseData[]) => void;
}

const PreviousSpouseForm = (props: Props) => {
  const { position, data: previousSpouses, setData } = props;

  const add = (position: positionType): void => {
    const newArray = [...previousSpouses, { ...initialPreviousSpouse }];
    setData(newArray);
  }

  const remove = (position: positionType, index: number) => {
    const newArray = [...previousSpouses];
    newArray.splice(index, 1);
    setData(newArray);
  }

  const handleChange = (
    position: positionType, index: number, name: keyof PreviousSpouseData, value: any
  ): void => {
    if (!name) return;

    const newArray = [...previousSpouses];
    newArray[index] = {
      ...newArray[index],
      [name]: value,
    };

    setData(newArray);
  };

  return (
    <PreviousSpouse
      position={position}
      locale={{ langCode: 'en' }}
      data={{
        applicant: {
          previousSpouses: previousSpouses,
        },
        spouse: {
          previousSpouses: previousSpouses,
        }
      }}
      addArrayData={add}
      removeArrayData={remove}
      setData={handleChange}
    />
  )
}

export default PreviousSpouseForm;