import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/styles/withStyles';
import { GeneralData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies
import React from 'react';
import calcAge from '../../utils/calculation';

const useStyles = makeStyles((theme: Theme): StyleRules => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme: Theme): StyleRules => createStyles({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

interface Props {
  data: GeneralData;
}

const PersonInformation = (props: Props): JSX.Element => {
  const { data } = props;
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Applicant Information</StyledTableCell>
              <StyledTableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell align="left">First Name</StyledTableCell>
              <StyledTableCell align="left">
                {`${data.firstName} (${data.firstNameInMotherLanguage})`}
              </StyledTableCell>
            </TableRow>
            {
              !!data.middleName
              && !!data.middleNameInMotherLanguage
              && (
                <TableRow>
                  <StyledTableCell align="left">Middle Name</StyledTableCell>
                  <StyledTableCell align="left">
                    {`${data.middleName} (${data.middleNameInMotherLanguage})`}
                  </StyledTableCell>
                </TableRow>
              )
            }
            <TableRow>
              <StyledTableCell align="left">Last Name</StyledTableCell>
              <StyledTableCell align="left">{`${data.lastName} (${data.lastNameInMotherLanguage})`}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">{data.email}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="left">Current Address</StyledTableCell>
              <StyledTableCell align="left">{data.currentAddress}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="left">Home Phone</StyledTableCell>
              <StyledTableCell align="left">{data.homePhone}</StyledTableCell>
            </TableRow>
            {
              data.cellPhone !== undefined
              && (
                <TableRow>
                  <StyledTableCell align="left">Cell Phone</StyledTableCell>
                  <StyledTableCell align="left">{data.cellPhone ? data.cellPhone : '-'}</StyledTableCell>
                </TableRow>
              )
            }
            <TableRow>
              <StyledTableCell align="left">Birth Date</StyledTableCell>
              <StyledTableCell align="left">
                {`${data.birthDate} (${calcAge(new Date(data.birthDate))} yrs)`}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="left">Birth Country</StyledTableCell>
              <StyledTableCell align="left">{data.birthCountry}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="left">Birth City</StyledTableCell>
              <StyledTableCell align="left">{data.birthCity}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="left">Height</StyledTableCell>
              <StyledTableCell align="left">{data.height ? data.height : '-'}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="left">Eye Color</StyledTableCell>
              <StyledTableCell align="left">{data.eyeColor ? data.eyeColor : '-'}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="left">I have military service experience</StyledTableCell>
              <StyledTableCell align="left">{data.military ? 'Yes' : 'No'}</StyledTableCell>
            </TableRow>
            {
              data.military === true
              && data.militaryDescription !== undefined
              && (
                <TableRow>
                  <StyledTableCell align="left">Details of military service experience</StyledTableCell>
                  <StyledTableCell align="left">{data.militaryDescription}</StyledTableCell>
                </TableRow>
              )
            }
            <TableRow>
              <StyledTableCell align="left">I have criminal record(s)</StyledTableCell>
              <StyledTableCell align="left">{data.criminal ? 'Yes' : 'No'}</StyledTableCell>
            </TableRow>
            {
              data.criminal === true
              && data.criminalDescription !== undefined
              && (
                <TableRow>
                  <StyledTableCell align="left">Details of criminal record(s)</StyledTableCell>
                  <StyledTableCell align="left">{data.criminalDescription}</StyledTableCell>
                </TableRow>
              )
            }
            <TableRow>
              <StyledTableCell align="left">
                I have visa refusal record (Any country including Canada)
              </StyledTableCell>
              <StyledTableCell align="left">{data.visaRefusal ? 'Yes' : 'No'}</StyledTableCell>
            </TableRow>
            {
              data.visaRefusal === true
              && data.visaRefusalDescription !== undefined
              && (
                <TableRow>
                  <StyledTableCell align="left">Details of visa refusal</StyledTableCell>
                  <StyledTableCell align="left">{data.visaRefusalDescription}</StyledTableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default PersonInformation;
