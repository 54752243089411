import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { format, isValid } from 'date-fns';
/* eslint-disable import/no-extraneous-dependencies */
import { SiblingData } from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import { ChangeEventWithName } from '../../../abbreviation-types';
import EditApi from '../../../apis/edit';
import PublicEditContext from '../../../contexts/publicEdit';
import { positionType } from '../../../modules/general';
import EditableCardHeader from '../../console/EditableCardHeader';
import SiblingsInformationForm from './SiblingsInformationForm';
import SiblingsInformationTable from './SiblingsInformationTable';

type OwnProps = {
  position: positionType;
  token?: string;
  siblings?: SiblingData[];
};

const initSibling = {
  relationship: '',
  firstName: '',
  firstNameInMotherLanguage: '',
  lastName: '',
  lastNameInMotherLanguage: '',
  birthCity: '',
  birthCountry: '',
  currentAddress: '',
};

const SiblingsInformation = (props: OwnProps): JSX.Element => {
  const {
    position,
    siblings,
    token = '',
  } = props;
  const { allData, updateData } = React.useContext(PublicEditContext);
  const siblingsInitialState: SiblingData[] = [];
  const [siblingsData, setSiblingsData] = useState<SiblingData[]>(siblingsInitialState);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoadingStatus] = useState<boolean>(false);

  useEffect((): void => {
    if (siblings) setSiblingsData([...siblings]);
  }, [siblings, isOpen]);

  const handleEdit = (): void => {
    setIsOpen(true);
  };

  const handleCancel = (): void => {
    if (siblings) setSiblingsData(siblings);
    setIsOpen(false);
  };

  const handleSave = async (): Promise<void> => {
    // Send data to DB
    let payroll = { ...allData };
    if (position === 'applicant') {
      payroll = {
        ...allData,
        siblings: siblingsData,
      };
    }
    if (position === 'spouse' && allData && allData.spouse) {
      payroll = {
        ...allData,
        spouse: {
          ...allData.spouse,
          siblings: siblingsData,
        },
      };
    }
    updateData(payroll);

    if (token) {
      try {
        setLoadingStatus(true);
        await EditApi.update(token, payroll);
      } catch (e) {
        // TODO: Error Handling
        // if reached this area, the result is probably 404 status
        alert('Some error has occurred: ApplicantParentsUpdate applicant');
      } finally {
        setLoadingStatus(false);
      }
    } else {
      // eslint-disable-next-line
      console.error('token is null | undefined !!');
    }

    setIsOpen(false);
  };

  const handleChange = (index: number) => (event: ChangeEventWithName): void => {
    const name = event.target.name || '';
    const { value } = event.target;

    if (!name) return;

    const newSiblingsData = [...siblingsData];
    newSiblingsData[index] = {
      ...newSiblingsData[index],
      [name]: value,
    };

    setSiblingsData(newSiblingsData);
  };

  const setDate = (index: number, name: 'birthDate' | 'deathDate') => (date: Date | null): void => {
    if (!name) return;

    if (isValid(date)) {
      const newSiblingsData = [...siblingsData];
      newSiblingsData[index] = {
        ...newSiblingsData[index],
        [name]: date ? format(date, 'yyyy-MM-dd') : null,
      };

      setSiblingsData(newSiblingsData);
    }
  };

  const handleDeathSwitch = (index: number) => (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { checked } = target;

    const newSiblingsData = [...siblingsData];

    if (checked) {
      newSiblingsData[index] = {
        ...newSiblingsData[index],
        currentAddress: '',
        deathDate: '',
      };
    } else {
      newSiblingsData[index] = {
        ...newSiblingsData[index],
        currentAddress: '',
      };
      delete newSiblingsData[index].deathDate;
    }

    setSiblingsData(newSiblingsData);
  };

  const addArrayData = (): void => {
    setSiblingsData([...siblingsData, initSibling]);
  };

  const removeArrayData = (index: number): void => {
    const newArray = [...siblingsData];
    newArray.splice(index, 1);

    setSiblingsData(newArray);
  };

  return (
    <>
      <EditableCardHeader title="Siblings Information" edit={handleEdit} />
      <SiblingsInformationTable data={siblingsData} />
      {
        isOpen
        && (
          <Dialog open={isOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Siblings</DialogTitle>
            <DialogContent>
              <SiblingsInformationForm
                data={siblingsData}
                addArrayData={addArrayData}
                removeArrayData={removeArrayData}
                handleDeathSwitch={handleDeathSwitch}
                setData={handleChange}
                setDate={setDate}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} disabled={isLoading}>Cancel</Button>
              <Button onClick={handleSave} color="primary" disabled={isLoading}>Save</Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
};

export default SiblingsInformation;
