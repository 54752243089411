import {
  Grid,
  Typography,
} from '@material-ui/core';
import { ApplicantFormData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies
import React from 'react';
import { getFormattedDateTime } from '../../utils/format';

interface PartsProps {
  applicant: ApplicantFormData | null;
}

const ApplicantDetailsHeader = (props: PartsProps): JSX.Element => {
  const { applicant } = props;

  return (
    <Grid container justify="space-between" spacing={2}>
      <Grid item>
        <Typography variant="h5" style={{ marginBottom: 20 }}>
          Applicant Detail
        </Typography>
      </Grid>
      {
        applicant
        && (
          <Grid item>
            <Typography variant="body1">
              Received:
              {` ${getFormattedDateTime(applicant.createdAt)}`}
            </Typography>
            <Typography variant="body1">
              Updated:
              {` ${getFormattedDateTime(applicant.updatedAt)}`}
            </Typography>
          </Grid>
        )
      }
    </Grid>
  );
};

export default ApplicantDetailsHeader;
