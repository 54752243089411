import Divider from '@material-ui/core/Divider/Divider';
import Grid from '@material-ui/core/Grid/Grid';
import TextField from '@material-ui/core/TextField';
import { CanadaVisitData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies
import React, {
  ChangeEvent,
  Component,
} from 'react';
import { connect } from 'react-redux';
import IntlMessage from '../../../intl';
import { VisitedCanadaMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import {
  CanadaVisitState,
  positionType,
  setCanadaVisitOriginal,
  setCanadaVisitRecent,
} from '../../../modules/canadaVisit';
import { LocaleState } from '../../../modules/locale';
import SubTitle from '../../application/SubTitle';
import Title from '../../application/Title';

type TargetElements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
  data: state.canadaVisitInfo,
});

interface Props {
  position: positionType;
  locale: LocaleState;
  data: CanadaVisitState;
  setCanadaVisitOriginalData: typeof setCanadaVisitOriginal;
  setCanadaVisitRecentData: typeof setCanadaVisitRecent;
}

class VisitedCanada extends Component<Props> {
  private setData = (type: keyof CanadaVisitData) => (event: ChangeEvent<TargetElements>) => {
    const { value, id } = event.target;
    const label = id.split('-')[0];
    const { setCanadaVisitOriginalData, setCanadaVisitRecentData, position } = this.props;
    const action = type === 'originalEntry' ? setCanadaVisitOriginalData : setCanadaVisitRecentData;

    if (Object.prototype.hasOwnProperty.call(event.target, 'checked')) {
      action(position, label, value === 'true');
    } else {
      action(position, label, value);
    }
  };

  public render() {
    const { data, locale, position } = this.props;
    const { originalEntry, recentEntry } = position === 'applicant' ? data.applicant : data.spouse;
    const intl = new IntlMessage(locale.langCode);

    return (
      <div>
        <Title text={intl.format(VisitedCanadaMessages.visitCanadaInformation)} />
        <SubTitle text={intl.format(VisitedCanadaMessages.originalEntryLabel)} />
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3}>
            <TextField
              id="date-originalEntry"
              label={intl.format(VisitedCanadaMessages.date)}
              type="date"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={this.setData('originalEntry')}
              value={originalEntry && originalEntry.date}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              id="place-originalEntry"
              label={intl.format(VisitedCanadaMessages.place)}
              variant="outlined"
              fullWidth
              onChange={this.setData('originalEntry')}
              value={originalEntry && originalEntry.place}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              id="visa-originalEntry"
              label={intl.format(VisitedCanadaMessages.visa)}
              variant="outlined"
              helperText={intl.format(VisitedCanadaMessages.visaExample)}
              fullWidth
              onChange={this.setData('originalEntry')}
              value={originalEntry && originalEntry.visa}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              id="expiredDate-originalEntry"
              label={intl.format(VisitedCanadaMessages.expiredDate)}
              type="date"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={this.setData('originalEntry')}
              value={originalEntry && originalEntry.expiredDate}
            />
          </Grid>
        </Grid>
        <Divider variant="fullWidth" style={{ marginTop: 36, marginBottom: 36 }} />
        <SubTitle text={intl.format(VisitedCanadaMessages.recentlyEntryLabel)} />
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3}>
            <TextField
              id="date-recentEntry"
              label={intl.format(VisitedCanadaMessages.date)}
              type="date"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={this.setData('recentEntry')}
              value={recentEntry && recentEntry.date}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              id="place-recentEntry"
              label={intl.format(VisitedCanadaMessages.place)}
              variant="outlined"
              fullWidth
              onChange={this.setData('recentEntry')}
              value={recentEntry && recentEntry.place}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              id="visa-recentEntry"
              label={intl.format(VisitedCanadaMessages.visa)}
              variant="outlined"
              helperText={intl.format(VisitedCanadaMessages.visaExample)}
              fullWidth
              onChange={this.setData('recentEntry')}
              value={recentEntry && recentEntry.visa}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              id="expiredDate-recentEntry"
              label={intl.format(VisitedCanadaMessages.expiredDate)}
              type="date"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={this.setData('recentEntry')}
              value={recentEntry && recentEntry.expiredDate}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  {
    setCanadaVisitOriginalData: setCanadaVisitOriginal,
    setCanadaVisitRecentData: setCanadaVisitRecent,
  },
)(VisitedCanada);
