import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker } from '@material-ui/pickers';
/* eslint-disable import/no-extraneous-dependencies */
import { SiblingData } from 'common_parts';
import React, { ChangeEvent } from 'react';
/* eslint-enable import/no-extraneous-dependencies */
import { ChangeEventWithName } from '../../../abbreviation-types';
import IntlMessage from '../../../intl';
import {
  GeneralMessages,
  PersonMessages,
} from '../../../intl/messages';
import CurrentAddress from '../../forms/parts/CurrentAddress';
import MarriageCondition from '../../forms/parts/MarriageCondition';
import Name from '../../forms/parts/Name';
import Place from '../../forms/parts/Place';

type Props = {
  //  position: positionType;
  data?: SiblingData[];
  addArrayData: () => void;
  removeArrayData: (index: number) => void;
  handleDeathSwitch: (index:number) => (event: ChangeEvent<HTMLInputElement>) => void,
  setData: (index: number) => (event: ChangeEventWithName) => void;
  setDate: (index: number, name:'birthDate'|'deathDate') => (date: Date | null) => void;
};

const SiblingsInformationFrom = (props: Props) => {
  const {
    data = [],
    addArrayData,
    removeArrayData,
    handleDeathSwitch,
    setData,
    setDate,
  } = props;
  const intl = new IntlMessage('en');

  return (
    <>
      {
        data.map((sibling, index) => {
          const {
            relationship,
            firstName,
            lastName,
            middleName,
            firstNameInMotherLanguage,
            lastNameInMotherLanguage,
            middleNameInMotherLanguage,
            birthDate,
            birthCountry,
            birthCity,
            marriageCondition,
            email,
            deathDate,
            currentAddress,
          } = sibling;

          return (
            <Card style={{ marginBottom: 12 }} key={index}>
              <CardContent>
                <TextField
                  name="relationship"
                  label={intl.format(PersonMessages.relationship)}
                  variant="outlined"
                  margin="normal"
                  onChange={setData(index)}
                  value={relationship}
                  required
                />
                <Name
                  label={intl.format(GeneralMessages.english)}
                  language=""
                  setData={setData(index)}
                  firstName={firstName}
                  lastName={lastName}
                  middleName={middleName}
                  originalFamilyName={null}
                />
                <Name
                  label={intl.format(GeneralMessages.motherLanguage)}
                  language="InMotherLanguage"
                  setData={setData(index)}
                  firstName={firstNameInMotherLanguage}
                  lastName={lastNameInMotherLanguage}
                  middleName={middleNameInMotherLanguage}
                  originalFamilyName={null}
                />
                <div>
                  <KeyboardDatePicker
                    name="birthDate"
                    format="yyyy-MM-dd"
                    variant="inline"
                    inputVariant="outlined"
                    margin="normal"
                    label={intl.format(GeneralMessages.dateOfBirth)}
                    value={birthDate ? `${birthDate}T12:00:00Z` : null}
                    onChange={setDate(index, 'birthDate')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </div>
                <Place
                  label={intl.format(GeneralMessages.placeOfBirth)}
                  category="birth"
                  setData={setData(index)}
                  country={birthCountry}
                  city={birthCity}
                />
                <MarriageCondition
                  value={marriageCondition}
                  setValue={setData(index)}
                />
                <TextField
                  name="email"
                  label={intl.format(GeneralMessages.emailAddress)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  onChange={setData(index)}
                  value={email}
                />
                <CurrentAddress
                  deathDate={deathDate}
                  currentAddress={currentAddress}
                  handleDeathSwitch={handleDeathSwitch(index)}
                  setValue={setData(index)}
                  setDate={setDate(index, 'deathDate')}
                />
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                  >
                    <IconButton aria-label="Delete" onClick={() => removeArrayData(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })
      }
      <div>
        <Button color="primary" onClick={addArrayData}>
          ADD
        </Button>
      </div>
    </>
  );
};

export default SiblingsInformationFrom;
