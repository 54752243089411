import { WorkExperienceData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies

const SET_WORK_EXPERIENCE = 'SET_WORK_EXPERIENCE';
const SET_IS_NOTHING_WORK = 'SET_IS_NOTHING_WORK';
const ADD_ARRAY_WORK = 'ADD_ARRAY_WORK';
const REMOVE_ARRAY_WORK = 'REMOVE_ARRAY_WORK';
const SORT_ARRAY_WORK = 'SORT_ARRAY_WORK';
export type positionType = 'applicant' | 'spouse';

export interface WorkExperienceState {
  applicant: {
    isNothing: boolean;
    workExperiences: WorkExperienceData[];
  };
  spouse: {
    isNothing: boolean;
    workExperiences: WorkExperienceData[];
  };
}

interface SetWorkExperienceAction {
  type: typeof SET_WORK_EXPERIENCE;
  position: positionType;
  index: number;
  label: keyof WorkExperienceData;
  payload: any;
}

interface SetIsNothingAction {
  type: typeof SET_IS_NOTHING_WORK;
  position: positionType;
  payload: any;
}

interface AddArrayAction {
  type: typeof ADD_ARRAY_WORK;
  position: positionType;
}

interface RemoveArrayAction {
  type: typeof REMOVE_ARRAY_WORK;
  position: positionType;
  index: number;
}

interface SortArrayAction {
  type: typeof SORT_ARRAY_WORK;
  position: positionType;
}

type WorkExperienceActionTypes =
  SetWorkExperienceAction
  | SetIsNothingAction
  | AddArrayAction
  | RemoveArrayAction
  | SortArrayAction;

const initialState: WorkExperienceState = {
  applicant: {
    isNothing: false,
    workExperiences: [{
      start: '',
      end: '',
      name: '',
      address: '',
      city: '',
      province: '',
      country: '',
      hourPerWeek: '',
      occupation: '',
      annualSalary: 0,
      isManager: false,
      visa: '',
    }],
  },
  spouse: {
    isNothing: false,
    workExperiences: [{
      start: '',
      end: '',
      name: '',
      address: '',
      city: '',
      province: '',
      country: '',
      hourPerWeek: '',
      occupation: '',
      annualSalary: 0,
      isManager: false,
      visa: '',
    }],
  },
};

function add(workExperienceData: WorkExperienceData[]): WorkExperienceData[] {
  const newArray = workExperienceData.slice();
  newArray.push({
    start: '',
    end: '',
    name: '',
    address: '',
    city: '',
    province: '',
    country: '',
    hourPerWeek: '',
    occupation: '',
    annualSalary: 0,
    isManager: false,
    visa: '',
  });
  return newArray;
}

function remove(workExperienceData: WorkExperienceData[], index: number): WorkExperienceData[] {
  const newArray = workExperienceData.slice();
  newArray.splice(index, 1);
  return newArray;
}

/**
 * TODO: Commonality
 * Sort the received WorkExperienceData array in the following order:
 *   1. "start" has been entered but "end" has not been entered
 *   2. when "start" and "end" are entered, sort "start" date by date
 *   3. "start" has not been entered
 *
 * @param workExperienceData
 */
function sort(workExperienceData: WorkExperienceData[]): WorkExperienceData[] {
  const newArray = workExperienceData.slice();

  return newArray.sort((a, b) => {
    if (a.start && !a.end) {
      return -1;
    }
    if (b.start && !b.end) {
      return 1;
    }

    if (!a.start) {
      return 1;
    }
    if (!b.start) {
      return -1;
    }

    return new Date(b.start).getTime() - new Date(a.start).getTime();
  });
}

export function WorkExperienceReducer(
  state = initialState,
  action: WorkExperienceActionTypes,
): WorkExperienceState {
  switch (action.type) {
    case SET_WORK_EXPERIENCE: {
      const {
        index, label, payload, position,
      } = action;
      if (position === 'applicant') {
        const newState = state.applicant.workExperiences.slice();
        newState[index] = {
          ...newState[index],
          [label]: payload,
        };
        return {
          applicant: {
            isNothing: state.applicant.isNothing,
            workExperiences: newState,
          },
          spouse: state.spouse,
        };
      }
      const newState = state.spouse.workExperiences.slice();
      newState[index] = {
        ...newState[index],
        [label]: payload,
      };
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: state.spouse.isNothing,
          workExperiences: newState,
        },
      };
    }
    case SET_IS_NOTHING_WORK:
      if (action.position === 'applicant') {
        return {
          applicant: {
            isNothing: action.payload,
            workExperiences: state.applicant.workExperiences,
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: action.payload,
          workExperiences: state.spouse.workExperiences,
        },
      };
    case ADD_ARRAY_WORK:
      if (action.position === 'applicant') {
        return {
          applicant: {
            isNothing: state.applicant.isNothing,
            workExperiences: add(state.applicant.workExperiences),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: state.spouse.isNothing,
          workExperiences: add(state.spouse.workExperiences),
        },
      };
    case REMOVE_ARRAY_WORK:
      if (action.position === 'applicant') {
        return {
          applicant: {
            isNothing: state.applicant.isNothing,
            workExperiences: remove(state.applicant.workExperiences, action.index),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: state.spouse.isNothing,
          workExperiences: remove(state.spouse.workExperiences, action.index),
        },
      };
    case SORT_ARRAY_WORK:
      if (action.position === 'applicant') {
        return {
          applicant: {
            isNothing: state.applicant.isNothing,
            workExperiences: sort(state.applicant.workExperiences),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: state.spouse.isNothing,
          workExperiences: sort(state.spouse.workExperiences),
        },
      };
    default:
      return state;
  }
}

export function setWorkExperience(
  position: positionType,
  index: number,
  label: keyof WorkExperienceData,
  value: any,
): WorkExperienceActionTypes {
  return {
    type: SET_WORK_EXPERIENCE,
    position,
    index,
    label,
    payload: value,
  };
}

export function setIsNothing(
  position: positionType,
  value: any,
): WorkExperienceActionTypes {
  return {
    type: SET_IS_NOTHING_WORK,
    position,
    payload: value,
  };
}

export function addArray(
  position: positionType,
): WorkExperienceActionTypes {
  return {
    type: ADD_ARRAY_WORK,
    position,
  };
}

export function removeArray(
  position: positionType,
  index: number,
): WorkExperienceActionTypes {
  return {
    type: REMOVE_ARRAY_WORK,
    position,
    index,
  };
}

export function sortArray(
  position: positionType,
): WorkExperienceActionTypes {
  return {
    type: SORT_ARRAY_WORK,
    position,
  };
}
