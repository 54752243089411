import { EducationData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies

const SET_EDUCATION = 'SET_EDUCATION';
const SET_IS_NOTHING_EDUCATION = 'SET_IS_NOTHING_EDUCATION';
const ADD_ARRAY_EDUCATION = 'ADD_ARRAY_EDUCATION';
const REMOVE_ARRAY_EDUCATION = 'REMOVE_ARRAY_EDUCATION';
const SORT_ARRAY_EDUCATION = 'SORT_ARRAY_EDUCATION';
export type positionType = 'applicant' | 'spouse';

export interface EducationState {
  applicant: {
    isNothing: boolean;
    educations: EducationData[];
  };
  spouse: {
    isNothing: boolean;
    educations: EducationData[];
  };
}

interface SetEducationAction {
  type: typeof SET_EDUCATION;
  position: positionType;
  index: number;
  label: keyof EducationData;
  payload: any;
}

interface SetIsNothingAction {
  type: typeof SET_IS_NOTHING_EDUCATION;
  position: positionType;
  payload: any;
}

interface AddArrayAction {
  type: typeof ADD_ARRAY_EDUCATION;
  position: positionType;
}

interface RemoveArrayAction {
  type: typeof REMOVE_ARRAY_EDUCATION;
  position: positionType;
  index: number;
}

interface SortArrayAction {
  type: typeof SORT_ARRAY_EDUCATION;
  position: positionType;
}

type EducationActionTypes =
  SetEducationAction
  | SetIsNothingAction
  | AddArrayAction
  | RemoveArrayAction
  | SortArrayAction;

const initialState: EducationState = {
  applicant: {
    isNothing: false,
    educations: [{
      start: '',
      end: '',
      name: '',
      country: '',
      city: '',
      major: '',
      type: '',
      visa: '',
    }],
  },
  spouse: {
    isNothing: false,
    educations: [{
      start: '',
      end: '',
      name: '',
      country: '',
      city: '',
      major: '',
      type: '',
      visa: '',
    }],
  },
};

function add(educationData: EducationData[]): EducationData[] {
  const newArray = educationData.slice();
  newArray.push({
    start: '',
    end: '',
    name: '',
    country: '',
    city: '',
    major: '',
    type: '',
    visa: '',
  });
  return newArray;
}

function remove(educationData: EducationData[], index: number): EducationData[] {
  const newArray = educationData.slice();
  newArray.splice(index, 1);
  return newArray;
}

/**
 * TODO: Commonality
 * Sort the received EducationData array in the following order:
 *   1. "start" has been entered but "end" has not been entered
 *   2. when "start" and "end" are entered, sort "start" date by date
 *   3. "start" has not been entered
 *
 * @param educationData
 */
function sort(educationData: EducationData[]): EducationData[] {
  const newArray = educationData.slice();

  return newArray.sort((a, b) => {
    if (a.start && !a.end) {
      return -1;
    }
    if (b.start && !b.end) {
      return 1;
    }

    if (!a.start) {
      return 1;
    }
    if (!b.start) {
      return -1;
    }

    return new Date(b.start).getTime() - new Date(a.start).getTime();
  });
}

export function EducationReducer(
  state = initialState,
  action: EducationActionTypes,
): EducationState {
  switch (action.type) {
    case SET_EDUCATION: {
      const {
        index, label, payload, position,
      } = action;
      if (position === 'applicant') {
        const newState = state.applicant.educations.slice();
        newState[index] = {
          ...newState[index],
          [label]: payload,
        };
        return {
          applicant: {
            isNothing: state.applicant.isNothing,
            educations: newState,
          },
          spouse: state.spouse,
        };
      }
      const newState = state.spouse.educations.slice();
      newState[index] = {
        ...newState[index],
        [label]: payload,
      };
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: state.spouse.isNothing,
          educations: newState,
        },
      };
    }
    case SET_IS_NOTHING_EDUCATION:
      if (action.position === 'applicant') {
        return {
          applicant: {
            isNothing: action.payload,
            educations: state.applicant.educations,
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: action.payload,
          educations: state.spouse.educations,
        },
      };
    case ADD_ARRAY_EDUCATION:
      if (action.position === 'applicant') {
        return {
          applicant: {
            isNothing: state.applicant.isNothing,
            educations: add(state.applicant.educations),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: state.spouse.isNothing,
          educations: add(state.spouse.educations),
        },
      };
    case REMOVE_ARRAY_EDUCATION:
      if (action.position === 'applicant') {
        return {
          applicant: {
            isNothing: state.applicant.isNothing,
            educations: remove(state.applicant.educations, action.index),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: state.spouse.isNothing,
          educations: remove(state.spouse.educations, action.index),
        },
      };
    case SORT_ARRAY_EDUCATION:
      if (action.position === 'applicant') {
        return {
          applicant: {
            isNothing: state.applicant.isNothing,
            educations: sort(state.applicant.educations),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          isNothing: state.spouse.isNothing,
          educations: sort(state.spouse.educations),
        },
      };
    default:
      return state;
  }
}

export function setEducation(
  position: positionType,
  index: number,
  label: keyof EducationData,
  value: any,
): EducationActionTypes {
  return {
    type: SET_EDUCATION,
    position,
    index,
    label,
    payload: value,
  };
}

export function setIsNothing(
  position: positionType,
  value: any,
): EducationActionTypes {
  return {
    type: SET_IS_NOTHING_EDUCATION,
    position,
    payload: value,
  };
}

export function addArray(
  position: positionType,
): EducationActionTypes {
  return {
    type: ADD_ARRAY_EDUCATION,
    position,
  };
}

export function removeArray(
  position: positionType,
  index: number,
): EducationActionTypes {
  return {
    type: REMOVE_ARRAY_EDUCATION,
    position,
    index,
  };
}

export function sortArray(
  position: positionType,
): EducationActionTypes {
  return {
    type: SORT_ARRAY_EDUCATION,
    position,
  };
}
