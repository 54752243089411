import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
/* eslint-disable import/no-extraneous-dependencies */
import {
  ApplicantFormData,
  AuthorityPermission,
  CanadaVisitData,
  ResponseData,
  SpouseData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router';
import EditApi from '../apis/edit';
import { AppContainerStyle } from '../assets/styles';
import EditableCardHeader from '../components/console/EditableCardHeader';
import FamilyStructure from '../components/console/FamilyStructure';
import PersonalHistory from '../components/console/PersonalHistory';
import PersonInformation from '../components/console/PersonInformation';
import CanadaEntryHistory from '../components/public-edit/CanadaEntryHistory';
import ChildrenInformation from '../components/public-edit/ChildInformation';
import EducationHistory from '../components/public-edit/EducationHisotry';
import LanguageExamScores from '../components/public-edit/LanguageExamScores';
import ParentsInformation from '../components/public-edit/ParentsInformation';
import PreviousSpouse from '../components/public-edit/PreviousSpouse';
import PublicEditDialog from '../components/public-edit/PublicEditDialog';
import ResidentHistory from '../components/public-edit/ResidentHistory';
import SiblingsInformation from '../components/public-edit/SiblingsInformation';
import TravelHistory from '../components/public-edit/TravelHistory';
import WorkExperience from '../components/public-edit/WorkExperience';
import PublicEditContext from '../contexts/publicEdit';
import { positionType } from '../modules/general';
import { getFormattedDateTime } from '../utils/format';

const PageHeader = (props: { applicant: ApplicantFormData }): JSX.Element => {
  const { applicant } = props;
  if (!applicant) return <div />;

  return (
    <Grid container justify="space-between" spacing={2}>
      <Grid item>
        <Typography variant="h5" style={{ marginBottom: 20 }}>
          Your Information
        </Typography>
      </Grid>
      {
        applicant
        && (
          <Grid item>
            <Typography variant="body1">
              Received:
              {` ${getFormattedDateTime(applicant.createdAt)}`}
            </Typography>
            <Typography variant="body1">
              Updated:
              {` ${getFormattedDateTime(applicant.updatedAt)}`}
            </Typography>
          </Grid>
        )
      }
    </Grid>
  );
};

const PageContent = (props: {
  applicant: ApplicantFormData;
  token: string;
  permission: AuthorityPermission;
  updateApplicant: (data: ApplicantFormData) => void;
}): JSX.Element => {
  const {
    applicant,
    token,
    permission,
    updateApplicant,
  } = props;
  const [applicantData, setApplicant] = useState<ApplicantFormData>(applicant);
  const [isOpen, setOpenStatus] = useState<boolean>(false);
  const [type, setType] = useState<positionType>('applicant');

  if (!applicant) return <div />;

  const { children, spouse, visaType } = applicantData;

  const handleEdit = (position: positionType): () => void => (): void => {
    setOpenStatus(true);
    setType(position);
  };

  const handleClose = (): void => {
    setOpenStatus(false);
  };

  const updateData = (data: ApplicantFormData): void => {
    updateApplicant(data);
    setApplicant(data);
  };

  const createPersonFiled = (
    data: ApplicantFormData | SpouseData,
    position: positionType,
  ): JSX.Element => {
    const {
      general,
      educations,
      language,
      father,
      mother,
      siblings,
      canadaVisitInfo,
      workExperiences,
      travelHistory,
      residentHistory,
      previousSpouses,
    } = data;
    const { birthDate } = general;

    const canadaEntryHistory: CanadaVisitData = (!canadaVisitInfo || canadaVisitInfo === 'none')
      ? {}
      : canadaVisitInfo;

    return (
      <div key={`${data._id}`}>
        {
          permission?.parents
          && (
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <ParentsInformation
                  position={position}
                  father={father}
                  mother={mother}
                  token={token}
                />
              </CardContent>
            </Card>
          )
        }
        {
          permission?.siblings
          && (
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <SiblingsInformation
                  position={position}
                  siblings={siblings}
                  token={token}
                />
              </CardContent>
            </Card>
          )
        }
        {
          permission?.previousSpouses
          && (
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <PreviousSpouse data={previousSpouses || []} position={position} token={token} />
              </CardContent>
            </Card>
          )
        }
        {
          language
          && permission?.languageExamScores
          && (
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <LanguageExamScores data={language} position={position} token={token} />
              </CardContent>
            </Card>
          )
        }
        {
          (
            permission?.educationHistory
            || permission?.workExperiences
            || permission?.residentHistory
          ) && (
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <PersonalHistory
                  birthday={birthDate}
                  educationData={educations === 'none' ? [] : educations}
                  residentHistoryData={residentHistory}
                  workExperienceData={workExperiences === 'none' ? [] : workExperiences}
                />
              </CardContent>
            </Card>
          )
        }
        {
          permission?.educationHistory
          && (
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <EducationHistory data={educations} position={position} token={token} />
              </CardContent>
            </Card>
          )
        }
        {
          permission?.workExperiences
          && (
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <WorkExperience data={workExperiences} position={position} token={token} />
              </CardContent>
            </Card>
          )
        }
        {
          permission?.residentHistory
          && (
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <ResidentHistory
                  residentHistory={residentHistory}
                  position={position}
                  token={token}
                />
              </CardContent>
            </Card>
          )
        }
        {
          permission?.travelHistory
          && (
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <TravelHistory data={travelHistory} position={position} token={token} />
              </CardContent>
            </Card>
          )
        }
        {
          permission?.canadaEntryHistory
          && (
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <CanadaEntryHistory
                  position={position}
                  data={canadaEntryHistory}
                  token={token}
                />
              </CardContent>
            </Card>
          )
        }
      </div>
    );
  };

  return (
    <>
      <Card style={{ marginBottom: 12 }}>
        <CardContent>
          <EditableCardHeader title="Family Structure" />
          <FamilyStructure formData={applicant} />
        </CardContent>
      </Card>
      {
        permission?.general
        && (
          <div>
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <EditableCardHeader
                  title="Applicant Information"
                  edit={handleEdit('applicant')}
                />
                <PersonInformation data={applicant.general} />
              </CardContent>
            </Card>
          </div>
        )
      }
      <div>
        <PublicEditContext.Provider
          value={{ allData: applicant, updateData }}
        >
          {createPersonFiled(applicant, 'applicant')}
        </PublicEditContext.Provider>
      </div>
      {
        !!spouse
        && permission?.spouse
        && (
          <>
            <Typography variant="h5" style={{ marginBottom: 20 }}>Spouse Detail</Typography>
            <Card style={{ marginBottom: 12 }}>
              <CardContent>
                <EditableCardHeader
                  title="Spouse Information"
                  edit={handleEdit('spouse')}
                />
                <PersonInformation data={spouse.general} />
              </CardContent>
            </Card>
            <div>
              <PublicEditContext.Provider
                value={{ allData: applicant, updateData }}
              >
                {createPersonFiled(spouse, 'spouse')}
              </PublicEditContext.Provider>
            </div>
          </>
        )
      }
      {
        !!children
        && permission?.children
        && (
          <>
            <Typography variant="h5" style={{ marginBottom: 20 }}>Children Detail</Typography>
            <PublicEditContext.Provider
              value={{ allData: applicant, updateData }}
            >
              <Card style={{ marginBottom: 12 }}>
                <CardContent>
                  <ChildrenInformation data={children} visaType={visaType} token={token} />
                </CardContent>
              </Card>
            </PublicEditContext.Provider>
          </>
        )
      }
      <PublicEditContext.Provider
        value={{ allData: applicant, updateData }}
      >
        <PublicEditDialog
          isOpen={isOpen}
          handleClose={handleClose}
          applicant={applicant}
          visaType={visaType}
          position={type}
          token={token}
        />
      </PublicEditContext.Provider>
    </>
  );
};

const PublicEditForm = (): JSX.Element => {
  const [isLoading, setLoadingStatus] = useState(false);
  const [applicant, setApplicant] = useState<ApplicantFormData>();
  const [editPermission, setEditPermission] = useState<AuthorityPermission>({
    residentHistory: false,
    previousSpouses: false,
    canadaEntryHistory: false,
    children: false,
    educationHistory: false,
    general: false,
    languageExamScores: false,
    parents: false,
    siblings: false,
    spouse: false,
    travelHistory: false,
    workExperiences: false,
  });

  const { token = '' } = useParams<{ token?: string }>();
  const history = useHistory();

  useEffect(
    () => {
      const fetchData = async (): Promise<void> => {
        setLoadingStatus(true);

        let result: ResponseData<{
          formData: ApplicantFormData;
          permission: AuthorityPermission;
        }> | null = null;
        try {
          result = await EditApi.get(token);
        } catch (e) {
          // TODO: Error Handling
          // if reached this area, the result probably be 404 status
        }

        if (!result || result.message !== 'success') {
          alert('This link is expired.');
          history.push('/');
          return;
        }

        const { data } = result;
        const { formData, permission } = data;
        setApplicant(formData);
        setEditPermission(permission);
        setLoadingStatus(false);
      };

      fetchData();
    },
    [history, token],
  );

  return (
    <div style={AppContainerStyle}>
      {
        isLoading || !applicant
          ? <CircularProgress size={24} className="loading-spinner" />
          : (
            <>
              <PageHeader applicant={applicant} />
              <PageContent
                applicant={applicant}
                token={token}
                permission={editPermission}
                updateApplicant={setApplicant}
              />
            </>
          )
      }
    </div>
  );
};

export default PublicEditForm;
