import { FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch/Switch';
import TextField from '@material-ui/core/TextField/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import React, {
  ChangeEvent,
  Component,
} from 'react';
import { ChangeEventWithName } from '../../../abbreviation-types';
import IntlMessage from '../../../intl';
import { WorkExperienceMessages } from '../../../intl/messages';
import { LocaleState } from '../../../modules/locale';
import { visaTypes } from '../../../modules/person';
import {
  addArray,
  positionType,
  removeArray,
  setIsNothing,
  sortArray,
  WorkExperienceState,
} from '../../../modules/workExperience';
import { PRESENT } from '../../../utils/constants';
import Title from '../../application/Title';
import AdministrativeDivisions from '../parts/AdministrativeDivisions';
import DurationWithPresent from '../parts/DurationWithPresent';

interface Props {
  visaType?: visaTypes;
  position: positionType;
  data: WorkExperienceState;
  locale: LocaleState;
  setIsNothingData: typeof setIsNothing | ((position: positionType, value: any) => void); // eslint-disable-line @typescript-eslint/no-explicit-any, max-len
  addArrayData: typeof addArray | ((position: positionType) => void);
  removeArrayData: typeof removeArray | ((position: positionType, index: number) => void);
  sortArrayData: typeof sortArray | ((position: positionType) => void);
  setData: (index: number) => (event: ChangeEventWithName) => void;
  setDuration: (index: number) => (name: 'start' | 'end') => (date: Date | typeof PRESENT | null) => void;
}

class WorkExperience extends Component<Props> {
  public componentDidMount(): void {
    this.sortData();
  }

  private handleChangeSwitch = (event: ChangeEvent<HTMLInputElement>): void => {
    const { setIsNothingData, position } = this.props;
    setIsNothingData(position, event.target.checked);
  };

  private add = (): void => {
    const { addArrayData, position } = this.props;
    addArrayData(position);
  };

  private remove = (index: number): () => void => (): void => {
    const { removeArrayData, position } = this.props;
    removeArrayData(position, index);
  };

  private sortData = (): void => {
    const { sortArrayData, position } = this.props;
    sortArrayData(position);
  };

  public render(): JSX.Element {
    const {
      data,
      position,
      locale,
      visaType,
      setData,
      setDuration,
    } = this.props;
    const {
      isNothing,
      workExperiences,
    } = position === 'applicant' ? data.applicant : data.spouse;
    const { length } = workExperiences;
    const list = [];
    const intl = new IntlMessage(locale.langCode);
    const isStudy = visaType === 'study';

    for (let i = 0; i < length; i += 1) {
      list.push(
        <React.Fragment key={i}>
          <Card style={{ marginBottom: 12 }}>
            <CardContent>
              <DurationWithPresent
                setData={setDuration(i)}
                start={workExperiences[i].start}
                end={workExperiences[i].end}
              />
              <TextField
                name="name"
                label={intl.format(WorkExperienceMessages.companyName)}
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={setData(i)}
                value={workExperiences[i].name}
              />
              <TextField
                name="address"
                label={intl.format(WorkExperienceMessages.address)}
                variant="outlined"
                helperText={intl.format(WorkExperienceMessages.addressExample)}
                margin="normal"
                fullWidth
                onChange={setData(i)}
                value={workExperiences[i].address}
              />
              <AdministrativeDivisions
                city={workExperiences[i].city}
                province={workExperiences[i].province}
                country={workExperiences[i].country}
                setData={setData(i)}
              />
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <TextField
                    name="occupation"
                    label={intl.format(WorkExperienceMessages.occupation)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    onChange={setData(i)}
                    value={workExperiences[i].occupation}
                    helperText={intl.format(WorkExperienceMessages.occupationHelper)}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <TextField
                    name="annualSalary"
                    label={intl.format(WorkExperienceMessages.annualSalary)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    onChange={setData(i)}
                    value={workExperiences[i].annualSalary}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div>
                    <FormControl variant="outlined" style={{ marginTop: 12, width: '100%' }}>
                      <InputLabel htmlFor="hourPerWeek">
                        {intl.format(WorkExperienceMessages.workHourPerWeek)}
                      </InputLabel>
                      <Select
                        native
                        value={workExperiences[i].hourPerWeek}
                        onChange={setData(i)}
                        inputProps={{
                          name: 'hourPerWeek',
                        }}
                        style={{ paddingLeft: 4, paddingRight: 4 }}
                      >
                        <option value="" />
                        <option value="less than 15 H per week">
                          {intl.format(WorkExperienceMessages.less15hours)}
                        </option>
                        <option value="more than 15 H but less than 30 H per week">
                          {intl.format(WorkExperienceMessages.less30hours)}
                        </option>
                        <option value="30 H or more per week">
                          {intl.format(WorkExperienceMessages.above30hours)}
                        </option>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" style={{ marginTop: 12, width: '100%' }} fullWidth>
                    <InputLabel htmlFor="visa">
                      {intl.format(WorkExperienceMessages.visa)}
                    </InputLabel>
                    <Select
                      name="visa"
                      native
                      label={intl.format(WorkExperienceMessages.visa)}
                      value={workExperiences[i].visa}
                      onChange={setData(i)}
                      inputProps={{
                        name: 'visa',
                      }}
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <option aria-label="-" value="" />
                      <option value="Student">
                        {intl.format(WorkExperienceMessages.studyPermit)}
                      </option>
                      <option value="Work">
                        {intl.format(WorkExperienceMessages.workPermit)}
                      </option>
                      <option value="Permanent Residence">
                        {intl.format(WorkExperienceMessages.permanentResidence)}
                      </option>
                      <option value="Citizen">
                        {intl.format(WorkExperienceMessages.citizenship)}
                      </option>
                      <option value="No Permit">
                        {intl.format(WorkExperienceMessages.noPermit)}
                      </option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {
                !isStudy
                && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={(
                          <Switch
                            name="isManager"
                            checked={workExperiences[i].isManager || false}
                            onChange={setData(i)}
                            value={!workExperiences[i].isManager || false}
                          />
                        )}
                        label={intl.format(WorkExperienceMessages.managerOrSupervisor)}
                      />
                    </Grid>
                  </Grid>
                )
              }
              <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <IconButton aria-label="Delete" onClick={this.remove(i)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            </CardContent>
          </Card>
        </React.Fragment>,
      );
    }

    return (
      <div>
        <Title text={intl.format(WorkExperienceMessages.workExperience)} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Switch
                  checked={isNothing}
                  onChange={this.handleChangeSwitch}
                  value
                />
              )}
              label={intl.format(WorkExperienceMessages.dontHaveExperience)}
            />
          </Grid>
        </Grid>
        {!isNothing && list}
        {
          !isNothing
          && (
            <div>
              <Button color="primary" onClick={this.add}>
                {intl.format(WorkExperienceMessages.add)}
              </Button>
              <Button color="inherit" onClick={this.sortData}>
                {intl.format(WorkExperienceMessages.sort)}
              </Button>
            </div>
          )
        }
      </div>
    );
  }
}

export default WorkExperience;
