import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core';
import type { PersonalHistoryData } from 'common_parts';
import React from 'react';
import { connect } from 'react-redux';
import IntlMessage from '../../../intl';
import PersonalHistoryMessage from '../../../intl/messages/components/forms/parts/PersonalHistory';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';
import { NO_DATA } from '../../../utils/constants';

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
});

type Props = {
  data: PersonalHistoryData[];
  locale: LocaleState;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}));

const PersonalHistoryTable = (props: Props): JSX.Element => {
  const { data, locale } = props;
  const intl = new IntlMessage(locale.langCode);
  const classes = useStyles();
  const cellClass = `${classes.head} ${classes.body}`;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={cellClass} align="center">From</TableCell>
            <TableCell className={cellClass} align="center">To</TableCell>
            <TableCell className={cellClass} align="center">Activity</TableCell>
            <TableCell className={cellClass} align="center">City or town and country or territory</TableCell>
            <TableCell className={cellClass} align="center">Status in country or territory</TableCell>
            <TableCell className={cellClass} align="center">
              Name of company, employer, school, facility, as applicable
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Tooltip
              key={`${row.start || ''}${row.end || ''}${row.name || ''}`}
              title={
                row.activityLabel === NO_DATA
                  ? intl.format(PersonalHistoryMessage.nodataWarningLabel)
                  : ''
              }
              arrow
            >
              <TableRow style={row.activityLabel === NO_DATA ? { backgroundColor: 'pink' } : undefined}>
                <TableCell align="center">{row.start || ''}</TableCell>
                <TableCell align="center">{row.end || ''}</TableCell>
                <TableCell align="center">{row.activityLabel || ''}</TableCell>
                <TableCell align="center">
                  {`${row.city || ''}${row.city && row.country ? ', ' : ''}${row.country || ''}`}
                </TableCell>
                <TableCell align="center">{row.visa || ''}</TableCell>
                <TableCell align="center">{row.name || ''}</TableCell>
              </TableRow>
            </Tooltip>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default connect(mapStateToProps, {})(PersonalHistoryTable);
