import type {
  EducationData,
  PersonalHistoryData,
  ResidentHistoryData,
  WorkExperienceData,
} from 'common_parts';
import {
  NO_DATA,
  PRESENT,
  UNEMPLOYED,
} from '../../../utils/constants';
import {
  getFormattedDate,
  zeroPadding,
} from '../../../utils/format';

function createPersonalHistoryBase(
  educations: EducationData[] = [],
  works: WorkExperienceData[] = [],
): PersonalHistoryData[] {
  const result: PersonalHistoryData[] = [];

  educations.map((education) => {
    const {
      start,
      end,
      name,
      city,
      country,
      visa,
    } = education;

    if (!start && !end && !name && !city && !country && !visa) {
      return undefined; // for ESLint: array-callback-return
    }

    result.push({
      start: education.start,
      end: education.end,
      name: education.name,
      activityLabel: 'Studying',
      city: education.city,
      country: education.country,
      visa: education.visa,
    });

    return undefined; // for ESLint: array-callback-return
  });

  works.map((work) => result.push({
    start: work.start,
    end: work.end,
    name: work.name,
    activityLabel: work.occupation,
    city: work.city,
    country: work.country,
    visa: work.visa,
  }));

  return result;
}

function generatePersonalHistoryGaps(
  newerLimit: string,
  olderLimit: string,
  residents: ResidentHistoryData[],
): PersonalHistoryData[] {
  const blankStartDate = new Date(`${olderLimit} 12:00:00`);
  const blankEndDate = new Date(`${newerLimit} 12:00:00`);
  const dayAsMilliseconds = 1000 * 60 * 60 * 24;

  const foundResidents = residents.filter((resident) => {
    if (!resident.start || !resident.end || !newerLimit || !olderLimit) {
      return false;
    }

    const dataStart = new Date(`${resident.start} 12:00:00`);
    const dataEnd = (() => {
      if (resident.end === PRESENT) {
        return new Date();
      }
      return new Date(`${resident.end} 12:00:00`);
    })();

    const isExactlySame = resident.start === olderLimit && resident.end === newerLimit;
    const isAcrossStart = dataStart <= blankStartDate && dataEnd >= blankStartDate;
    const isAcrossEnd = dataStart <= blankEndDate && dataEnd >= blankEndDate;
    const isInside = dataStart >= blankStartDate && dataEnd <= blankEndDate;
    const isCovered = dataStart <= blankStartDate && dataEnd >= blankEndDate;

    return isExactlySame || isAcrossStart || isAcrossEnd || isInside || isCovered;
  });

  const result: PersonalHistoryData[] = [];
  let filledBorder: string = getFormattedDate(new Date(blankEndDate.getTime() + dayAsMilliseconds));
  foundResidents.map((resident) => {
    if (!resident.start || !resident.end) {
      return undefined; // for ESLint: array-callback-return
    }

    const residentEndDate = new Date(`${resident.end} 12:00:00`);
    const filledBorderDate = new Date(`${filledBorder} 12:00:00`);
    if (residentEndDate < new Date(filledBorderDate.getTime() - dayAsMilliseconds)) {
      result.push({
        activityLabel: NO_DATA,
        start: getFormattedDate(new Date(residentEndDate.getTime() + dayAsMilliseconds)),
        end: getFormattedDate(new Date(filledBorderDate.getTime() - dayAsMilliseconds)),
        name: '-',
        city: '-',
        visa: '-',
      });
    }

    result.push({
      start: new Date(resident.start) < blankStartDate ? olderLimit : resident.start,
      end: new Date(resident.end) > blankEndDate ? newerLimit : resident.end,
      name: '-',
      activityLabel: UNEMPLOYED,
      city: resident.city,
      country: resident.country,
      visa: resident.visa,
    });

    if (new Date(resident.start) <= blankStartDate) {
      // 居住区の開始日が埋めたい範囲を超える場合
      filledBorder = olderLimit;
    } else {
      filledBorder = resident.start;
    }

    return undefined; // for ESLint: array-callback-return
  });

  // 最後に余白が残ってしまっている場合はNO DATAで埋める
  if (olderLimit !== filledBorder) {
    const endDate = new Date(`${filledBorder} 12:00:00`);
    endDate.setTime(endDate.getTime() - dayAsMilliseconds);
    result.push({
      activityLabel: NO_DATA,
      start: olderLimit,
      end: getFormattedDate(endDate),
      name: '-',
      city: '-',
      visa: '-',
    });

    filledBorder = newerLimit;
  }

  return result;
}

function fillInHistoryGaps(
  historyRows: PersonalHistoryData[],
  residents: ResidentHistoryData[],
  newerLimitDate: string,
  olderLimitDate: string,
): PersonalHistoryData[] {
  const result: PersonalHistoryData[] = [];
  const dayAsMilliseconds = 1000 * 60 * 60 * 24;

  let previousStartDate = newerLimitDate;
  historyRows.forEach((row) => {
    if (!row.start || !row.end) {
      result.push(row);
      return;
    }

    // 集計下限より古いデータを切り捨て
    if (row.end !== PRESENT && new Date(row.end).getTime() <= new Date(olderLimitDate).getTime()) {
      return;
    }

    // 集計上限より新しいデータを切り捨て
    if (new Date(row.start).getTime() >= new Date(newerLimitDate).getTime()) {
      return;
    }

    // 現在進行中のデータ と 1つ前のデータとの間に空白期間がないデータ は配列に追加して次へ
    if (
      row.end === PRESENT
      || new Date(row.end).getTime() + dayAsMilliseconds >= new Date(previousStartDate).getTime()
    ) {
      if (new Date(row.start).getTime() <= new Date(previousStartDate).getTime()) {
        previousStartDate = row.start;
      }
      result.push(row);
      return;
    }

    // 空白期間の追加処理
    const startBorder = new Date(`${row.end} 12:00:00`);
    startBorder.setTime(startBorder.getTime() + dayAsMilliseconds);
    const formattedStartBorder = getFormattedDate(startBorder);
    const endBorder = new Date(`${previousStartDate} 12:00:00`);
    endBorder.setTime(
      endBorder.getTime() - (previousStartDate === newerLimitDate ? 0 : dayAsMilliseconds),
    );
    const formattedEndBorder = getFormattedDate(endBorder);

    const gaps = generatePersonalHistoryGaps(formattedEndBorder, formattedStartBorder, residents);
    gaps.map((gapRow) => {
      result.push({
        ...gapRow,
      });
      return undefined; // for ESLint: array-callback-return
    });

    result.push(row);
    previousStartDate = row.start;
  });

  // olderLimitDateまでに空白期間があれば埋める
  if (new Date(olderLimitDate).getTime() < new Date(previousStartDate).getTime()) {
    const startBorder = new Date(`${olderLimitDate} 12:00:00`);
    const formattedStartBorder = getFormattedDate(startBorder);
    const endBorder = new Date(`${previousStartDate} 12:00:00`);
    endBorder.setTime(endBorder.getTime() - dayAsMilliseconds);
    const formattedEndBorder = getFormattedDate(endBorder);

    const gaps = generatePersonalHistoryGaps(formattedEndBorder, formattedStartBorder, residents);
    gaps.map((gapRow) => {
      result.push({
        ...gapRow,
      });
      return undefined; // for ESLint: array-callback-return
    });
  }

  return result;
}

/**
 * YYYY-MM表記で出力する
 * @param date Date
 * @param additionalMonth number
 */
function getFormattedYearAndMonth(date: Date, additionalMonth: number): string {
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;
  const monthsInYear = 12;

  let year = currentYear;
  let month = currentMonth + additionalMonth;

  if (month > monthsInYear) {
    const remind = month % monthsInYear;
    year += remind;
    month -= (remind * monthsInYear);
  }

  if (month <= 0) {
    while (month <= 0) {
      year -= 1;
      month += monthsInYear;
    }
  }

  return `${year}-${zeroPadding(month, 2)}`;
}

export {
  createPersonalHistoryBase,
  generatePersonalHistoryGaps,
  fillInHistoryGaps,
  getFormattedYearAndMonth,
};
