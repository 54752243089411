import { FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Component } from 'react';
import { ChangeEventWithName } from '../../../abbreviation-types';
import IntlMessage from '../../../intl';
import { TravelHistoryMessages } from '../../../intl/messages';
import { LocaleState } from '../../../modules/locale';
import {
  addArray,
  positionType,
  removeArray,
  sortArray,
  TravelHistoryState,
} from '../../../modules/travelHistory';
import Caption from '../../application/Caption';
import Title from '../../application/Title';
import Duration from '../parts/Duration';
import Place from '../parts/Place';

interface Props {
  position: positionType;
  data: TravelHistoryState;
  locale: LocaleState;
  addArrayData: typeof addArray | ((position: positionType) => void);
  removeArrayData: typeof removeArray | ((position: positionType, index: number) => void);
  sortArrayData: typeof sortArray | ((position: positionType) => void);
  setData: (index: number) => (event: ChangeEventWithName) => void;
  setDuration: (index: number) => (name: 'start' | 'end') => (date: Date | null) => void;
}

class TravelHistory extends Component<Props> {
  public componentDidMount(): void {
    this.sortData();
  }

  private add = (): void => {
    const { addArrayData, position } = this.props;
    addArrayData(position);
  };

  private remove = (index: number): () => void => (): void => {
    const { removeArrayData, position } = this.props;
    removeArrayData(position, index);
  };

  private sortData = (): void => {
    const { sortArrayData, position } = this.props;
    sortArrayData(position);
  };

  public render(): JSX.Element {
    const {
      data,
      position,
      locale,
      setData,
      setDuration,
    } = this.props;
    const {
      travelHistory,
    } = position === 'applicant' ? data.applicant : data.spouse;
    const { length } = travelHistory;
    const list = [];
    const english = new IntlMessage('en');
    const intl = new IntlMessage(locale.langCode);

    for (let i = 0; i < length; i += 1) {
      list.push(
        <React.Fragment key={i}>
          <Card style={{ marginBottom: 12 }}>
            <CardContent>

              <Duration
                setData={setDuration(i)}
                start={travelHistory[i].start}
                end={travelHistory[i].end}
              />
              <Place
                label={intl.format(TravelHistoryMessages.place)}
                category=""
                setData={setData(i)}
                country={travelHistory[i].country}
                city={travelHistory[i].city}
              />
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <FormControl variant="outlined" fullWidth style={{ marginBottom: 12 }}>
                    <InputLabel htmlFor="purpose">
                      {intl.format(TravelHistoryMessages.purpose)}
                    </InputLabel>
                    <Select
                      native
                      value={travelHistory[i].purpose}
                      onChange={setData(i)}
                      inputProps={{
                        name: 'purpose',
                      }}
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <option value="" />
                      <option value={english.format(TravelHistoryMessages.sightseeing)}>
                        {intl.format(TravelHistoryMessages.sightseeing)}
                      </option>
                      <option value={english.format(TravelHistoryMessages.study)}>
                        {intl.format(TravelHistoryMessages.study)}
                      </option>
                      <option value={english.format(TravelHistoryMessages.work)}>
                        {intl.format(TravelHistoryMessages.work)}
                      </option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                >
                  <IconButton aria-label="Delete" onClick={this.remove(i)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </React.Fragment>,
      );
    }

    return (
      <div>
        <Title text={intl.format(TravelHistoryMessages.travelHistory)} />
        <Caption text={intl.format(TravelHistoryMessages.fillInAll)} />
        {list}
        <div>
          <Button color="primary" onClick={this.add}>
            {intl.format(TravelHistoryMessages.add)}
          </Button>
          <Button color="inherit" onClick={this.sortData}>
            {intl.format(TravelHistoryMessages.sort)}
          </Button>
        </div>
      </div>
    );
  }
}

export default TravelHistory;
