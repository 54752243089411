import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import UserApi from '../../apis/user';
import { AppState } from '../../modules';
import { setIsLoggedIn } from '../../modules/auth';

const mapStateToProps = (state: AppState): { isLoggedIn: boolean } => ({
  isLoggedIn: state.auth.isLoggedIn,
});

interface Props {
  children: any;
  isLoggedIn?: boolean;
  setIsLoggedInRedux: typeof setIsLoggedIn;
}

const tokenExists = (): boolean => !!localStorage.getItem('token');

const checkIsLoggedIn = async (): Promise<boolean> => {
  if (tokenExists()) {
    try {
      const { data } = await UserApi.me();
      return data;
    } catch (error) {
      localStorage.removeItem('token');
    }
  }
  return false;
};

const Auth = (props: Props): JSX.Element => {
  const { children, isLoggedIn, setIsLoggedInRedux } = props;
  const [isLoading, setLoading] = useState<boolean>(true);

  const { pathname } = window.location;
  const redirectURL = pathname ? `?redirect=${pathname}` : '';

  useEffect(() => {
    const checkAuth = async (): Promise<void> => {
      if (!isLoggedIn) {
        const result = await checkIsLoggedIn();
        setIsLoggedInRedux(result);
      }
      setLoading(false);
    };

    checkAuth();
  }, [isLoggedIn, setIsLoggedInRedux]);

  return (
    <>
      {
        !isLoading && (
          isLoggedIn ? children : <Redirect to={`/login${redirectURL}`} />
        )
      }
    </>
  );
};

export default connect(mapStateToProps, { setIsLoggedInRedux: setIsLoggedIn })(Auth);
