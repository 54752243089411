import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const PreviousSpouseMessages = defineMessages({
  english: {
    id: 'person.english',
    defaultMessage: Japanese['person.english'],
  },
  motherLanguage: {
    id: 'person.motherLanguage',
    defaultMessage: Japanese['person.motherLanguage'],
  },
  dateOfBirth: {
    id: 'person.dateOfBirth',
    defaultMessage: Japanese['person.dateOfBirth'],
  },
  marriageDate: {
    id: 'person.marriageDate',
    defaultMessage: Japanese['person.marriageDate'],
  },
  divorceDate: {
    id: 'person.divorceDate',
    defaultMessage: Japanese['person.divorceDate'],
  },
  previousSpouse: {
    id: 'previousSpouse.previousSpouse',
    defaultMessage: Japanese['previousSpouse.previousSpouse'],
  },
  caption: {
    id: 'previousSpouse.caption',
    defaultMessage: Japanese['previousSpouse.caption'],
  },
  add: {
    id: 'previousSpouse.add',
    defaultMessage: Japanese['previousSpouse.add'],
  },
  remove: {
    id: 'previousSpouse.remove',
    defaultMessage: Japanese['previousSpouse.remove'],
  },
});

export default PreviousSpouseMessages;
