import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const SpouseMessages = defineMessages({
  parentsLabel: {
    id: 'spouse.parentsLabel',
    defaultMessage: Japanese['spouse.parentsLabel'],
  },
  fatherRelationshipLabel: {
    id: 'spouse.fatherRelationshipLabel',
    defaultMessage: Japanese['spouse.fatherRelationshipLabel'],
  },
  motherRelationshipLabel: {
    id: 'spouse.motherRelationshipLabel',
    defaultMessage: Japanese['spouse.motherRelationshipLabel'],
  },
  applicant: {
    id: 'spouse.applicant',
    defaultMessage: Japanese['spouse.applicant'],
  },
  spouse: {
    id: 'spouse.spouse',
    defaultMessage: Japanese['spouse.spouse'],
  },
  children: {
    id: 'spouse.children',
    defaultMessage: Japanese['spouse.children'],
  },
  spouseSection: {
    id: 'spouse.spouseSection',
    defaultMessage: Japanese['spouse.spouseSection'],
  },
  spouseCompleted: {
    id: 'spouse.spouseCompleted',
    defaultMessage: Japanese['spouse.spouseCompleted'],
  },
  goNext: {
    id: 'spouse.goNext',
    defaultMessage: Japanese['spouse.goNext'],
  },
  back: {
    id: 'spouse.back',
    defaultMessage: Japanese['spouse.back'],
  },
  next: {
    id: 'spouse.next',
    defaultMessage: Japanese['spouse.next'],
  },
});

export default SpouseMessages;
