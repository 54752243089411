function calcAge(birthDate: Date): number {
  if (Number.isNaN(birthDate.getTime())) return 0;

  const now = new Date();
  const nowYear = now.getFullYear().toString().padStart(4, '0');
  const nowMonth = (now.getMonth() + 1).toString().padStart(2, '0');
  const nowDay = now.getDate().toString().padStart(2, '0');

  const birthYear = birthDate.getFullYear().toString().padStart(4, '0');
  const birthMonth = (birthDate.getMonth() + 1).toString().padStart(2, '0');
  const birthDay = birthDate.getDate().toString().padStart(2, '0');

  return Math.floor(
    (Number(nowYear + nowMonth + nowDay) - Number(birthYear + birthMonth + birthDay)) / 10000,
  );
}

export { calcAge as default };
