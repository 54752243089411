import {
  FormControlLabel,
  Switch,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import IntlMessage from '../../../intl';
import {
  GeneralMessages,
  ParentsMessages,
} from '../../../intl/messages';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';

const mapStateToProps = (state: AppState): {
  locale: LocaleState;
} => ({
  locale: state.locale,
});

interface Props {
  locale: LocaleState;
  deathDate?: string;
  currentAddress?: string;
  handleDeathSwitch: (event: ChangeEvent<HTMLInputElement>) => void;
  setValue: (stepName: React.ChangeEvent<{ name: string; value: unknown }>) => void;
  setDate: (date: Date | null) => void;
  required?: boolean;
}

const CurrentAddress = (props: Props) => {
  const {
    locale,
    deathDate,
    currentAddress = '',
    handleDeathSwitch,
    setValue,
    setDate,
  } = props;
  const intl = new IntlMessage(locale.langCode);

  // "deathDate" は死別しているかどうかのスイッチに関する値で、
  //   - undefined => スイッチがOFF
  //   - 空文字 => スイッチはONだが日付が未入力（入力途中でも送信を許容してほしいというプロジェクト当初の意向）
  //   - 文字列 => スイッチがONかつ日付が入力されている
  // という状態分岐を扱っている。
  const death = deathDate !== undefined;

  return (
    <>
      <FormControlLabel
        control={(
          <Switch
            checked={death}
            onChange={handleDeathSwitch}
            name="death"
          />
        )}
        label={intl.format(ParentsMessages.bereaved)}
      />
      {
        death ? (
          <>
            <KeyboardDatePicker
              name="deathDate"
              format="yyyy-MM-dd"
              variant="inline"
              inputVariant="outlined"
              margin="normal"
              label={intl.format(ParentsMessages.dateOfDeath)}
              value={deathDate ? `${deathDate}T12:00:00Z` : null}
              onChange={setDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <TextField
              name="currentAddress"
              label={intl.format(ParentsMessages.bereavementAddress)}
              margin="normal"
              variant="outlined"
              fullWidth
              onChange={setValue}
              value={currentAddress}
            />
          </>
        ) : (
          <TextField
            name="currentAddress"
            label={intl.format(GeneralMessages.currentAddress)}
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={setValue}
            value={currentAddress}
          />
        )
      }
    </>

  );
};

export default connect(mapStateToProps, {})(CurrentAddress);
