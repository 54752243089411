/* eslint-disable import/no-extraneous-dependencies */
import {
  ApplicantFormData,
  ResponseData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import Request from './request';

export default class RegisterFormApi {
  public static async create(data: ApplicantFormData): Promise<ResponseData<ApplicantFormData>> {
    return Request.post('applicant', data);
  }

  public static async update(
    id: string,
    data: ApplicantFormData,
  ): Promise<ResponseData<ApplicantFormData>> {
    return Request.post(`applicant/${id}`, data);
  }
}
