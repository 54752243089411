import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import React from 'react';
import { connect } from 'react-redux';
import { ChangeEventWithName } from '../../../abbreviation-types';
import IntlMessage from '../../../intl';
import { NameMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';
import ErrorMessage from '../../application/ErrorMessage';

const mapStateToProps = (state: AppState): {
  locale: LocaleState;
} => ({
  locale: state.locale,
});

export interface Props {
  label: string;
  language: string;
  setData?: (stepName: ChangeEventWithName) => void;
  lastName: string;
  firstName: string;
  middleName?: string;
  originalFamilyName?: string | null;
  locale: LocaleState;
  error?: boolean;
}

const Name = (props: Props): JSX.Element => {
  const {
    label,
    language,
    setData,
    lastName,
    firstName,
    middleName,
    originalFamilyName,
    locale,
    error,
  } = props;
  const intl = new IntlMessage(locale.langCode);
  const formLabel = `${intl.format(NameMessages.name)} (${label})`;
  const isRequired = props && (typeof error !== 'undefined');

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormLabel style={{ fontSize: '0.8em' }}>
            {formLabel}
          </FormLabel>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '8px' }}>
        <Grid item xs={12} sm={4}>
          <TextField
            required={isRequired}
            name={`firstName${language}`}
            label={intl.format(NameMessages.firstName)}
            variant="outlined"
            fullWidth
            onChange={setData}
            value={firstName}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required={isRequired}
            name={`lastName${language}`}
            label={intl.format(NameMessages.lastName)}
            variant="outlined"
            fullWidth
            onChange={setData}
            value={lastName}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={`middleName${language}`}
            label={intl.format(NameMessages.middleName)}
            variant="outlined"
            fullWidth
            onChange={setData}
            value={middleName}
          />
        </Grid>
        {
          originalFamilyName !== null
          && (
            <Grid item xs={12} sm={4}>
              <TextField
                name={`originalFamilyName${language}`}
                label={intl.format(NameMessages.originalFamilyName)}
                variant="outlined"
                fullWidth
                onChange={setData}
                value={originalFamilyName}
              />
            </Grid>
          )
        }
      </Grid>
      {
        error && (!lastName || !firstName)
        && (
          <Grid container style={{ marginTop: -8, marginBottom: 8 }}>
            <Grid item xs={12}>
              <ErrorMessage text={`${formLabel} ${intl.format(NameMessages.errorName)}`} />
            </Grid>
          </Grid>
        )
      }
    </>
  );
};

export default connect(mapStateToProps, {})(Name);
