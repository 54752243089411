import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const EditableLabelMessages = defineMessages({
  edit: {
    id: 'editableLabel.edit',
    defaultMessage: Japanese['editableLabel.edit'],
  },
  confirm: {
    id: 'editableLabel.confirm',
    defaultMessage: Japanese['editableLabel.confirm'],
  },
});

export default EditableLabelMessages;
