import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const ChildrenMessages = defineMessages({
  child: {
    id: 'children.child',
    defaultMessage: Japanese['children.child'],
  },
  english: {
    id: 'children.english',
    defaultMessage: Japanese['children.english'],
  },
  motherLanguage: {
    id: 'children.motherLanguage',
    defaultMessage: Japanese['children.motherLanguage'],
  },
  dateOfBirth: {
    id: 'children.dateOfBirth',
    defaultMessage: Japanese['children.dateOfBirth'],
  },
  placeOfBirth: {
    id: 'children.placeOfBirth',
    defaultMessage: Japanese['children.placeOfBirth'],
  },
  applicant: {
    id: 'children.applicant',
    defaultMessage: Japanese['children.applicant'],
  },
  spouse: {
    id: 'children.spouse',
    defaultMessage: Japanese['children.spouse'],
  },
  children: {
    id: 'children.children',
    defaultMessage: Japanese['children.children'],
  },
  childInformation: {
    id: 'children.childInformation',
    defaultMessage: Japanese['children.childInformation'],
  },
  add: {
    id: 'children.add',
    defaultMessage: Japanese['children.add'],
  },
  remove: {
    id: 'children.remove',
    defaultMessage: Japanese['children.remove'],
  },
  back: {
    id: 'children.back',
    defaultMessage: Japanese['children.back'],
  },
  next: {
    id: 'children.next',
    defaultMessage: Japanese['children.next'],
  },
});

export default ChildrenMessages;
