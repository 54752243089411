import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const TravelHistoryMessages = defineMessages({
  place: {
    id: 'travelHistory.place',
    defaultMessage: Japanese['travelHistory.place'],
  },
  purpose: {
    id: 'travelHistory.purpose',
    defaultMessage: Japanese['travelHistory.purpose'],
  },
  travelHistory: {
    id: 'travelHistory.travelHistory',
    defaultMessage: Japanese['travelHistory.travelHistory'],
  },
  fillInAll: {
    id: 'travelHistory.fillInAll',
    defaultMessage: Japanese['travelHistory.fillInAll'],
  },
  add: {
    id: 'travelHistory.add',
    defaultMessage: Japanese['travelHistory.add'],
  },
  remove: {
    id: 'travelHistory.remove',
    defaultMessage: Japanese['travelHistory.remove'],
  },
  sort: {
    id: 'travelHistory.sort',
    defaultMessage: Japanese['travelHistory.sort'],
  },
  sightseeing: {
    id: 'travelHistory.sightseeing',
    defaultMessage: Japanese['travelHistory.sightseeing'],
  },
  study: {
    id: 'travelHistory.study',
    defaultMessage: Japanese['travelHistory.study'],
  },
  work: {
    id: 'travelHistory.work',
    defaultMessage: Japanese['travelHistory.work'],
  },
});

export default TravelHistoryMessages;
