import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';
/* eslint-disable import/no-extraneous-dependencies */
import { WorkExperienceData } from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React from 'react';

interface Props {
  data?: WorkExperienceData[] | 'none';
}

const useStyles = makeStyles((): StyleRules => createStyles({
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme: Theme): StyleRules => createStyles({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const WorkExperienceDataSummary = (props: Props): JSX.Element => {
  const { data } = props;
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <StyledTableCell align="center">Company Name</StyledTableCell>
          <StyledTableCell align="center">Occupation</StyledTableCell>
          <StyledTableCell align="center">Hours per Week</StyledTableCell>
          <StyledTableCell align="center">Located At</StyledTableCell>
          <StyledTableCell align="center">Annual Salary</StyledTableCell>
          <StyledTableCell align="center">Start</StyledTableCell>
          <StyledTableCell align="center">End</StyledTableCell>
          <StyledTableCell align="center">Visa</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          (
            data
            && data === 'none'
            && (
              <TableRow>
                None
              </TableRow>
            )
          ) || (
            data
            && Array.isArray(data)
            && data.map((workHistory): JSX.Element => (
              <TableRow key={`${workHistory.name}${workHistory.name}`}>
                <StyledTableCell align="center">{workHistory.name}</StyledTableCell>
                <StyledTableCell align="center">{workHistory.occupation}</StyledTableCell>
                <StyledTableCell align="center">{workHistory.hourPerWeek}</StyledTableCell>
                <StyledTableCell align="center">{workHistory.address}</StyledTableCell>
                <StyledTableCell align="center">{workHistory.annualSalary}</StyledTableCell>
                <StyledTableCell align="center">{workHistory.start}</StyledTableCell>
                <StyledTableCell align="center">{workHistory.end}</StyledTableCell>
                <StyledTableCell align="center">{workHistory.visa}</StyledTableCell>
              </TableRow>
            ))
          )
        }
      </TableBody>
    </Table>
  );
};

export default WorkExperienceDataSummary;
