import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const SiblingsMessages = defineMessages({
  siblings: {
    id: 'siblings.siblings',
    defaultMessage: Japanese['siblings.siblings'],
  },
  siblingsInformation: {
    id: 'siblings.siblingsInformation',
    defaultMessage: Japanese['siblings.siblingsInformation'],
  },
  dontHaveSiblings: {
    id: 'siblings.dontHaveSiblings',
    defaultMessage: Japanese['siblings.dontHaveSiblings'],
  },
  add: {
    id: 'siblings.add',
    defaultMessage: Japanese['siblings.add'],
  },
  remove: {
    id: 'siblings.remove',
    defaultMessage: Japanese['siblings.remove'],
  },
});

export default SiblingsMessages;
