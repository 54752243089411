import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField/TextField';
/* eslint-disable import/no-extraneous-dependencies */
import {
  LanguageData,
  LanguageScoreData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import IntlMessage from '../../../intl';
import { LanguageScoreMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';

type TargetElements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
});

interface Props {
  setData: (language: keyof LanguageData) => (stepName: ChangeEvent<TargetElements>) => void;
  languageKey: keyof LanguageData;
  data: LanguageScoreData;
  locale: LocaleState;
}

const LanguageScore = (props: Props) => {
  const {
    setData, languageKey, data, locale,
  } = props;
  const intl = new IntlMessage(locale.langCode);

  return (
    <React.Fragment>
      <Grid container spacing={1} style={{ marginBottom: 0 }}>
        <Grid item xs={6} sm={3}>
          <TextField
            id={`listeningScore-${languageKey}`}
            label={intl.format(LanguageScoreMessages.listening)}
            variant="outlined"
            fullWidth
            type="number"
            onChange={setData(languageKey)}
            value={data && data.listeningScore}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            id={`readingScore-${languageKey}`}
            label={intl.format(LanguageScoreMessages.reading)}
            variant="outlined"
            fullWidth
            type="number"
            onChange={setData(languageKey)}
            value={data && data.readingScore}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            id={`writingScore-${languageKey}`}
            label={intl.format(LanguageScoreMessages.writing)}
            variant="outlined"
            fullWidth
            type="number"
            onChange={setData(languageKey)}
            value={data && data.writingScore}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            id={`speakingScore-${languageKey}`}
            label={intl.format(LanguageScoreMessages.speaking)}
            variant="outlined"
            fullWidth
            type="number"
            onChange={setData(languageKey)}
            value={data && data.speakingScore}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, {})(LanguageScore);
