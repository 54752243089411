import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import type { SiblingData } from 'common_parts';
import React from 'react';

type OwnProps = {
  data: SiblingData[];
  token?: string;
};

type ParentProps = {
  data: SiblingData;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}));

const SiblingRow = (props: ParentProps): JSX.Element => {
  const { data } = props;
  const {
    firstName,
    firstNameInMotherLanguage,
    lastName,
    lastNameInMotherLanguage,
    middleName,
    middleNameInMotherLanguage,
    email,
    birthCountry,
    birthCity,
    currentAddress,
    birthDate,
    deathDate,
    marriageCondition,
    relationship,
  } = data;

  return (
    <TableRow>
      <TableCell align="center">{relationship}</TableCell>
      <TableCell align="center">
        <ruby>
          {firstNameInMotherLanguage}
          <rt>{firstName}</rt>
          {
            middleName && middleNameInMotherLanguage
            && (
              <>
                (
                {middleNameInMotherLanguage}
                <rt>{middleName}</rt>
                )
              </>
            )
          }
          {lastNameInMotherLanguage}
          <rt>{lastName}</rt>
        </ruby>
      </TableCell>
      <TableCell align="center">{birthDate}</TableCell>
      <TableCell align="center">
        {birthCity ? `${birthCity},` : ''}
        {birthCountry}
      </TableCell>
      <TableCell align="center">{email}</TableCell>
      <TableCell align="center">{currentAddress}</TableCell>
      <TableCell align="center">{marriageCondition}</TableCell>
      <TableCell align="center">{deathDate}</TableCell>
    </TableRow>
  );
};

const SiblingsInformationTable = (props: OwnProps): JSX.Element => {
  const { data } = props;
  const classes = useStyles();
  const cellClass = `${classes.head} ${classes.body}`;

  if (data.length === 1 && !data[0].relationship) {
    return (
      <p>No sibling</p>
    )
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={cellClass} align="center">Relation</TableCell>
            <TableCell className={cellClass} align="center">Name</TableCell>
            <TableCell className={cellClass} align="center">Date of Birth</TableCell>
            <TableCell className={cellClass} align="center">Place of Birth</TableCell>
            <TableCell className={cellClass} align="center">Email</TableCell>
            <TableCell className={cellClass} align="center">Present Address</TableCell>
            <TableCell className={cellClass} align="center">Marriage Condition</TableCell>
            <TableCell className={cellClass} align="center">Date of Death, if applicable</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((sibling, index) => (
            <SiblingRow key={index} data={sibling} />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SiblingsInformationTable;
