/* eslint-disable import/no-extraneous-dependencies */
import {
  ApplicantFormData,
  ApplicantRangedList,
  ResponseData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import Request from './request';

export default class ApplicantApi {
  public static async getRange(
    offset: number,
    limit: number,
  ): Promise<ResponseData<ApplicantRangedList>> {
    return Request.get('applicant', {
      offset,
      limit,
    });
  }

  public static async getAll(): Promise<ResponseData<ApplicantRangedList>> {
    return Request.get('applicant');
  }

  public static async get(id: string): Promise<ResponseData<ApplicantFormData>> {
    return Request.get(`applicant/${id}`);
  }

  public static async delete(id: string): Promise<ResponseData<ApplicantFormData>> {
    return Request.delete(`applicant/${id}`);
  }
}
