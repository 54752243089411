import { ParentData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies

const SET_FATHER = 'SET_FATHER';
const SET_MOTHER = 'SET_MOTHER';
export type positionType = 'applicant' | 'spouse';

export interface ParentState {
  applicant: {
    father: ParentData;
    mother: ParentData;
  };
  spouse: {
    father: ParentData;
    mother: ParentData;
  };
}

interface SetFatherAction {
  type: typeof SET_FATHER;
  position: positionType;
  label: keyof ParentData;
  payload: any;
}

interface SetMotherAction {
  type: typeof SET_MOTHER;
  position: positionType;
  label: keyof ParentData;
  payload: any;
}

type ParentActionTypes = SetFatherAction | SetMotherAction;

const initialState: ParentState = {
  applicant: {
    father: {
      lastName: '',
      firstName: '',
      middleName: '',
      lastNameInMotherLanguage: '',
      firstNameInMotherLanguage: '',
      middleNameInMotherLanguage: '',
      birthDate: '',
      birthCountry: '',
      birthCity: '',
      marriageCondition: '',
      currentAddress: '',
      email: '',
      occupation: '',
    },
    mother: {
      lastName: '',
      firstName: '',
      middleName: '',
      lastNameInMotherLanguage: '',
      firstNameInMotherLanguage: '',
      middleNameInMotherLanguage: '',
      birthDate: '',
      birthCountry: '',
      birthCity: '',
      marriageCondition: '',
      currentAddress: '',
      email: '',
      occupation: '',
    },
  },
  spouse: {
    father: {
      lastName: '',
      firstName: '',
      middleName: '',
      lastNameInMotherLanguage: '',
      firstNameInMotherLanguage: '',
      middleNameInMotherLanguage: '',
      birthDate: '',
      birthCountry: '',
      birthCity: '',
      marriageCondition: '',
      currentAddress: '',
      email: '',
      occupation: '',
    },
    mother: {
      lastName: '',
      firstName: '',
      middleName: '',
      lastNameInMotherLanguage: '',
      firstNameInMotherLanguage: '',
      middleNameInMotherLanguage: '',
      birthDate: '',
      birthCountry: '',
      birthCity: '',
      marriageCondition: '',
      currentAddress: '',
      email: '',
      occupation: '',
    },
  },
};

export function ParentReducer(
  state = initialState,
  action: ParentActionTypes,
): ParentState {
  switch (action.type) {
    case SET_FATHER:
      if (action.position === 'applicant') {
        return {
          applicant: {
            father: {
              ...state.applicant.father,
              [action.label]: action.payload,
            },
            mother: state.applicant.mother,
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          father: {
            ...state.spouse.father,
            [action.label]: action.payload,
          },
          mother: state.spouse.mother,
        },
      };
    case SET_MOTHER:
      if (action.position === 'applicant') {
        return {
          applicant: {
            mother: {
              ...state.applicant.mother,
              [action.label]: action.payload,
            },
            father: state.applicant.father,
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          mother: {
            ...state.spouse.mother,
            [action.label]: action.payload,
          },
          father: state.spouse.father,
        },
      };
    default:
      return state;
  }
}

export function setFather(
  position: positionType,
  label: keyof ParentData,
  value: any,
): ParentActionTypes {
  return {
    type: SET_FATHER,
    position,
    label,
    payload: value,
  };
}

export function setMother(
  position: positionType,
  label: keyof ParentData,
  value: any,
): ParentActionTypes {
  return {
    type: SET_MOTHER,
    position,
    label,
    payload: value,
  };
}
