import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/styles/withStyles';
/* eslint-disable import/no-extraneous-dependencies */
import { WorkExperienceData } from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import {
  format,
  isValid,
} from 'date-fns';
import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { ChangeEventWithName } from '../../abbreviation-types';
import EditApi from '../../apis/edit';
import PublicEditContext from '../../contexts/publicEdit';
import { AppState } from '../../modules';
import { positionType } from '../../modules/general';
import { LocaleState } from '../../modules/locale';
import { PRESENT } from '../../utils/constants';
import { sortHistorical } from '../../utils/sort';
import EditableCardHeader from '../console/EditableCardHeader';
import WorkExperienceSummary from '../console/WorkExperience/WorkExperienceSummary';
import * as WorkExperienceForm from '../forms/register/WorkExperience';

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
});

interface Props {
  data?: WorkExperienceData[] | 'none';
  position: positionType;
  token: string;
  locale: LocaleState;
}

const useStyles = makeStyles((theme: Theme): StyleRules => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
}));

const WorkExperience = (props: Props): JSX.Element => {
  const {
    data,
    position,
    token = '',
    locale,
  } = props;
  const classes = useStyles();
  const { allData, updateData } = React.useContext(PublicEditContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [workExperiences, setWorkExperiences] = useState<WorkExperienceData[]>([]);
  const [isNothing, setIsNothing] = useState<boolean>(false);
  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  const initWorkExperience = {
    start: '',
    end: '',
    name: '',
    address: '',
    city: '',
    province: '',
    country: '',
    hourPerWeek: '',
    occupation: '',
    annualSalary: 0,
    isManager: false,
    visa: '',
  };

  useEffect((): void => {
    if (data && data !== 'none') setWorkExperiences(data);
  }, [data, isOpen]);

  const handleEdit = (): void => {
    setIsOpen(true);
  };

  const handleCancel = (): void => {
    if (data && data !== 'none') setWorkExperiences(data);

    setIsOpen(false);
  };

  const handleSave = async (): Promise<void> => {
    // Send data to DB
    const newWorkExperiences = isNothing ? [initWorkExperience] : workExperiences;

    if (position === 'applicant') {
      const payroll = {
        ...allData,
        workExperiences: newWorkExperiences,
      };
      updateData(payroll);
      if (token) {
        try {
          setLoadingStatus(true);
          await EditApi.update(token, payroll);
        } catch (e) {
          // TODO: Error Handling
          // if reached this area, the result is probably 404 status
          alert('Some error has occurred: WorkExperienceUpdate applicant');
        } finally {
          setLoadingStatus(false);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('token is null | undefined !!');
      }
    } else if (allData && allData.spouse) {
      const payroll = {
        ...allData,
        spouse: {
          ...allData.spouse,
          workExperiences: newWorkExperiences,
        },
      };
      updateData(payroll);
      if (token) {
        try {
          setLoadingStatus(true);
          await EditApi.update(token, payroll);
        } catch (e) {
          // TODO: Error Handling
          // if reached this area, the result is probably 404 status
          alert('Some error has occurred: WorkExperienceUpdate spouse');
        } finally {
          setLoadingStatus(false);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('token is null | undefined !!');
      }
    }

    setIsOpen(false);
  };

  // eslint-disable-next-line max-len
  const handleChange = (index: number): (event: ChangeEventWithName) => void => (event: ChangeEventWithName): void => {
    const { value, name } = event.target;
    const newWorkExperiences = workExperiences.slice();

    if (!name) return;

    if (Object.prototype.hasOwnProperty.call(event.target, 'checked')) {
      newWorkExperiences[index] = {
        ...newWorkExperiences[index],
        [name]: value === 'true',
      };
    } else {
      newWorkExperiences[index] = {
        ...newWorkExperiences[index],
        [name]: value,
      };
    }

    setWorkExperiences(newWorkExperiences);
  };

  const setDuration = (index: number) => (name: 'start' | 'end') => (date: Date | typeof PRESENT | null) => {
    const newWorkExperiences = workExperiences.slice();

    if (!date) {
      newWorkExperiences[index] = {
        ...newWorkExperiences[index],
        [name]: null,
      };

      setWorkExperiences(newWorkExperiences);
    } else if (date === PRESENT) {
      newWorkExperiences[index] = {
        ...newWorkExperiences[index],
        [name]: date,
      };

      setWorkExperiences(newWorkExperiences);
    } else if (isValid(date)) {
      newWorkExperiences[index] = {
        ...newWorkExperiences[index],
        [name]: date ? format(date, 'yyyy-MM-dd') : null,
      };

      setWorkExperiences(newWorkExperiences);
    }
  };

  // eslint-disable-next-line no-shadow, @typescript-eslint/no-unused-vars
  const setIsNothingData = (position: positionType, value: any) => {
    setIsNothing(value);
  };

  // eslint-disable-next-line no-shadow, @typescript-eslint/no-unused-vars
  const addArrayData = (position: positionType) => {
    const newArray = workExperiences.slice();
    newArray.push(initWorkExperience);

    setWorkExperiences(newArray);
  };

  // eslint-disable-next-line no-shadow, @typescript-eslint/no-unused-vars
  const removeArrayData = (position: positionType, index: number) => {
    const newArray = workExperiences.slice();
    newArray.splice(index, 1);

    setWorkExperiences(newArray);
  };

  // eslint-disable-next-line no-shadow, @typescript-eslint/no-unused-vars
  const sortArrayData = (position: positionType) => {
    const newArray = workExperiences.slice();
    const sortedArray = sortHistorical(newArray);

    setWorkExperiences(sortedArray);
  };

  return (
    <>
      <EditableCardHeader title="Work Experience" edit={handleEdit} />
      <Paper className={classes.root}>
        <WorkExperienceSummary data={data} />
      </Paper>
      {
        isOpen
        && (
          <Dialog open={isOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Education</DialogTitle>
            <DialogContent>
              {
                data
                && (
                  <WorkExperienceForm.default
                    position={position}
                    data={{
                      applicant: {
                        isNothing,
                        workExperiences,
                      },
                      spouse: {
                        isNothing,
                        workExperiences,
                      },
                    }}
                    locale={locale}
                    setIsNothingData={setIsNothingData}
                    addArrayData={addArrayData}
                    removeArrayData={removeArrayData}
                    sortArrayData={sortArrayData}
                    setData={handleChange}
                    setDuration={setDuration}
                  />
                )
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} disabled={isLoading}>
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary" disabled={isLoading}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
};

export default connect(mapStateToProps, {})(WorkExperience);
