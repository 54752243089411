import { FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
/* eslint-disable import/no-extraneous-dependencies */
import {
  ParentData,
  SiblingData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import IntlMessage from '../../../intl';
import { PersonMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';
import Caption from '../../application/Caption';
import SubTitle from '../../application/SubTitle';
import Name from './Name';
import Place from './Place';

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
});

export interface Props {
  relationship: string;
  setData: (arg1: ChangeEvent<{ name?: string; value?: unknown }>) => void;
  data: ParentData | SiblingData;
  isLabel?: boolean;
  locale: LocaleState;
}

const Person = (props: Props): JSX.Element => {
  const {
    relationship,
    setData,
    data,
    isLabel,
    locale,
  } = props;
  const {
    firstName,
    lastName,
    middleName,
    firstNameInMotherLanguage,
    lastNameInMotherLanguage,
    middleNameInMotherLanguage,
    birthDate,
    birthCountry,
    birthCity,
    marriageCondition,
    deathDate,
    currentAddress,
    email,
  } = data;
  const occupation = (data as ParentData).occupation || '';
  const relationshipSiblings = (data as SiblingData).relationship || '';
  const intl = new IntlMessage(locale.langCode);
  const englishIntl = new IntlMessage('en');
  const deathNotice1 = intl.format(PersonMessages.deathNotice1);
  const deathNotice2 = intl.format(PersonMessages.deathNotice2);
  const deathNotice = `${deathNotice1}${relationship}${deathNotice2}`; // i.e) `If your ${relationship} passed away, please write down date of death. Otherwise, fill in the contact information.`

  let relationshipInput = null;
  if (isLabel) {
    relationshipInput = (
      <div>
        <TextField
          id="relationship"
          label={intl.format(PersonMessages.relationship)}
          variant="outlined"
          margin="normal"
          onChange={setData}
          value={relationshipSiblings}
        />
      </div>
    );
  }

  return (
    <>
      <SubTitle text={relationship} />
      {relationshipInput}
      <Name
        label={intl.format(PersonMessages.english)}
        language=""
        setData={setData}
        firstName={firstName}
        lastName={lastName}
        middleName={middleName}
        originalFamilyName={null}
      />
      <Name
        label={intl.format(PersonMessages.motherLanguage)}
        language="InMotherLanguage"
        setData={setData}
        firstName={firstNameInMotherLanguage}
        lastName={lastNameInMotherLanguage}
        middleName={middleNameInMotherLanguage}
        originalFamilyName={null}
      />
      <Place
        label={intl.format(PersonMessages.placeOfBirth)}
        category="birth"
        setData={setData}
        country={birthCountry}
        city={birthCity}
      />
      <div>

        <TextField
          id="birthDate"
          label={intl.format(PersonMessages.dateOfBirth)}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          margin="normal"
          onChange={setData}
          value={birthDate}
        />
      </div>
      <div>
        <TextField
          id="occupation"
          label={intl.format(PersonMessages.occupation)}
          variant="outlined"
          margin="normal"
          onChange={setData}
          value={occupation}
          helperText={intl.format(PersonMessages.occupationHelper)}
        />
      </div>
      <div>
        <FormControl variant="outlined">
          <InputLabel htmlFor="marriageCondition">
            {intl.format(PersonMessages.marriageCondition)}
          </InputLabel>
          <Select
            native
            value={marriageCondition}
            onChange={setData}
            inputProps={{
              id: 'marriageCondition',
            }}
          >
            <option value="">---</option>
            <option value={englishIntl.format(PersonMessages.neverMarried)}>
              {intl.format(PersonMessages.neverMarried)}
            </option>
            <option value={englishIntl.format(PersonMessages.married)}>
              {intl.format(PersonMessages.married)}
            </option>
            <option value={englishIntl.format(PersonMessages.divorced)}>
              {intl.format(PersonMessages.divorced)}
            </option>
            <option value={englishIntl.format(PersonMessages.remarried)}>
              {intl.format(PersonMessages.remarried)}
            </option>
          </Select>
        </FormControl>
      </div>
      <div style={{ marginTop: '12px' }}>
        <Caption text={deathNotice} />
      </div>
      <div>
        <TextField
          id="deathDate"
          label={intl.format(PersonMessages.dateOfDeath)}
          type="date"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={setData}
          value={deathDate}
        />
      </div>
      <TextField
        id="currentAddress"
        label={intl.format(PersonMessages.currentAddress)}
        margin="normal"
        variant="outlined"
        fullWidth
        onChange={setData}
        value={currentAddress}
      />
      <TextField
        id="email"
        label={intl.format(PersonMessages.emailAddress)}
        margin="normal"
        variant="outlined"
        fullWidth
        onChange={setData}
        value={email}
      />
    </>
  );
};

export default connect(mapStateToProps, {})(Person);
