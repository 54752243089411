import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { StyleRules } from '@material-ui/styles/withStyles';
/* eslint-disable import/no-extraneous-dependencies */
import {
  ApplicantFormData,
  CanadaVisitData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import {
  format,
  isValid,
} from 'date-fns';
import React, { useState } from 'react';
import {
  ChangeEventWithId,
  ChangeTextInputEvent,
} from '../../abbreviation-types';
import EditApi from '../../apis/edit';
import PublicEditContext from '../../contexts/publicEdit';
import IntlMessage from '../../intl';
import VisitedCanadaMessages from '../../intl/messages/components/forms/register/VisitedCanada';
import { positionType } from '../../modules/general';
import { toPascalCase } from '../../utils/format';
import SubTitle from '../application/SubTitle';
import EditableCardHeader from '../console/EditableCardHeader';

const useStyles = makeStyles((theme: Theme): StyleRules => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme: Theme): StyleRules => createStyles({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

type HeaderProps = {
  relationLabel: string;
  data: CanadaVisitData;
  handleEdit: () => void;
};

const CanadaEntryHistoryHeader = (props: HeaderProps): JSX.Element => {
  const { data, relationLabel, handleEdit } = props;
  const { originalEntry, recentEntry } = data;
  const classes = useStyles();

  return (
    <>
      <EditableCardHeader title="Entry History of Canada" edit={handleEdit} />
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Relation</StyledTableCell>
              <StyledTableCell align="center">Date of Original Entry</StyledTableCell>
              <StyledTableCell align="center">Place of Original Entry</StyledTableCell>
              <StyledTableCell align="center">Status of Original Entry</StyledTableCell>
              <StyledTableCell align="center">Date of Most Recent Entry</StyledTableCell>
              <StyledTableCell align="center">Place of Most Recent Entry</StyledTableCell>
              <StyledTableCell align="center">Status of Most Recent Entry</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data
              && (
                <TableRow>
                  <StyledTableCell align="center">{toPascalCase(relationLabel)}</StyledTableCell>
                  <StyledTableCell align="center">{originalEntry?.date}</StyledTableCell>
                  <StyledTableCell align="center">{originalEntry?.place}</StyledTableCell>
                  <StyledTableCell align="center">{originalEntry?.visa}</StyledTableCell>
                  <StyledTableCell align="center">{recentEntry?.date}</StyledTableCell>
                  <StyledTableCell align="center">{recentEntry?.place}</StyledTableCell>
                  <StyledTableCell align="center">{recentEntry?.visa}</StyledTableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

type EntryRow = {
  date?: string;
  place?: string;
  visa?: string;
  expiredDate?: string;
};

type InputProps = {
  intl: IntlMessage;
  originalEntry?: EntryRow;
  recentEntry?: EntryRow;
  setValue: (label: 'original' | 'recent', key: keyof EntryRow) => (event: ChangeTextInputEvent) => void;
  setDate: (label: 'original' | 'recent', name: keyof EntryRow) => (date: Date | null) => void;
};

const CanadaEntryHistoryInputs = (props: InputProps): JSX.Element => {
  const {
    intl,
    originalEntry,
    recentEntry,
    setValue,
    setDate,
  } = props;

  return (
    <>
      <SubTitle text={intl.format(VisitedCanadaMessages.originalEntryLabel)} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <KeyboardDatePicker
            id="date-originalEntry"
            format="yyyy-MM-dd"
            variant="inline"
            inputVariant="outlined"
            fullWidth
            label={intl.format(VisitedCanadaMessages.date)}
            value={originalEntry?.date ? `${originalEntry?.date}T12:00:00Z` : null}
            onChange={setDate('original', 'date')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            id="place-originalEntry"
            label={intl.format(VisitedCanadaMessages.place)}
            helperText="Border or Airport"
            variant="outlined"
            fullWidth
            onChange={setValue('original', 'place')}
            value={originalEntry?.place || ''}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            id="visa-originalEntry"
            label={intl.format(VisitedCanadaMessages.visa)}
            variant="outlined"
            helperText={intl.format(VisitedCanadaMessages.visaExample)}
            fullWidth
            onChange={setValue('original', 'visa')}
            value={originalEntry?.visa || ''}
          />
        </Grid>
      </Grid>
      <SubTitle text={intl.format(VisitedCanadaMessages.recentlyEntryLabel)} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <KeyboardDatePicker
            id="date-recentEntry"
            format="yyyy-MM-dd"
            variant="inline"
            inputVariant="outlined"
            fullWidth
            label={intl.format(VisitedCanadaMessages.date)}
            value={recentEntry?.date ? `${recentEntry?.date}T12:00:00Z` : null}
            onChange={setDate('recent', 'date')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            id="place-recentEntry"
            label={intl.format(VisitedCanadaMessages.place)}
            helperText="Border or Airport"
            variant="outlined"
            fullWidth
            onChange={setValue('recent', 'place')}
            value={recentEntry?.place || ''}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            id="visa-recentEntry"
            label={intl.format(VisitedCanadaMessages.visa)}
            variant="outlined"
            helperText={intl.format(VisitedCanadaMessages.visaExample)}
            fullWidth
            onChange={setValue('recent', 'visa')}
            value={recentEntry?.visa || ''}
          />
        </Grid>
      </Grid>
    </>
  );
};

type EditDialogProps = {
  intl: IntlMessage;
  isOpen: boolean;
  isLoading: boolean;
  originalEntry?: EntryRow;
  recentEntry?: EntryRow;
  setValue: (label: 'original' | 'recent', key: keyof EntryRow) => (event: ChangeTextInputEvent) => void;
  setDate: (label: 'original' | 'recent', name: keyof EntryRow) => (date: Date | null) => void;
  handleSave: () => Promise<void>;
  handleCancel: () => void;
};

const EditDialog = (props: EditDialogProps): JSX.Element => {
  const {
    intl,
    isOpen,
    isLoading,
    originalEntry,
    recentEntry,
    setValue,
    setDate,
    handleSave,
    handleCancel,
  } = props;

  return (
    <Dialog open={isOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Language</DialogTitle>
      <DialogContent>
        <CanadaEntryHistoryInputs
          intl={intl}
          originalEntry={originalEntry}
          recentEntry={recentEntry}
          setValue={setValue}
          setDate={setDate}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={isLoading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type Props = {
  position: positionType;
  data: CanadaVisitData;
  token: string;
};

const CanadaEntryHistory = (props: Props): JSX.Element => {
  const {
    position,
    data,
    token,
  } = props;
  const { allData, updateData } = React.useContext(PublicEditContext);
  const [nextCanadaVisitData, setNextCanadaVisitData] = useState<CanadaVisitData>(data);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  const intl = new IntlMessage('en');

  const handleEdit = (): void => {
    setIsOpen(true);
  };

  const handleCancel = (): void => {
    setNextCanadaVisitData(data);

    setIsOpen(false);
  };

  // eslint-disable-next-line max-len
  const handleChange = (label: 'original' | 'recent', key: keyof EntryRow) => (event: ChangeEventWithId): void => {
    const { value } = event.target;

    const nextValue: CanadaVisitData = {
      originalEntry: { ...nextCanadaVisitData.originalEntry },
      recentEntry: { ...nextCanadaVisitData.recentEntry },
    };

    if (label === 'original') {
      nextValue.originalEntry = {
        ...nextValue.originalEntry,
        [key]: value,
      };
    }
    if (label === 'recent') {
      nextValue.recentEntry = {
        ...nextValue.recentEntry,
        [key]: value,
      };
    }

    setNextCanadaVisitData({
      originalEntry: { ...nextValue.originalEntry },
      recentEntry: { ...nextValue.recentEntry },
    });
  };

  const setDate = (label: 'original' | 'recent', name: keyof EntryRow) => (date: Date | null): void => {
    if (isValid(date)) {
      const nextValue: CanadaVisitData = {
        originalEntry: { ...nextCanadaVisitData.originalEntry },
        recentEntry: { ...nextCanadaVisitData.recentEntry },
      };

      if (label === 'original') {
        nextValue.originalEntry = {
          ...nextValue.originalEntry,
          [name]: date ? format(date, 'yyyy-MM-dd') : null,
        };
      }
      if (label === 'recent') {
        nextValue.recentEntry = {
          ...nextValue.recentEntry,
          [name]: date ? format(date, 'yyyy-MM-dd') : null,
        };
      }

      setNextCanadaVisitData({
        originalEntry: { ...nextValue.originalEntry },
        recentEntry: { ...nextValue.recentEntry },
      });
    }
  };

  const handleSave = async (): Promise<void> => {
    let payroll: ApplicantFormData = { ...allData };

    if (position === 'applicant') {
      payroll = {
        ...allData,
        canadaVisitInfo: nextCanadaVisitData,
      };
    } else if (allData && allData.spouse) {
      payroll = {
        ...allData,
        spouse: {
          ...allData.spouse,
          canadaVisitInfo: nextCanadaVisitData,
        },
      };
    }

    updateData(payroll);

    if (token) {
      try {
        setLoadingStatus(true);
        await EditApi.update(token, payroll);
      } catch (e) {
        // TODO: Error Handling
        // if reached this area, the result is probably 404 status
        alert('Some error has occurred: CanadaEntryHistoryUpdate applicant');
      } finally {
        setLoadingStatus(false);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('token is null | undefined !!');
    }

    setLoadingStatus(false);
    setIsOpen(false);
  };

  return (
    <>
      <CanadaEntryHistoryHeader
        relationLabel={toPascalCase(position)}
        data={nextCanadaVisitData}
        handleEdit={handleEdit}
      />
      <EditDialog
        intl={intl}
        isOpen={isOpen}
        isLoading={isLoading}
        originalEntry={nextCanadaVisitData.originalEntry}
        recentEntry={nextCanadaVisitData.recentEntry}
        setValue={handleChange}
        setDate={setDate}
        handleSave={handleSave}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default CanadaEntryHistory;
