import { defineMessages } from 'react-intl';
import japanese from '../../locales/ja.json';

const RegisterTypeMessages = defineMessages({
  title: {
    id: 'registerType.title',
    defaultMessage: japanese['registerType.title'],
  },
  visaLabel: {
    id: 'registerType.visaLabel',
    defaultMessage: japanese['registerType.visaLabel'],
  },
  studyVisa: {
    id: 'registerType.studyVisa',
    defaultMessage: japanese['registerType.studyVisa'],
  },
  immigration: {
    id: 'registerType.immigration',
    defaultMessage: japanese['registerType.immigration'],
  },
  marriageLabel: {
    id: 'registerType.marriageLabel',
    defaultMessage: japanese['registerType.marriageLabel'],
  },
  neverMarried: {
    id: 'registerType.neverMarried',
    defaultMessage: japanese['registerType.neverMarried'],
  },
  married: {
    id: 'registerType.married',
    defaultMessage: japanese['registerType.married'],
  },
  divorced: {
    id: 'registerType.divorced',
    defaultMessage: japanese['registerType.divorced'],
  },
  remarried: {
    id: 'registerType.remarried',
    defaultMessage: japanese['registerType.remarried'],
  },
  childLabel: {
    id: 'registerType.childLabel',
    defaultMessage: japanese['registerType.childLabel'],
  },
  haveChild: {
    id: 'registerType.haveChild',
    defaultMessage: japanese['registerType.haveChild'],
  },
  haveNoChild: {
    id: 'registerType.haveNoChild',
    defaultMessage: japanese['registerType.haveNoChild'],
  },
  goNext: {
    id: 'registerType.goNext',
    defaultMessage: japanese['registerType.goNext'],
  },
  errorVisaType: {
    id: 'registerType.errorVisaType',
    defaultMessage: japanese['registerType.errorVisaType'],
  },
  errorMarriageCondition: {
    id: 'registerType.errorMarriageCondition',
    defaultMessage: japanese['registerType.errorMarriageCondition'],
  },
  errorChildren: {
    id: 'registerType.errorChildren',
    defaultMessage: japanese['registerType.errorChildren'],
  },
});

export default RegisterTypeMessages;
