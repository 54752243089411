import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';
import { format, isValid } from 'date-fns';
/* eslint-disable import/no-extraneous-dependencies */
import {
  CanadaVisitData,
  ChildData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  ChangeEventWithName,
  ChangeInputEvent,
} from '../../abbreviation-types';
import EditApi from '../../apis/edit';
import PublicEditContext from '../../contexts/publicEdit';
import { LocaleState } from '../../modules/locale';
import { visaTypes } from '../../modules/person';
import calcAge from '../../utils/calculation';
import EditableCardHeader from '../console/EditableCardHeader';
import ChildrenList from '../forms/register/ChildrenList';

interface Props {
  data: ChildData[];
  visaType?: visaTypes;
  token: string;
}

interface ChildPros {
  data: ChildData;
  index: number;
}

const useStyles = makeStyles((theme: Theme): StyleRules => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme: Theme): StyleRules => createStyles({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function getRow(data: ChildData): JSX.Element {
  const {
    firstName,
    firstNameInMotherLanguage,
    lastName,
    lastNameInMotherLanguage,
    middleName,
    middleNameInMotherLanguage,
    birthDate,
    birthCity,
    birthCountry,
  } = data;

  if (!firstName && !lastName && !birthDate) {
    return (
      <TableRow>
        <StyledTableCell align="left">Data is Nothing</StyledTableCell>
        <StyledTableCell align="left" />
      </TableRow>
    );
  }

  return (
    <>
      <TableRow>
        <StyledTableCell align="left">First Name</StyledTableCell>
        <StyledTableCell align="left">{`${firstName} (${firstNameInMotherLanguage})`}</StyledTableCell>
      </TableRow>
      {
        !!middleName
        && !!middleNameInMotherLanguage
        && (
          <TableRow>
            <StyledTableCell align="left">Middle Name</StyledTableCell>
            <StyledTableCell align="left">{`${middleName} (${middleNameInMotherLanguage})`}</StyledTableCell>
          </TableRow>
        )
      }
      <TableRow>
        <StyledTableCell align="left">Last Name</StyledTableCell>
        <StyledTableCell align="left">{`${lastName} (${lastNameInMotherLanguage})`}</StyledTableCell>
      </TableRow>
      {
        !!birthDate
        && (
          <TableRow>
            <StyledTableCell align="left">Birth Date</StyledTableCell>
            <StyledTableCell align="left">
              {`${birthDate} (${calcAge(new Date(birthDate))} yrs)`}
            </StyledTableCell>
          </TableRow>
        )
      }
      <TableRow>
        <StyledTableCell align="left">Birth Country</StyledTableCell>
        <StyledTableCell align="left">{birthCountry}</StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell align="left">Birth City</StyledTableCell>
        <StyledTableCell align="left">{birthCity}</StyledTableCell>
      </TableRow>
    </>
  );
}

const ChildInformation = (props: ChildPros): JSX.Element => {
  const { data, index } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">{`Child #${index + 1}`}</StyledTableCell>
            <StyledTableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {getRow(data)}
        </TableBody>
      </Table>
    </Paper>
  );
};

const ChildrenInformation = (props: Props): JSX.Element => {
  const { data, visaType, token = '' } = props;
  const { allData, updateData } = React.useContext(PublicEditContext);
  const [isOpen, setIsOpen] = useState(false);
  const [children, setChildren] = useState<ChildData[]>();
  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  const locale: LocaleState = { langCode: 'en' };
  const init = {
    lastName: '',
    firstName: '',
    middleName: '',
    lastNameInMotherLanguage: '',
    firstNameInMotherLanguage: '',
    middleNameInMotherLanguage: '',
    birthDate: '',
    birthCountry: '',
    birthCity: '',
    marriageCondition: '',
    currentAddress: '',
    email: '',
    originalEntry: {
      date: '',
      place: '',
      visa: '',
      expiredDate: '',
    },
    recentEntry: {
      date: '',
      place: '',
      visa: '',
      expiredDate: '',
    },
  };

  useEffect((): void => {
    if (data) {
      setChildren(data);
    }
  }, [data, isOpen]);

  const handleEdit = (): void => {
    setIsOpen(true);
  };

  const handleCancel = (): void => {
    if (data) {
      setChildren(data);
    }

    setIsOpen(false);
  };

  const handleSave = async (): Promise<void> => {
    // Send data to DB
    const payroll = {
      ...allData,
      children,
    };

    updateData(payroll);

    if (token) {
      try {
        setLoadingStatus(true);
        await EditApi.update(token, payroll);
      } catch (e) {
        // TODO: Error Handling
        // if reached this area, the result is probably 404 status
        alert('Some error has occurred: ChildInformationUpdate');
      } finally {
        setLoadingStatus(false);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('token is null | undefined !!');
    }

    setIsOpen(false);
  };

  const handleChange = (index: number): (event: ChangeEventWithName) => void => (
    event: ChangeEventWithName,
  ): void => {
    const { value, name } = event.target;
    const newChildren = (children) ? children.slice() : [];

    if (!name) return;

    newChildren[index] = {
      ...newChildren[index],
      [name]: value,
    };

    setChildren(newChildren);
  };
  
  const setBirthDate = (index: number) => (date: Date | null) => {
    if (isValid(date)) {
      const newChildren = (children) ? children.slice() : [];
      const name = 'birthDate' as keyof ChildData;
      newChildren[index] = {
        ...newChildren[index],
        [name]: date ? format(date, 'yyyy-MM-dd') : null,
      };

      setChildren(newChildren);
    }
  };

  // eslint-disable-next-line max-len
  const setCanadaVisitData = (type: keyof CanadaVisitData, index: number): (event: ChangeInputEvent) => void => (event: ChangeInputEvent): void => {
    const { value, id } = event.target;
    const label = id.split('-')[0];
    const newChildren = (children) ? children.slice() : [];
    const payroll = Object.prototype.hasOwnProperty.call(event.target, 'checked') ? value === 'true' : value;

    if (!label) return;

    if (type === 'originalEntry') {
      newChildren[index] = {
        ...newChildren[index],
        originalEntry: {
          ...newChildren[index].originalEntry,
          [label]: payroll,
        },
      };
    } else {
      newChildren[index] = {
        ...newChildren[index],
        recentEntry: {
          ...newChildren[index].recentEntry,
          [label]: payroll,
        },
      };
    }

    setChildren(newChildren);
  };

  const setCanadaVisitDateData = (type: keyof CanadaVisitData, index: number, name: string) => (date: Date | null) => {
    if (!isValid(date)) {
      return;
    }

    const newChildren = (children) ? children.slice() : [];

    if (type === 'originalEntry') {
      newChildren[index] = {
        ...newChildren[index],
        originalEntry: {
          ...newChildren[index].originalEntry,
          [name]: date ? format(date, 'yyyy-MM-dd') : null,
        },
      };
    } else {
      newChildren[index] = {
        ...newChildren[index],
        recentEntry: {
          ...newChildren[index].recentEntry,
          [name]: date ? format(date, 'yyyy-MM-dd') : null,
        },
      };
    }

    setChildren(newChildren);
  };

  const addArrayData = (): void => {
    const newArray = (children) ? children.slice() : [];
    newArray.push(init);

    setChildren(newArray);
  };

  const removeArrayData = (index: number): void => {
    const newArray = (children) ? children.slice() : [];
    newArray.splice(index, 1);

    setChildren(newArray);
  };

  return (
    <>
      <EditableCardHeader title="Children Information" edit={handleEdit} />
      {
        data.map((child: ChildData, index: number): JSX.Element => (
          <ChildInformation data={child} index={index} key={child._id} />
        ))
      }
      {
        isOpen
        && (
          <Dialog open={isOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Travel History</DialogTitle>
            <DialogContent>
              {
                data && children
                && (
                  <ChildrenList
                    visaType={visaType}
                    data={{
                      children,
                    }}
                    locale={locale}
                    addArrayData={addArrayData}
                    removeArrayData={removeArrayData}
                    setCanadaVisitData={setCanadaVisitData}
                    setData={handleChange}
                    setBirthDate={setBirthDate}
                    setCanadaVisitDateData={setCanadaVisitDateData}
                  />
                )
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} disabled={isLoading}>
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary" disabled={isLoading}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
};

export default ChildrenInformation;
