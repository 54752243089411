/* eslint-disable import/no-extraneous-dependencies */
import {
  LanguageData,
  LanguageScoreData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */

const SET_ENGLISH = 'SET_ENGLISH';
const SET_FRENCH = 'SET_FRENCH';
export type positionType = 'applicant' | 'spouse';

export interface LanguageState {
  applicant: LanguageData;
  spouse: LanguageData;
}

interface SetEnglishAction {
  type: typeof SET_ENGLISH;
  position: positionType;
  label: keyof LanguageScoreData;
  payload: any;
}

interface SetFrenchAction {
  type: typeof SET_FRENCH;
  position: positionType;
  label: keyof LanguageScoreData;
  payload: any;
}

type LanguageActionTypes = SetEnglishAction | SetFrenchAction;

const initialState: LanguageState = {
  applicant: {
    english: {
      testName: '',
      listeningScore: 0,
      readingScore: 0,
      writingScore: 0,
      speakingScore: 0,
    },
    french: {
      testName: '',
      listeningScore: 0,
      readingScore: 0,
      writingScore: 0,
      speakingScore: 0,
    },
  },
  spouse: {
    english: {
      testName: '',
      listeningScore: 0,
      readingScore: 0,
      writingScore: 0,
      speakingScore: 0,
    },
    french: {
      testName: '',
      listeningScore: 0,
      readingScore: 0,
      writingScore: 0,
      speakingScore: 0,
    },
  },
};

export function LanguageReducer(
  state = initialState,
  action: LanguageActionTypes,
): LanguageState {
  switch (action.type) {
    case SET_ENGLISH:
      if (action.position === 'applicant') {
        return {
          applicant: {
            english: {
              ...state.applicant.english,
              [action.label]: action.payload,
            },
            french: state.applicant.french,
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          english: {
            ...state.spouse.english,
            [action.label]: action.payload,
          },
          french: state.spouse.french,
        },
      };
    case SET_FRENCH:
      if (action.position === 'applicant') {
        return {
          applicant: {
            french: {
              ...state.applicant.french,
              [action.label]: action.payload,
            },
            english: state.applicant.english,
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          french: {
            ...state.spouse.french,
            [action.label]: action.payload,
          },
          english: state.spouse.english,
        },
      };
    default:
      return state;
  }
}

export function setEnglish(
  position: positionType,
  label: keyof LanguageScoreData,
  value: any,
): LanguageActionTypes {
  return {
    type: SET_ENGLISH,
    position,
    label,
    payload: value,
  };
}

export function setFrench(
  position: positionType,
  label: keyof LanguageScoreData,
  value: any,
): LanguageActionTypes {
  return {
    type: SET_FRENCH,
    position,
    label,
    payload: value,
  };
}
