import {
  Theme,
  withTheme,
} from '@material-ui/core';
import Typography, { TypographyProps } from '@material-ui/core/Typography/Typography';
import * as React from 'react';

interface Props extends TypographyProps {
  theme: Theme;
  text: string;
}

const Title = (props: Props) => {
  const { theme, text } = props;
  return (
    <Typography
      variant="h6"
      gutterBottom
      style={{ textTransform: 'capitalize', color: theme.palette.primary.main }}
    >
      {text}
    </Typography>
  );
};

export default withTheme(Title);
