import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const ResidentHistoryMessages = defineMessages({
  address: {
    id: 'residentHistory.address',
    defaultMessage: Japanese['residentHistory.address'],
  },
  postalCode: {
    id: 'residentHistory.postalCode',
    defaultMessage: Japanese['residentHistory.postalCode'],
  },
  residentHistory: {
    id: 'residentHistory.residentHistory',
    defaultMessage: Japanese['residentHistory.residentHistory'],
  },
  forPast10Years: {
    id: 'residentHistory.forPast10Years',
    defaultMessage: Japanese['residentHistory.forPast10Years'],
  },
  add: {
    id: 'residentHistory.add',
    defaultMessage: Japanese['residentHistory.add'],
  },
  remove: {
    id: 'residentHistory.remove',
    defaultMessage: Japanese['residentHistory.remove'],
  },
  sort: {
    id: 'residentHistory.sort',
    defaultMessage: Japanese['residentHistory.sort'],
  },
  visa: {
    id: 'residentHistory.visa',
    defaultMessage: Japanese['residentHistory.visa'],
  },
  studyPermit: {
    id: 'residentHistory.studyPermit',
    defaultMessage: Japanese['residentHistory.studyPermit'],
  },
  workPermit: {
    id: 'residentHistory.workPermit',
    defaultMessage: Japanese['residentHistory.workPermit'],
  },
  permanentResidence: {
    id: 'residentHistory.permanentResidence',
    defaultMessage: Japanese['residentHistory.permanentResidence'],
  },
  citizenship: {
    id: 'residentHistory.citizenship',
    defaultMessage: Japanese['residentHistory.citizenship'],
  },
  noPermit: {
    id: 'residentHistory.noPermit',
    defaultMessage: Japanese['residentHistory.noPermit'],
  },
});

export default ResidentHistoryMessages;
