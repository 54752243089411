import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { GeneralData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies
import React from 'react';
import { ChangeEventWithName } from '../../../abbreviation-types';
import IntlMessage from '../../../intl';
import { GeneralMessages } from '../../../intl/messages';
import { LocaleState } from '../../../modules/locale';
import { visaTypes } from '../../../modules/person';
import ErrorMessage from '../../application/ErrorMessage';
import SubTitle from '../../application/SubTitle';
import Title from '../../application/Title';
import Name from '../parts/Name';
import Place from '../parts/Place';

export interface Props {
  visaType?: visaTypes;
  shouldValidate?: boolean;
  locale: LocaleState;
  data: GeneralData;
  setData: (event: ChangeEventWithName) => void;
  setBirthDate: (date: Date | null) => void;
}

const GeneralForm = (props: Props): JSX.Element => {
  const {
    setData,
    setBirthDate,
    data,
    locale,
    shouldValidate,
  } = props;
  const {
    lastName,
    firstName,
    middleName,
    lastNameInMotherLanguage,
    firstNameInMotherLanguage,
    middleNameInMotherLanguage,
    birthDate,
    birthCountry,
    birthCity,
    height,
    eyeColor,
    currentAddress,
    email,
    homePhone,
    cellPhone,
    military,
    militaryDescription,
    criminal,
    criminalDescription,
    visaRefusal,
    visaRefusalDescription,
  } = data;
  const intl = new IntlMessage(locale.langCode);
  const isRequired = props && (typeof shouldValidate !== 'undefined');

  return (
    <div>
      <Title text={intl.format(GeneralMessages.generalInformation)} />
      <Name
        label={intl.format(GeneralMessages.english)}
        language=""
        setData={setData}
        firstName={firstName}
        lastName={lastName}
        middleName={middleName}
        originalFamilyName={null}
        error={shouldValidate}
      />
      <Name
        label={intl.format(GeneralMessages.motherLanguage)}
        language="InMotherLanguage"
        setData={setData}
        firstName={firstNameInMotherLanguage}
        lastName={lastNameInMotherLanguage}
        middleName={middleNameInMotherLanguage}
        originalFamilyName={null}
        error={shouldValidate}
      />
      <Place
        label={intl.format(GeneralMessages.placeOfBirth)}
        category="birth"
        setData={setData}
        country={birthCountry}
        city={birthCity}
      />
      <div>
        <KeyboardDatePicker
          format="yyyy-MM-dd"
          inputVariant="outlined"
          margin="normal"
          label={intl.format(GeneralMessages.dateOfBirth)}
          value={birthDate ? `${birthDate}T12:00:00Z` : null}
          onChange={setBirthDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </div>
      {
        shouldValidate && !birthDate
        && <ErrorMessage text={intl.format(GeneralMessages.errorDateOfBirth)} />
      }

      <TextField
        name="height"
        label={intl.format(GeneralMessages.height)}
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
        }}
        onChange={setData}
        value={height}
      />
      <Grid container spacing={3}>
        <Grid item xs={6} sm={4}>
          <FormControl
            variant="outlined"
            style={{ marginTop: 12, marginBottom: 12 }}
            fullWidth
          >
            <InputLabel htmlFor="eyeColor">
              {intl.format(GeneralMessages.eyeColor)}
            </InputLabel>
            <Select
              name="eyeColor"
              native
              value={eyeColor}
              onChange={setData}
              inputProps={{
                id: 'eyeColor',
              }}
              style={{ paddingLeft: 4, paddingRight: 4 }}
            >
              <option value="" />
              <option value="Black">{intl.format(GeneralMessages.black)}</option>
              <option value="Brown">{intl.format(GeneralMessages.brown)}</option>
              <option value="Blue">{intl.format(GeneralMessages.blue)}</option>
              <option value="Green">{intl.format(GeneralMessages.green)}</option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Divider variant="fullWidth" style={{ marginTop: 36, marginBottom: 36 }} />
      <SubTitle text={intl.format(GeneralMessages.contact)} />

      <TextField
        required={isRequired}
        name="email"
        label={intl.format(GeneralMessages.emailAddress)}
        margin="normal"
        variant="outlined"
        fullWidth
        onChange={setData}
        value={email}
      />
      {
        shouldValidate && !email
        && <ErrorMessage text={intl.format(GeneralMessages.errorEmail)} />
      }
      <TextField
        name="currentAddress"
        label={intl.format(GeneralMessages.currentAddress)}
        margin="normal"
        variant="outlined"
        fullWidth
        onChange={setData}
        value={currentAddress}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <TextField
            name="homePhone"
            label={intl.format(GeneralMessages.homePhone)}
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={setData}
            value={homePhone}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="cellPhone"
            label={intl.format(GeneralMessages.cellPhone)}
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={setData}
            value={cellPhone}
          />
        </Grid>
      </Grid>

      <Divider variant="fullWidth" style={{ marginTop: 36, marginBottom: 36 }} />
      <SubTitle text={intl.format(GeneralMessages.other)} />
      <div>
        <FormControlLabel
          control={(
            <Switch
              name="military"
              onChange={setData}
              checked={military}
              value={!military}
            />
          )}
          label={intl.format(GeneralMessages.militaryLabel)}
        />
      </div>
      {
        military
        && (
          <TextField
            name="militaryDescription"
            label={intl.format(GeneralMessages.pleaseProvideDetails)}
            variant="outlined"
            fullWidth
            onChange={setData}
            value={militaryDescription}
          />
        )
      }
      <div>
        <FormControlLabel
          control={(
            <Switch
              name="criminal"
              onChange={setData}
              checked={criminal}
              value={!criminal}
            />
          )}
          label={intl.format(GeneralMessages.criminalLabel)}
        />
      </div>
      {
        criminal
        && (
          <TextField
            name="criminalDescription"
            label={intl.format(GeneralMessages.pleaseProvideDetails)}
            variant="outlined"
            fullWidth
            onChange={setData}
            value={criminalDescription}
          />
        )
      }
      <div>
        <FormControlLabel
          control={(
            <Switch
              name="visaRefusal"
              onChange={setData}
              checked={visaRefusal}
              value={!visaRefusal}
            />
          )}
          label={intl.format(GeneralMessages.visaRefusalLabel)}
        />
      </div>
      {
        visaRefusal
        && (
          <TextField
            name="visaRefusalDescription"
            label={intl.format(GeneralMessages.pleaseProvideDetails)}
            variant="outlined"
            fullWidth
            onChange={setData}
            value={visaRefusalDescription}
          />
        )
      }
    </div>
  );
};

export default GeneralForm;
