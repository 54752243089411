import { defineMessages } from 'react-intl';
import Japanese from '../../locales/ja.json';

const RegisterFormMessages = defineMessages({
  thanks: {
    id: 'registerForm.thanks',
    defaultMessage: Japanese['registerForm.thanks'],
  },
  confirmation: {
    id: 'registerForm.confirmation',
    defaultMessage: Japanese['registerForm.confirmation'],
  },
  email: {
    id: 'registerForm.email',
    defaultMessage: Japanese['registerForm.email'],
  },
  register: {
    id: 'registerForm.register',
    defaultMessage: Japanese['registerForm.register'],
  },
  back: {
    id: 'registerForm.back',
    defaultMessage: Japanese['registerForm.back'],
  },
  registered: {
    id: 'registerForm.registered',
    defaultMessage: Japanese['registerForm.registered'],
  },
  registeredMessage: {
    id: 'registerForm.registeredMessage',
    defaultMessage: Japanese['registerForm.registeredMessage'],
  },
  closePage: {
    id: 'registerForm.closePage',
    defaultMessage: Japanese['registerForm.closePage'],
  },
});

export default RegisterFormMessages;
