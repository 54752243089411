function getFormattedDateTime(arg?: string | Date): string {
  if (!arg) {
    return '';
  }

  const date = (typeof arg === 'string') ? new Date(arg) : arg;
  return `${date.toLocaleString()}`;
}

function toPascalCase(target: string): string {
  if (!target) return '';
  return target.charAt(0).toUpperCase() + target.slice(1).toLowerCase();
}

function zeroPadding(num: number, digit = 2): string {
  let padding = '';
  for (let i = 0; i < digit; i += 1) {
    padding = `${padding}0`;
  }
  return (padding + num).slice(-digit);
}

function getFormattedDate(arg?: string | Date): string {
  if (!arg) {
    return '';
  }

  const date = (typeof arg === 'string') ? new Date(`${arg} 12:00:00`) : arg;
  return `${date.getFullYear()}-${zeroPadding(date.getMonth() + 1, 2)}-${zeroPadding(date.getDate(), 2)}`;
}

export {
  toPascalCase,
  getFormattedDate,
  getFormattedDateTime,
  zeroPadding,
};
