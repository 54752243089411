import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
/* eslint-disable import/no-extraneous-dependencies */
import { ResidentHistoryData } from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React from 'react';
import { connect } from 'react-redux';
import { ChangeEventWithName } from '../../../abbreviation-types';
import IntlMessage from '../../../intl';
import { ResidentHistoryMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';
import { PRESENT } from '../../../utils/constants';
import AdministrativeDivisions from '../../forms/parts/AdministrativeDivisions';
import DurationWithPresent from '../../forms/parts/DurationWithPresent';

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
});

type Props = {
  //  position: positionType;
  data?: ResidentHistoryData[];
  addArrayData: () => void;
  sortArrayData: () => void;
  removeArrayData: (index: number) => void;
  setData: (index: number) => (event: ChangeEventWithName) => void;
  setDuration: (index: number) => (name: 'start' | 'end') => (date: Date | typeof PRESENT | null) => void;
  locale: LocaleState;
};

const ResidentHistoryForm = (props: Props) => {
  const {
    data = [],
    addArrayData,
    sortArrayData,
    removeArrayData,
    setData,
    setDuration,
    locale,
  } = props;
  const intl = new IntlMessage(locale.langCode);

  return (
    <>
      {
        data.map((resident, index) => {
          const {
            postalCode,
            address,
            city,
            province,
            country,
            start,
            end,
            visa,
          } = resident;

          return (
            <Card style={{ marginBottom: 12 }} key={index}>
              <CardContent>
                <div>
                  <TextField
                    name="postalCode"
                    label={intl.format(ResidentHistoryMessages.postalCode)}
                    variant="outlined"
                    margin="normal"
                    onChange={setData(index)}
                    value={postalCode}
                    required
                  />
                </div>
                <div>
                  <TextField
                    name="address"
                    label={intl.format(ResidentHistoryMessages.address)}
                    variant="outlined"
                    margin="normal"
                    onChange={setData(index)}
                    value={address}
                    required
                    fullWidth
                  />
                </div>
                <AdministrativeDivisions
                  city={city}
                  province={province}
                  country={country}
                  setData={setData(index)}
                />
                <DurationWithPresent
                  setData={setDuration(index)}
                  start={start}
                  end={end}
                />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <FormControl variant="outlined" style={{ marginTop: 12, width: '100%' }} fullWidth>
                      <InputLabel htmlFor="visa">
                        {intl.format(ResidentHistoryMessages.visa)}
                      </InputLabel>
                      <Select
                        name="visa"
                        native
                        label={intl.format(ResidentHistoryMessages.visa)}
                        value={visa}
                        onChange={setData(index)}
                        inputProps={{
                          name: 'visa',
                        }}
                        style={{ paddingLeft: 4, paddingRight: 4 }}
                      >
                        <option aria-label="-" value="" />
                        <option value="Student">{intl.format(ResidentHistoryMessages.studyPermit)}</option>
                        <option value="Work">{intl.format(ResidentHistoryMessages.workPermit)}</option>
                        <option value="Permanent Residence">
                          {intl.format(ResidentHistoryMessages.permanentResidence)}
                        </option>
                        <option value="Citizen">{intl.format(ResidentHistoryMessages.citizenship)}</option>
                        <option value="No Permit">{intl.format(ResidentHistoryMessages.noPermit)}</option>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                  >
                    <IconButton aria-label="Delete" onClick={() => removeArrayData(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })
      }
      <div>
        <Button color="primary" onClick={addArrayData}>
          ADD
        </Button>
        <Button color="inherit" onClick={sortArrayData}>
          SORT
        </Button>
      </div>
    </>
  );
};

export default connect(mapStateToProps, {})(ResidentHistoryForm);
