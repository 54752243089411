import React from 'react';
import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { PreviousSpouseData } from 'common_parts';

interface Props {
  data: PreviousSpouseData[];
}

interface RowProps {
  key: number;
  data: PreviousSpouseData;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}));

const Row = (props: RowProps) => {
  const { data } = props;

  return (
    <TableRow>
      <TableCell align="center">
        <ruby>
          {data.firstNameInMotherLanguage}
          <rt>{data.firstName}</rt>
          {
            data.middleName && data.middleNameInMotherLanguage
            && (
              <>
                (
                {data.middleNameInMotherLanguage}
                <rt>{data.middleName}</rt>
                )
              </>
            )
          }
          {data.lastNameInMotherLanguage}
          <rt>{data.lastName}</rt>
        </ruby>
      </TableCell>
      <TableCell align="center">{data.birthDate}</TableCell>
      <TableCell align="center">{data.marriageDate}</TableCell>
      <TableCell align="center">{data.divorceDate}</TableCell>
    </TableRow>
  );
};


const PreviousSpouseTable = (props: Props) => {
  const { data } = props;
  const classes = useStyles();
  const cellClass = `${classes.head} ${classes.body}`;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={cellClass} align="center">Name</TableCell>
            <TableCell className={cellClass} align="center">Birth Of Date</TableCell>
            <TableCell className={cellClass} align="center">Marriage Date</TableCell>
            <TableCell className={cellClass} align="center">Divorce date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((resident, index) => (
            <Row key={index} data={resident} />
          ))}
        </TableBody>
      </Table>
    </Paper>

  )
}

export default PreviousSpouseTable;