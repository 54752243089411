import { TravelHistoryData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies

const SET_TRAVEL_HISTORY = 'SET_TRAVEL_HISTORY';
const ADD_ARRAY_TRAVEL = 'ADD_ARRAY_TRAVEL';
const REMOVE_ARRAY_TRAVEL = 'REMOVE_ARRAY_TRAVEL';
const SORT_ARRAY_TRAVEL = 'SORT_ARRAY_TRAVEL';
export type positionType = 'applicant' | 'spouse';

export interface TravelHistoryState {
  applicant: {
    travelHistory: TravelHistoryData[];
  };
  spouse: {
    travelHistory: TravelHistoryData[];
  };
}

interface SetTravelHistoryAction {
  type: typeof SET_TRAVEL_HISTORY;
  position: positionType;
  index: number;
  label: keyof TravelHistoryData;
  payload: any;
}

interface AddArrayAction {
  type: typeof ADD_ARRAY_TRAVEL;
  position: positionType;
}

interface RemoveArrayAction {
  type: typeof REMOVE_ARRAY_TRAVEL;
  position: positionType;
  index: number;
}

interface SortArrayAction {
  type: typeof SORT_ARRAY_TRAVEL;
  position: positionType;
}

type SiblingActionTypes =
  SetTravelHistoryAction
  | AddArrayAction
  | RemoveArrayAction
  | SortArrayAction;

const initialState: TravelHistoryState = {
  applicant: {
    travelHistory: [{
      start: '',
      end: '',
      country: '',
      city: '',
      purpose: '',
    }],
  },
  spouse: {
    travelHistory: [{
      start: '',
      end: '',
      country: '',
      city: '',
      purpose: '',
    }],
  },
};

function add(travelHistoryData: TravelHistoryData[]): TravelHistoryData[] {
  const newArray = travelHistoryData.slice();
  newArray.push({
    start: '',
    end: '',
    country: '',
    city: '',
    purpose: '',
  });
  return newArray;
}

function remove(travelHistoryData: TravelHistoryData[], index: number): TravelHistoryData[] {
  const newArray = travelHistoryData.slice();
  newArray.splice(index, 1);
  return newArray;
}

/**
 * TODO: Commonality
 * Sort the received TravelHistoryData array in the following order:
 *   1. "start" has been entered but "end" has not been entered
 *   2. when "start" and "end" are entered, sort "start" date by date
 *   3. "start" has not been entered
 *
 * @param travelHistoryData
 */
function sort(travelHistoryData: TravelHistoryData[]): TravelHistoryData[] {
  const newArray = travelHistoryData.slice();

  return newArray.sort((a, b) => {
    if (a.start && !a.end) {
      return -1;
    }
    if (b.start && !b.end) {
      return 1;
    }

    if (!a.start) {
      return 1;
    }
    if (!b.start) {
      return -1;
    }

    return new Date(b.start).getTime() - new Date(a.start).getTime();
  });
}

export function TravelHistoryReducer(
  state = initialState,
  action: SiblingActionTypes,
): TravelHistoryState {
  switch (action.type) {
    case SET_TRAVEL_HISTORY: {
      const {
        index, label, payload, position,
      } = action;
      if (position === 'applicant') {
        const newState = state.applicant.travelHistory.slice();
        newState[index] = {
          ...newState[index],
          [label]: payload,
        };
        return {
          applicant: {
            travelHistory: newState,
          },
          spouse: state.spouse,
        };
      }
      const newState = state.spouse.travelHistory.slice();
      newState[index] = {
        ...newState[index],
        [label]: payload,
      };
      return {
        applicant: state.applicant,
        spouse: {
          travelHistory: newState,
        },
      };
    }
    case ADD_ARRAY_TRAVEL:
      if (action.position === 'applicant') {
        return {
          applicant: {
            travelHistory: add(state.applicant.travelHistory),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          travelHistory: add(state.spouse.travelHistory),
        },
      };
    case REMOVE_ARRAY_TRAVEL:
      if (action.position === 'applicant') {
        return {
          applicant: {
            travelHistory: remove(state.applicant.travelHistory, action.index),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          travelHistory: remove(state.spouse.travelHistory, action.index),
        },
      };
    case SORT_ARRAY_TRAVEL:
      if (action.position === 'applicant') {
        return {
          applicant: {
            travelHistory: sort(state.applicant.travelHistory),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          travelHistory: sort(state.spouse.travelHistory),
        },
      };
    default:
      return state;
  }
}

export function setTravelHistory(
  position: positionType,
  index: number,
  label: keyof TravelHistoryData,
  value: any,
): SiblingActionTypes {
  return {
    type: SET_TRAVEL_HISTORY,
    position,
    index,
    label,
    payload: value,
  };
}

export function addArray(
  position: positionType,
): SiblingActionTypes {
  return {
    type: ADD_ARRAY_TRAVEL,
    position,
  };
}

export function removeArray(
  position: positionType,
  index: number,
): SiblingActionTypes {
  return {
    type: REMOVE_ARRAY_TRAVEL,
    position,
    index,
  };
}

export function sortArray(
  position: positionType,
): SiblingActionTypes {
  return {
    type: SORT_ARRAY_TRAVEL,
    position,
  };
}
