import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import PublicEditContext from '../../../contexts/publicEdit';
import EditApi from '../../../apis/edit';
import { positionType } from '../../../modules/previousSpouse';
import { PreviousSpouseData } from 'common_parts';
import EditableCardHeader from '../../console/EditableCardHeader';
import PreviousSpouseTable from './PreviousSpouseTable';
import PreviousSpouseForm from './PreviousSpouseForm';

interface Props {
  position: positionType;
  data?: PreviousSpouseData[];
  token: string;
};

const PreviousSpouse = (props: Props) => {
  const { position, data: previousSpouses, token } = props;
  const { allData, updateData } = useContext(PublicEditContext);
  const [
    previousSpouseData,
    setPreviousSpouseData,
  ] = useState<PreviousSpouseData[]>(previousSpouses || []);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoadingStatus] = useState<boolean>(false);

  const handleEdit = (): void => {
    setIsOpen(true);
  };

  const handleCancel = (): void => {
    if (previousSpouses) setPreviousSpouseData(previousSpouses);
    setIsOpen(false);
  };

  const handleSave = async (): Promise<void> => {
    if (!token) {
      // eslint-disable-next-line no-console
      console.error('token is null | undefined !!');
    }

    let payroll = { ...allData };
    if (position === 'applicant') {
      payroll = {
        ...allData,
        previousSpouses: previousSpouseData,
      };
    }
    if (position === 'spouse' && allData && allData.spouse) {
      payroll = {
        ...allData,
        spouse: {
          ...allData.spouse,
          previousSpouses: previousSpouseData,
        },
      };
    }

    updateData(payroll);

    try {
      setLoadingStatus(true);
      await EditApi.update(token, payroll);
    } catch (e) {
      // TODO: Error Handling
      // if reached this area, the result is probably 404 status
      alert(`Some error has occurred: Previous Spouse ${position}.`);
    } finally {
      setLoadingStatus(false);
    }

    setIsOpen(false);
  };

  const handleChange = (data: PreviousSpouseData[]) => {
    setPreviousSpouseData(data);
  }

  return (
    <>
      <EditableCardHeader title="Previous Spouse" edit={handleEdit} />
      <PreviousSpouseTable data={previousSpouses || []} />
      {
        isOpen
        && (
          <Dialog open={isOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Previous Spouse</DialogTitle>
            <DialogContent>
              <PreviousSpouseForm
                position={position}
                data={previousSpouseData}
                setData={handleChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} disabled={isLoading}>Cancel</Button>
              <Button onClick={handleSave} color="primary" disabled={isLoading}>Save</Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  )
}

export default PreviousSpouse;