import { defineMessages } from 'react-intl';
import Japanese from '../../../../locales/ja.json';

const VisitedCanadaMessages = defineMessages({
  visitCanadaInformation: {
    id: 'visitedCanada.visitCanadaInformation',
    defaultMessage: Japanese['visitedCanada.visitCanadaInformation'],
  },
  neverVisited: {
    id: 'visitedCanada.neverVisited',
    defaultMessage: Japanese['visitedCanada.neverVisited'],
  },
  originalEntryLabel: {
    id: 'visitedCanada.originalEntryLabel',
    defaultMessage: Japanese['visitedCanada.originalEntryLabel'],
  },
  date: {
    id: 'visitedCanada.date',
    defaultMessage: Japanese['visitedCanada.date'],
  },
  place: {
    id: 'visitedCanada.place',
    defaultMessage: Japanese['visitedCanada.place'],
  },
  visa: {
    id: 'visitedCanada.visa',
    defaultMessage: Japanese['visitedCanada.visa'],
  },
  visaExample: {
    id: 'visitedCanada.visaExample',
    defaultMessage: Japanese['visitedCanada.visaExample'],
  },
  expiredDate: {
    id: 'visitedCanada.expiredDate',
    defaultMessage: Japanese['visitedCanada.expiredDate'],
  },
  recentlyEntryLabel: {
    id: 'visitedCanada.recentlyEntryLabel',
    defaultMessage: Japanese['visitedCanada.recentlyEntryLabel'],
  },
});

export default VisitedCanadaMessages;
