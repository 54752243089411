import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
/* eslint-disable import/no-extraneous-dependencies */
import {
  ApplicantFormData,
  ChildData,
  GeneralData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme: Theme) => createStyles({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

interface Props {
  formData: ApplicantFormData;
}

const FamilyStructure = (props: Props) => {
  const { formData } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Relation</StyledTableCell>
            <StyledTableCell align="center">Name</StyledTableCell>
            <StyledTableCell align="center">Date of Birth</StyledTableCell>
            <StyledTableCell align="center">Place of Birth</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FamilyStructureRow data={formData.general} relation="Applicant" />
          {
            formData.spouse
            && <FamilyStructureRow data={formData.spouse.general} relation="Spouse" />
          }
          {
            formData.children
            && formData.children.map((child, index) => (
              <FamilyStructureRow
                key={`child-structure-row${child._id}`}
                data={child}
                relation={`Child #${index + 1}`}
              />
            ))
          }
        </TableBody>
      </Table>
    </Paper>
  );
};

interface RowProps {
  data: GeneralData | ChildData;
  relation: string;
}

const FamilyStructureRow = (props: RowProps) => {
  const { data, relation } = props;

  return (
    <TableRow>
      <StyledTableCell scope="row" align="center">{relation}</StyledTableCell>
      <StyledTableCell align="center">
        {`${data.firstName} ${data.lastName}`}
        <br />
        {`${data.firstNameInMotherLanguage} ${data.lastNameInMotherLanguage}`}
      </StyledTableCell>
      <StyledTableCell align="center">{data.birthDate}</StyledTableCell>
      <StyledTableCell align="center">
        {`${data.birthCity}${!!data.birthCity && !!data.birthCountry ? ', ' : ''}${data.birthCountry}`}
      </StyledTableCell>
    </TableRow>
  );
};

export default FamilyStructure;
