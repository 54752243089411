import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { KeyboardDatePicker } from '@material-ui/pickers';
/* eslint-disable import/no-extraneous-dependencies */
import { ParentData } from 'common_parts';
import {
  format,
  isValid,
} from 'date-fns';
/* eslint-enable import/no-extraneous-dependencies */
import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import { ChangeEventWithName } from '../../../abbreviation-types';
import EditApi from '../../../apis/edit';
import PublicEditContext from '../../../contexts/publicEdit';
import IntlMessage from '../../../intl';
import { GeneralMessages } from '../../../intl/messages';
import { positionType } from '../../../modules/general';
import EditableCardHeader from '../../console/EditableCardHeader';
import CurrentAddress from '../../forms/parts/CurrentAddress';
import MarriageCondition from '../../forms/parts/MarriageCondition';
import Name from '../../forms/parts/Name';
import Place from '../../forms/parts/Place';
import ParentsInformation from './ParentInformation';

type OwnProps = {
  position: positionType;
  token?: string;
  father?: ParentData;
  mother?: ParentData;
};

const FamilyStructure = (props: OwnProps): JSX.Element => {
  const {
    position,
    father,
    mother,
    token = '',
  } = props;
  const { allData, updateData } = React.useContext(PublicEditContext);
  const parentInitialState: ParentData = {
    firstName: '',
    firstNameInMotherLanguage: '',
    lastName: '',
    lastNameInMotherLanguage: '',
    birthCity: '',
    birthCountry: '',
    currentAddress: '',
  };
  const [fatherData, setFatherData] = useState<ParentData>(parentInitialState);
  const [motherData, setMotherData] = useState<ParentData>(parentInitialState);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  const intl = new IntlMessage('en');

  useEffect((): void => {
    if (father) setFatherData(father);
    if (mother) setMotherData(mother);
  }, [father, mother, isOpen]);

  const handleEdit = (): void => {
    setIsOpen(true);
  };

  const handleCancel = (): void => {
    if (father) setFatherData(father);
    if (mother) setMotherData(mother);

    setIsOpen(false);
  };

  const handleSave = async (): Promise<void> => {
    // Send data to DB
    let payroll = { ...allData };
    if (position === 'applicant') {
      payroll = {
        ...allData,
        father: fatherData,
        mother: motherData,
      };
    }
    if (position === 'spouse' && allData && allData.spouse) {
      payroll = {
        ...allData,
        spouse: {
          ...allData.spouse,
          father: fatherData,
          mother: motherData,
        },
      };
    }
    updateData(payroll);

    if (token) {
      try {
        setLoadingStatus(true);
        await EditApi.update(token, payroll);
      } catch (e) {
        // TODO: Error Handling
        // if reached this area, the result is probably 404 status
        alert('Some error has occurred: ApplicantParentsUpdate applicant');
      } finally {
        setLoadingStatus(false);
      }
    } else {
      // eslint-disable-next-line
      console.error('token is null | undefined !!');
    }

    setIsOpen(false);
  };

  const handleChange = (label: 'father' | 'mother') => (event: ChangeEventWithName): void => {
    const name = event.target.name || '';
    const { value } = event.target;

    switch (label) {
      case 'father':
        setFatherData({
          ...fatherData,
          [name]: value,
        });
        break;
      case 'mother':
      default:
        setMotherData({
          ...motherData,
          [name]: value,
        });
        break;
    }
  };

  const setDate = (label: 'father' | 'mother', name: 'birthDate' | 'deathDate') => (date: Date | null) => {
    if (isValid(date)) {
      switch (label) {
        case 'father':
          setFatherData({
            ...fatherData,
            [name]: date ? format(date, 'yyyy-MM-dd') : null,
          });
          break;
        case 'mother':
        default:
          setMotherData({
            ...motherData,
            [name]: date ? format(date, 'yyyy-MM-dd') : null,
          });
          break;
      }
    }
  };

  const handleDeathSwitch = (label: 'father' | 'mother') => (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const { checked } = target;

    switch (label) {
      case 'mother':
        if (checked) {
          setMotherData({
            ...motherData,
            currentAddress: '',
            deathDate: '',
          });
        } else {
          const next = {
            ...motherData,
            currentAddress: '',
          };
          delete next.deathDate;
          setMotherData(next);
        }
        break;
      case 'father':
      default:
        if (checked) {
          setFatherData({
            ...fatherData,
            currentAddress: '',
            deathDate: '',
          });
        } else {
          const next = {
            ...fatherData,
            currentAddress: '',
          };
          delete next.deathDate;
          setFatherData(next);
        }
    }
  };

  return (
    <>
      <EditableCardHeader title="Parents Information" edit={handleEdit} />
      <ParentsInformation father={fatherData} mother={motherData} />
      {
        isOpen
        && (
          <Dialog open={isOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Parents</DialogTitle>
            <DialogContent>
              <Card style={{ marginBottom: 12 }}>
                <CardContent>
                  Father
                  <Name
                    label={intl.format(GeneralMessages.english)}
                    language=""
                    setData={handleChange('father')}
                    firstName={fatherData.firstName}
                    lastName={fatherData.lastName}
                    middleName={fatherData.middleName}
                    originalFamilyName={null}
                  />
                  <Name
                    label={intl.format(GeneralMessages.motherLanguage)}
                    language="InMotherLanguage"
                    setData={handleChange('father')}
                    firstName={fatherData.firstNameInMotherLanguage}
                    lastName={fatherData.lastNameInMotherLanguage}
                    middleName={fatherData.middleNameInMotherLanguage}
                    originalFamilyName={null}
                  />
                  <div>
                    <KeyboardDatePicker
                      name="birthDate"
                      format="yyyy-MM-dd"
                      variant="inline"
                      inputVariant="outlined"
                      margin="normal"
                      label={intl.format(GeneralMessages.dateOfBirth)}
                      value={fatherData.birthDate ? `${fatherData.birthDate}T12:00:00Z` : null}
                      onChange={setDate('father', 'birthDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </div>
                  <Place
                    label={intl.format(GeneralMessages.placeOfBirth)}
                    category="birth"
                    setData={handleChange('father')}
                    country={fatherData.birthCountry}
                    city={fatherData.birthCity}
                  />
                  <MarriageCondition
                    value={fatherData.marriageCondition}
                    setValue={handleChange('father')}
                  />
                  <TextField
                    name="email"
                    label={intl.format(GeneralMessages.emailAddress)}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange('father')}
                    value={fatherData.email}
                  />
                  <CurrentAddress
                    deathDate={fatherData.deathDate}
                    currentAddress={fatherData.currentAddress}
                    handleDeathSwitch={handleDeathSwitch('father')}
                    setValue={handleChange('father')}
                    setDate={setDate('father', 'deathDate')}
                  />
                </CardContent>
              </Card>
              <Card style={{ marginBottom: 12 }}>
                <CardContent>
                  Mother
                  <Name
                    label={intl.format(GeneralMessages.english)}
                    language=""
                    setData={handleChange('mother')}
                    firstName={motherData.firstName}
                    lastName={motherData.lastName}
                    middleName={motherData.middleName}
                    originalFamilyName={motherData.originalFamilyName}
                    error
                  />
                  <Name
                    label={intl.format(GeneralMessages.motherLanguage)}
                    language="InMotherLanguage"
                    setData={handleChange('mother')}
                    firstName={motherData.firstNameInMotherLanguage}
                    lastName={motherData.lastNameInMotherLanguage}
                    middleName={motherData.middleNameInMotherLanguage}
                    originalFamilyName={motherData.originalFamilyNameInMotherLanguage}
                    error
                  />
                  <div>
                    <KeyboardDatePicker
                      name="birthDate"
                      format="yyyy-MM-dd"
                      variant="inline"
                      inputVariant="outlined"
                      margin="normal"
                      label={intl.format(GeneralMessages.dateOfBirth)}
                      value={motherData.birthDate ? `${motherData.birthDate}T12:00:00Z` : null}
                      onChange={setDate('mother', 'birthDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </div>
                  <Place
                    label={intl.format(GeneralMessages.placeOfBirth)}
                    category="birth"
                    setData={handleChange('mother')}
                    country={motherData.birthCountry}
                    city={motherData.birthCity}
                  />
                  <MarriageCondition
                    value={motherData.marriageCondition}
                    setValue={handleChange('mother')}
                  />
                  <TextField
                    name="email"
                    label={intl.format(GeneralMessages.emailAddress)}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange('mother')}
                    value={motherData.email}
                  />
                  <CurrentAddress
                    deathDate={motherData.deathDate}
                    currentAddress={motherData.currentAddress}
                    handleDeathSwitch={handleDeathSwitch('mother')}
                    setValue={handleChange('mother')}
                    setDate={setDate('mother', 'deathDate')}
                  />
                </CardContent>
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} disabled={isLoading}>Cancel</Button>
              <Button onClick={handleSave} color="primary" disabled={isLoading}>Save</Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
};

export default FamilyStructure;
