import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
/* eslint-disable import/no-extraneous-dependencies */
import {
  ApplicantFormData,
  AuthorityPermission,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import PublishApi from '../../apis/publish';

const initialEditingPermission: AuthorityPermission = {
  general: false,
  workExperiences: false,
  travelHistory: false,
  spouse: false,
  siblings: false,
  parents: false,
  languageExamScores: false,
  educationHistory: false,
  children: false,
  canadaEntryHistory: false,
  residentHistory: false,
  previousSpouses: false,
};

type OwnProps = {
  applicant: ApplicantFormData | null;
};

const ApplicantPublishSwitch = (props: OwnProps): JSX.Element => {
  const { applicant } = props;

  const applicantId = applicant?._id || '';
  const [publishToken, setPublishToken] = useState<string | null>(null);
  const [switchStatus, setSwitchStatus] = useState<boolean>(false);
  const [isGetStatusLoading, setGetStatusLoading] = useState<boolean>(false);
  const [isCreatePublishLoading, setCreatePublishLoading] = useState<boolean>(false);
  const [isDeletePublishLoading, setDeletePublishLoading] = useState<boolean>(false);
  const [isUpdatePublishLoading, setUpdatePublishLoading] = useState<boolean>(false);
  const [editingPermission, setEditingPermission] = useState<AuthorityPermission | null>(null);
  const isLoading = isGetStatusLoading || isCreatePublishLoading || isDeletePublishLoading;

  useEffect(() => {
    async function getStatus(): Promise<void> {
      setGetStatusLoading(true);
      if (applicantId) {
        const { data } = await PublishApi.get(applicantId);
        if (data?.token !== publishToken) {
          if (data?.permission) {
            setEditingPermission({ ...data.permission });
          } else {
            setEditingPermission({ ...initialEditingPermission });
          }

          setPublishToken(data?.token);
        }
      }
      setGetStatusLoading(false);
    }

    getStatus();
    setSwitchStatus(!!publishToken);
  }, [applicantId, publishToken]);

  const createPublishAuth = async (): Promise<void> => {
    if (!applicantId) return;

    setCreatePublishLoading(true);
    const { data } = await PublishApi.create(applicantId);
    const { token } = data;
    if (token !== publishToken) setPublishToken(token);
    setCreatePublishLoading(false);
  };

  const updatePublishAuthPermissions = async (
    permissions: AuthorityPermission,
  ): Promise<void> => {
    if (!applicantId) return;

    setUpdatePublishLoading(true);
    const { data } = await PublishApi.update(applicantId, permissions);
    const { token } = data;
    if (token !== publishToken) setPublishToken(token);
    setUpdatePublishLoading(false);
  };

  const deletePublishAuth = async (): Promise<void> => {
    if (!applicantId || !publishToken) return;

    setDeletePublishLoading(true);
    await PublishApi.delete(applicantId);
    setPublishToken(null);
    setEditingPermission(null);
    setDeletePublishLoading(false);
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const willBeOn = event.target.checked;

    if (willBeOn) {
      createPublishAuth();
    } else {
      deletePublishAuth();
    }

    setSwitchStatus(willBeOn);
  };

  const handlePermissionChanged = (
    key: keyof AuthorityPermission,
  ) => (event: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;
    const nextPermissions = {
      ...editingPermission,
      [key]: checked,
    };

    updatePublishAuthPermissions({ ...nextPermissions });
    setEditingPermission({ ...nextPermissions });
  };

  return (
    <div style={{ marginTop: '16px', marginBottom: '16px' }}>
      Publish Switch:
      <Switch
        checked={switchStatus}
        disabled={isLoading}
        onChange={handleSwitch}
        color="primary"
        name="checkPublishAuth"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      {
        publishToken
        && (
          <>
            <TextField
              id="publishUrl"
              label="Publish URL"
              InputProps={{ readOnly: true }}
              variant="outlined"
              value={`https://${window.location.host}/${publishToken}`}
            />
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2">
                  Customer-side Editing Permission
                </Typography>
                <FormGroup row>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={editingPermission?.general}
                        onChange={handlePermissionChanged('general')}
                        name="allowGeneralEdit"
                        color="primary"
                        disabled={isUpdatePublishLoading}
                      />
                    )}
                    label="General"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={editingPermission?.spouse}
                        onChange={handlePermissionChanged('spouse')}
                        name="allowSpouseEdit"
                        color="primary"
                        disabled={isUpdatePublishLoading}
                      />
                    )}
                    label="Spouse"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={editingPermission?.parents}
                        onChange={handlePermissionChanged('parents')}
                        name="allowParentsEdit"
                        color="primary"
                        disabled={isUpdatePublishLoading}
                      />
                    )}
                    label="Parents"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={editingPermission?.siblings}
                        onChange={handlePermissionChanged('siblings')}
                        name="allowSiblingsEdit"
                        color="primary"
                        disabled={isUpdatePublishLoading}
                      />
                    )}
                    label="Siblings"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={editingPermission?.residentHistory}
                        onChange={handlePermissionChanged('residentHistory')}
                        name="allowResidentHistoryEdit"
                        color="primary"
                        disabled={isUpdatePublishLoading}
                      />
                    )}
                    label="ResidentHistory"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={editingPermission?.previousSpouses}
                        onChange={handlePermissionChanged('previousSpouses')}
                        name="allowPreviousSpousesEdit"
                        color="primary"
                        disabled={isUpdatePublishLoading}
                      />
                    )}
                    label="PreviousSpouses"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={editingPermission?.children}
                        onChange={handlePermissionChanged('children')}
                        name="allowChildrenEdit"
                        color="primary"
                        disabled={isUpdatePublishLoading}
                      />
                    )}
                    label="Children"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={editingPermission?.educationHistory}
                        onChange={handlePermissionChanged('educationHistory')}
                        name="allowEducationHistoryEdit"
                        color="primary"
                        disabled={isUpdatePublishLoading}
                      />
                    )}
                    label="EducationHistory"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={editingPermission?.languageExamScores}
                        onChange={handlePermissionChanged('languageExamScores')}
                        name="allowLanguageExamScoresEdit"
                        color="primary"
                        disabled={isUpdatePublishLoading}
                      />
                    )}
                    label="LanguageExamScores"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={editingPermission?.travelHistory}
                        onChange={handlePermissionChanged('travelHistory')}
                        name="allowTravelHistoryEdit"
                        color="primary"
                        disabled={isUpdatePublishLoading}
                      />
                    )}
                    label="TravelHistory"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={editingPermission?.workExperiences}
                        onChange={handlePermissionChanged('workExperiences')}
                        name="allowWorkExperiencesEdit"
                        color="primary"
                        disabled={isUpdatePublishLoading}
                      />
                    )}
                    label="WorkExperiences"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={editingPermission?.canadaEntryHistory}
                        onChange={handlePermissionChanged('canadaEntryHistory')}
                        name="allowCanadaEntryHistoryEdit"
                        color="primary"
                        disabled={isUpdatePublishLoading}
                      />
                    )}
                    label="CanadaEntryHistory"
                  />
                </FormGroup>
              </CardContent>
            </Card>
          </>
        )
      }
    </div>
  );
};

export default ApplicantPublishSwitch;
