import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import { SiblingData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies
import React, {
  ChangeEvent,
  Component,
} from 'react';
import { connect } from 'react-redux';
import IntlMessage from '../../../intl';
import { SiblingsMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';
import {
  addArray,
  positionType,
  removeArray,
  setIsNothing,
  setSibling,
  SiblingState,
} from '../../../modules/sibling';
import Title from '../../application/Title';
import Person from '../parts/Person';

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
  data: state.sibling,
});

interface Props {
  position: positionType;
  data: SiblingState;
  locale: LocaleState;
  setSiblingData: typeof setSibling;
  setIsNothingData: typeof setIsNothing;
  addArrayData: typeof addArray;
  removeArrayData: typeof removeArray;
}

class Siblings extends Component<Props, {}> {
  private handleChangeSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    const { setIsNothingData, position } = this.props;
    setIsNothingData(position, event.target.checked);
  };

  private add = () => {
    const { addArrayData, position } = this.props;
    addArrayData(position);
  };

  private remove = (index: number) => () => {
    const { removeArrayData, position } = this.props;
    removeArrayData(position, index);
  };

  private setData = (index: number) => (event: ChangeEvent<{ name?: string; value?: unknown }>) => {
    const { value, name } = event.target;
    const { setSiblingData, position } = this.props;

    if (Object.prototype.hasOwnProperty.call(event.target, 'checked')) {
      setSiblingData(position, index, (name as keyof SiblingData), value === 'true');
    } else {
      setSiblingData(position, index, (name as keyof SiblingData), value);
    }
  };

  public render() {
    const { data, position, locale } = this.props;
    const {
      isNothing,
      siblings,
    } = position === 'applicant' ? data.applicant : data.spouse;
    const { length } = siblings;
    const list = [];
    const intl = new IntlMessage(locale.langCode);

    for (let i = 0; i < length; i += 1) {
      list.push(
        <React.Fragment key={i}>
          <Card style={{ marginBottom: 12 }}>
            <CardContent>
              <Person
                key={i}
                relationship={`${intl.format(SiblingsMessages.siblings)}`}
                isLabel
                setData={this.setData(i)}
                data={siblings[i]}
              />
              <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <IconButton aria-label="Delete" onClick={this.remove(i)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            </CardContent>
          </Card>
        </React.Fragment>,
      );
    }

    return (
      <div>
        <Title text={intl.format(SiblingsMessages.siblingsInformation)} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Switch
                  checked={isNothing}
                  onChange={this.handleChangeSwitch}
                  value
                />
              )}
              label={intl.format(SiblingsMessages.dontHaveSiblings)}
            />
          </Grid>
        </Grid>
        {!isNothing && list}
        {!isNothing
        && (
          <div>
            <Button color="primary" onClick={this.add}>
              {intl.format(SiblingsMessages.add)}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  {
    setSiblingData: setSibling,
    setIsNothingData: setIsNothing,
    addArrayData: addArray,
    removeArrayData: removeArray,
  },
)(Siblings);
