import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider/Divider';
import React from 'react';
import { connect } from 'react-redux';
import { AppContainerStyle } from '../assets/styles';
import ButtonLink from '../components/application/ButtonLink';
import SubTitle from '../components/application/SubTitle';
import Title from '../components/application/Title';
import IntelMessage from '../intl';
import { RegisterInfoMessages } from '../intl/messages';
import { AppState } from '../modules';
import { LocaleState } from '../modules/locale';
import { isFrogMode } from '../utils/params';

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
});

interface Props {
  locale: LocaleState;
}

const RegisterInfo = (props: Props) => {
  const { locale } = props;
  const intl = new IntelMessage(locale.langCode);

  return (
    <div style={AppContainerStyle}>
      <Title text={intl.format(RegisterInfoMessages.title)} />
      <Typography variant="body1" gutterBottom>
        {
          isFrogMode()
            ? intl.format(RegisterInfoMessages.frogGreeting)
            : intl.format(RegisterInfoMessages.greeting)
        }
        <br />
        {intl.format(RegisterInfoMessages.introduction)}
      </Typography>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        <SubTitle text={intl.format(RegisterInfoMessages.subtitle)} />
        <ul style={{ margin: 0, marginTop: -16 }}>
          <li>{intl.format(RegisterInfoMessages.familyInformation)}</li>
          <li>{intl.format(RegisterInfoMessages.educationHistory)}</li>
          <li>{intl.format(RegisterInfoMessages.workHistory)}</li>
          <li>{intl.format(RegisterInfoMessages.travelHistory)}</li>
        </ul>
      </div>
      <Divider variant="fullWidth" style={{ marginTop: 36, marginBottom: 36 }} />
      <ButtonLink variant="contained" color="primary" to="/register-type">
        {intl.format(RegisterInfoMessages.getStarted)}
      </ButtonLink>
    </div>
  );
};

export default connect(mapStateToProps, {})(RegisterInfo);
