import {
  withStyles,
  WithStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { CanadaVisitData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies
import React, { Component } from 'react';
import {
  ChangeEventWithName,
  ChangeInputEvent,
} from '../../../abbreviation-types';
import { commonStyle } from '../../../assets/styles';
import IntlMessage from '../../../intl';
import { ChildrenMessages } from '../../../intl/messages';
import VisitedCanadaMessages from '../../../intl/messages/components/forms/register/VisitedCanada';
import {
  addArray,
  ChildState,
  removeArray,
} from '../../../modules/child';
import { LocaleState } from '../../../modules/locale';
import { visaTypes } from '../../../modules/person';
import SubTitle from '../../application/SubTitle';
import Name from '../parts/Name';
import Place from '../parts/Place';

export interface Props extends WithStyles<typeof commonStyle> {
  locale: LocaleState;
  data: ChildState;
  visaType?: visaTypes;
  addArrayData: typeof addArray | (() => void);
  removeArrayData: typeof removeArray | ((index: number) => void);
  setCanadaVisitData: (
    type: keyof CanadaVisitData,
    index: number,
  ) => (event: ChangeInputEvent) => void;
  setCanadaVisitDateData: (
    type: keyof CanadaVisitData,
    index: number,
    name: string
  ) => (date: Date | null) => void;
  setData: (index: number) => (event: ChangeEventWithName) => void;
  setBirthDate: (index: number) => (date: Date | null) => void;
}

class ChildrenList extends Component<Props> {
  private add = (): void => {
    const { addArrayData } = this.props;

    addArrayData();
  };

  private remove = (index: number): () => void => (): void => {
    const { removeArrayData } = this.props;

    removeArrayData(index);
  };

  public render(): JSX.Element {
    const {
      visaType,
      locale,
      data,
      setData,
      setBirthDate,
      setCanadaVisitData,
      setCanadaVisitDateData,
    } = this.props;
    const isStudy = visaType === 'study';
    const list = [];
    const { length } = data.children;
    const intl = new IntlMessage(locale.langCode);

    for (let i = 0; i < length; i += 1) {
      const {
        firstName,
        lastName,
        middleName,
        firstNameInMotherLanguage,
        lastNameInMotherLanguage,
        middleNameInMotherLanguage,
        birthDate,
        birthCity,
        birthCountry,
        originalEntry,
        recentEntry,
      } = data.children[i];

      list.push(
        <React.Fragment key={i}>
          <Card style={{ marginBottom: 12 }}>
            <CardContent>
              <SubTitle text={`${intl.format(ChildrenMessages.child)}${i + 1}`} />
              <Name
                label={intl.format(ChildrenMessages.english)}
                language=""
                setData={setData(i)}
                firstName={firstName}
                lastName={lastName}
                middleName={middleName}
                originalFamilyName={null}
              />
              <Name
                label={intl.format(ChildrenMessages.motherLanguage)}
                language="InMotherLanguage"
                setData={setData(i)}
                firstName={firstNameInMotherLanguage}
                lastName={lastNameInMotherLanguage}
                middleName={middleNameInMotherLanguage}
                originalFamilyName={null}
              />
              <KeyboardDatePicker
                format="yyyy-MM-dd"
                variant="inline"
                inputVariant="outlined"
                margin="normal"
                style={{ marginBottom: 16 }}
                label={intl.format(ChildrenMessages.dateOfBirth)}
                value={birthDate ? `${birthDate}T12:00:00Z` : null}
                onChange={setBirthDate(i)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <Place
                label={intl.format(ChildrenMessages.placeOfBirth)}
                category="birth"
                setData={setData(i)}
                country={birthCountry}
                city={birthCity}
              />
              {
                !isStudy
                && (
                  <div>
                    <SubTitle text={intl.format(VisitedCanadaMessages.originalEntryLabel)} />
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6}>
                        <KeyboardDatePicker
                          id="date-originalEntry"
                          format="yyyy-MM-dd"
                          variant="inline"
                          inputVariant="outlined"
                          margin="normal"
                          style={{ marginBottom: 16 }}
                          fullWidth
                          label={intl.format(VisitedCanadaMessages.date)}
                          value={originalEntry && originalEntry.date ? `${originalEntry.date}T12:00:00Z` : null}
                          onChange={setCanadaVisitDateData('originalEntry', i, 'date')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="place-originalEntry"
                          label={intl.format(VisitedCanadaMessages.place)}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          onChange={setCanadaVisitData('originalEntry', i)}
                          value={originalEntry && originalEntry.place}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="visa-originalEntry"
                          label={intl.format(VisitedCanadaMessages.visa)}
                          variant="outlined"
                          margin="normal"
                          helperText={intl.format(VisitedCanadaMessages.visaExample)}
                          fullWidth
                          onChange={setCanadaVisitData('originalEntry', i)}
                          value={originalEntry && originalEntry.visa}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <KeyboardDatePicker
                          id="expiredDate-originalEntry"
                          format="yyyy-MM-dd"
                          variant="inline"
                          inputVariant="outlined"
                          margin="normal"
                          fullWidth
                          label={intl.format(VisitedCanadaMessages.expiredDate)}
                          value={originalEntry && originalEntry.expiredDate ? `${originalEntry.expiredDate}T12:00:00Z` : null}
                          onChange={setCanadaVisitDateData('originalEntry', i, 'expiredDate')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </Grid>
                    <SubTitle text={intl.format(VisitedCanadaMessages.recentlyEntryLabel)} />
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6}>
                        <KeyboardDatePicker
                          id="date-recentEntry"
                          format="yyyy-MM-dd"
                          variant="inline"
                          inputVariant="outlined"
                          margin="normal"
                          fullWidth
                          label={intl.format(VisitedCanadaMessages.date)}
                          value={recentEntry && recentEntry.date ? `${recentEntry.date}T12:00:00Z` : null}
                          onChange={setCanadaVisitDateData('recentEntry', i, 'date')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="place-recentEntry"
                          label={intl.format(VisitedCanadaMessages.place)}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          onChange={setCanadaVisitData('recentEntry', i)}
                          value={recentEntry && recentEntry.place}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="visa-recentEntry"
                          label={intl.format(VisitedCanadaMessages.visa)}
                          variant="outlined"
                          margin="normal"
                          helperText={intl.format(VisitedCanadaMessages.visaExample)}
                          fullWidth
                          onChange={setCanadaVisitData('recentEntry', i)}
                          value={recentEntry && recentEntry.visa}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <KeyboardDatePicker
                          id="expiredDate-recentEntry"
                          format="yyyy-MM-dd"
                          variant="inline"
                          inputVariant="outlined"
                          margin="normal"
                          fullWidth
                          label={intl.format(VisitedCanadaMessages.expiredDate)}
                          value={recentEntry && recentEntry.expiredDate ? `${recentEntry.expiredDate}T12:00:00Z` : null}
                          onChange={setCanadaVisitDateData('recentEntry', i, 'expiredDate')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )
              }
              <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <IconButton aria-label="Delete" onClick={this.remove(i)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            </CardContent>
          </Card>
        </React.Fragment>,
      );
    }

    return (
      <div>
        {list}
        <div>
          <Button color="primary" onClick={this.add}>
            {intl.format(ChildrenMessages.add)}
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(commonStyle)(ChildrenList);
