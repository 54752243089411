import {
  withStyles,
  WithStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MobileStepper from '@material-ui/core/MobileStepper/MobileStepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { format, isValid } from 'date-fns';
/* eslint-disable import/no-extraneous-dependencies */
import {
  EducationData,
  GeneralData,
  LanguageData,
  LanguageScoreData,
  TravelHistoryData,
  WorkExperienceData,
} from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  ChangeEventWithId,
  ChangeEventWithName,
} from '../../../abbreviation-types';
import { commonStyle } from '../../../assets/styles';
import IntlMessage from '../../../intl';
import { ApplicantMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import {
  addArray as addEducationArray,
  EducationState,
  removeArray as removeEducationArray,
  setEducation,
  setIsNothing as setEducationIsNothing,
  sortArray as sortEducationArray,
} from '../../../modules/education';
import {
  GeneralState,
  positionType,
  setGeneral,
} from '../../../modules/general';
import {
  LanguageState,
  setEnglish,
  setFrench,
} from '../../../modules/language';
import { LocaleState } from '../../../modules/locale';
import {
  PersonState,
  visaTypes,
} from '../../../modules/person';
import {
  addArray as addTravelArray,
  removeArray as removeTravelArray,
  setTravelHistory,
  sortArray as sortTravelArray,
  TravelHistoryState,
} from '../../../modules/travelHistory';
import {
  addArray as addWorkExperienceArray,
  removeArray as removeWorkExperienceArray,
  setIsNothing as setWorkExperienceIsNothing,
  setWorkExperience,
  sortArray as sortWorkExperienceArray,
  WorkExperienceState,
} from '../../../modules/workExperience';
import {
  addArray as addPreviousSpouseArray,
  removeArray as removePreviousSpouseArray,
  setData as setPreviousSpouse,
  PreviousSpouseState,
} from '../../../modules/previousSpouse';
import { PRESENT } from '../../../utils/constants';
import Caption from '../../application/Caption';
import Education from './Education';
import GeneralForm from './General';
import LanguageForm from './Language';
import Parent from './Parent';
import ResidentHistory from './ResidentHistory';
import Siblings from './Siblings';
import TravelHistory from './TravelHistory';
import VisitedCanada from './VisitedCanada';
import WorkExperience from './WorkExperience';
import PreviousSpouse from './PreviousSpouse'

const mapStateToProps = (state: AppState) => ({
  person: state.person,
  locale: state.locale,
  general: state.general,
  language: state.language,
  education: state.education,
  workexperience: state.workExperience,
  travelhistory: state.travelHistory,
  previousspouse: state.previousSpouse,
});

interface Props extends WithStyles<typeof commonStyle> {
  handleNext: () => void;
  person: PersonState;
  locale: LocaleState;
  general: GeneralState;
  language: LanguageState;
  education: EducationState;
  workexperience: WorkExperienceState;
  travelhistory: TravelHistoryState;
  previousspouse: PreviousSpouseState;
  setGeneralData: typeof setGeneral;
  setEnglishData: typeof setEnglish;
  setFrenchData: typeof setFrench;
  setEducationData: typeof setEducation;
  setEducationIsNothingData: typeof setEducationIsNothing;
  addEducationArrayData: typeof addEducationArray;
  removeEducationArrayData: typeof removeEducationArray;
  sortEducationArrayData: typeof sortEducationArray;
  setWorkExperienceData: typeof setWorkExperience;
  setWorkExperienceIsNothingData: typeof setWorkExperienceIsNothing;
  addWorkExperienceArrayData: typeof addWorkExperienceArray;
  removeWorkExperienceArrayData: typeof removeWorkExperienceArray;
  sortWorkExperienceArrayData: typeof sortWorkExperienceArray;
  setTravelHistoryData: typeof setTravelHistory;
  addTravelArrayData: typeof addTravelArray;
  removeTravelArrayData: typeof removeTravelArray;
  sortTravelArrayData: typeof sortTravelArray;
  setPreviousSpouseData: typeof setPreviousSpouse;
  addPreviousSpouseArrayData: typeof addPreviousSpouseArray;
  removePreviousSpouseArrayData: typeof removePreviousSpouseArray;
}

interface State {
  hashCode: string;
  activeStep: number;
  steps: string[];
  shouldValidate: {
    general: boolean;
  };
}

const defaultSteps = [
  'General',
  'Language',
  'Siblings',
  'Education',
  'Visit Canada',
  'Work Experience',
  'Travel History',
  'Resident History',
  'Parent',
  'Previous Spouse',
];

class ApplicantForm extends Component<Props, State> {
  private position = 'applicant' as positionType;

  public state = {
    hashCode: '',
    activeStep: 0,
    steps: defaultSteps,
    shouldValidate: {
      general: false,
    },
  };

  public componentDidMount() {
    const { person } = this.props;
    let { steps } = this.state;
    const { hashCode } = this.state;
    const { visaType, marriageCondition } = person.condition;
    const isStudy = visaType === 'study';

    if (!hashCode) {
      steps = steps.filter(
        step => (
          step !== 'Parent'
          && step !== 'Visit Canada'
          && step !== 'Siblings'
          && step !== 'Resident History'
        ),
      );
    }

    if (isStudy) {
      steps = steps.filter(step => step !== 'Siblings');
    }

    if (marriageCondition === 'neverMarried' || marriageCondition === 'married') {
      steps = steps.filter(step => step !== 'Previous Spouse');
    }

    this.setState({
      steps,
    });
  }

  private handleStep = (step: number) => () => {
    if (this.validate()) {
      this.setState(() => ({
        activeStep: step,
      }));
    }
  };

  private handleNext = () => {
    const { activeStep } = this.state;
    if (this.validate()) {
      this.setState(() => ({
        activeStep: activeStep + 1,
      }));
    }
  };

  private handleBack = () => {
    const { activeStep } = this.state;

    this.setState(() => ({
      activeStep: activeStep - 1,
    }));
  };

  private validate = () => {
    const { activeStep, steps } = this.state;
    const { general } = this.props;

    if (steps[activeStep] === 'General') {
      this.setState({
        shouldValidate: {
          general: true,
        },
      });

      if (!general.applicant.lastName
        || !general.applicant.firstName
        || !general.applicant.firstNameInMotherLanguage
        || !general.applicant.lastNameInMotherLanguage
        || !general.applicant.birthDate
        || !general.applicant.email
      ) {
        return false;
      }
    }
    return true;
  };

  private gotoNextStep = () => {
    const { handleNext } = this.props;
    handleNext();
  };

  private setGeneralDataWrapper = (event: ChangeEventWithName) => {
    const { setGeneralData } = this.props;
    const { value, name } = event.target;
    const position = 'applicant';

    if (Object.prototype.hasOwnProperty.call(event.target, 'checked')) {
      setGeneralData(position, (name as keyof GeneralData), value === 'true');
    } else {
      setGeneralData(position, (name as keyof GeneralData), value);
    }
  };

  private setBirthDate = (date: Date | null) => {
    if (isValid(date)) {
      const { setGeneralData } = this.props;
      const name = 'birthDate' as keyof GeneralData;
      const position = 'applicant';
      setGeneralData(position, name, date ? format(date, 'yyyy-MM-dd') : null);
    }
  };

  private setLanguageDataWrapper = (type: keyof LanguageData) => (event: ChangeEventWithId) => {
    const { value, id } = event.target;
    const label = id && id.split('-')[0];
    const { setEnglishData, setFrenchData } = this.props;
    const action = type === 'english' ? setEnglishData : setFrenchData;
    const position = 'applicant';

    if (label === 'testName') {
      action(position, (label as keyof LanguageScoreData), value);
    } else {
      action(position, (label as keyof LanguageScoreData), Number(value));
    }
  };

  private setEducationDataWrapper = (index: number) => (event: ChangeEventWithName) => {
    const { value, name } = event.target;
    const { setEducationData } = this.props;
    const position = 'applicant';

    if (Object.prototype.hasOwnProperty.call(event.target, 'checked')) {
      setEducationData(position, index, (name as keyof EducationData), value === 'true');
    } else {
      setEducationData(position, index, (name as keyof EducationData), value);
    }
  };

  private setEducationDuration = (index: number) => (name: 'start' | 'end') => (date: Date | null) => {
    if (isValid(date)) {
      const { setEducationData } = this.props;
      const position = 'applicant';
      setEducationData(position, index, name, date ? format(date, 'yyyy-MM-dd') : '');
    }
  };

  private setWorkExperienceDataWrapper = (index: number) => (event: ChangeEventWithName) => {
    const { value, name } = event.target;
    const { setWorkExperienceData } = this.props;
    const position = 'applicant';

    if (Object.prototype.hasOwnProperty.call(event.target, 'checked')) {
      setWorkExperienceData(position, index, (name as keyof WorkExperienceData), value === 'true');
    } else {
      setWorkExperienceData(position, index, (name as keyof WorkExperienceData), value);
    }
  };

  private setWorkExperienceDuration = (index: number) => (name: 'start' | 'end') => (date: Date | typeof PRESENT | null) => {
    const position = 'applicant';
    const { setWorkExperienceData } = this.props;

    if (!date) {
      setWorkExperienceData(position, index, name, null);
    } else if (date === PRESENT) {
      setWorkExperienceData(position, index, name, date);
    } else if (isValid(date)) {
      setWorkExperienceData(position, index, name, date ? format(date, 'yyyy-MM-dd') : '');
    }
  };

  private setTravelHistoryDataWrapper = (index: number) => (event: ChangeEventWithName) => {
    const { value, name } = event.target;
    const { setTravelHistoryData } = this.props;
    const position = 'applicant';

    if (Object.prototype.hasOwnProperty.call(event.target, 'checked')) {
      setTravelHistoryData(position, index, (name as keyof TravelHistoryData), value === 'true');
    } else {
      setTravelHistoryData(position, index, (name as keyof TravelHistoryData), value);
    }
  };

  private setTravelHistoryDuration = (index: number) => (name: 'start' | 'end') => (date: Date | null) => {
    if (isValid(date)) {
      const { setTravelHistoryData } = this.props;
      const position = 'applicant';
      setTravelHistoryData(position, index, name, date ? format(date, 'yyyy-MM-dd') : '');
    }
  };

  private getForm = (step: number, visaType?: visaTypes) => {
    const {
      steps,
      shouldValidate,
    } = this.state;

    const {
      locale,
      general,
      language,
      education,
      workexperience,
      travelhistory,
      previousspouse,
      setEducationIsNothingData,
      addEducationArrayData,
      removeEducationArrayData,
      sortEducationArrayData,
      setWorkExperienceIsNothingData,
      addWorkExperienceArrayData,
      removeWorkExperienceArrayData,
      sortWorkExperienceArrayData,
      addTravelArrayData,
      removeTravelArrayData,
      sortTravelArrayData,
      setPreviousSpouseData,
      addPreviousSpouseArrayData,
      removePreviousSpouseArrayData,
    } = this.props;

    const {
      setGeneralDataWrapper,
      setBirthDate,
      setLanguageDataWrapper,
      setEducationDataWrapper,
      setEducationDuration,
      setWorkExperienceDataWrapper,
      setWorkExperienceDuration,
      setTravelHistoryDataWrapper,
      setTravelHistoryDuration,
    } = this;

    let returnDOM: JSX.Element = (
      <div />
    );

    switch (steps[step]) {
      case 'General':
        returnDOM = (
          <GeneralForm
            locale={locale}
            data={general.applicant}
            visaType={visaType}
            shouldValidate={shouldValidate.general}
            setData={setGeneralDataWrapper}
            setBirthDate={setBirthDate}
          />
        );
        break;
      case 'Language':
        returnDOM = (
          <LanguageForm
            position={this.position}
            locale={locale}
            data={language}
            setData={setLanguageDataWrapper}
          />
        );
        break;
      case 'Parent':
        returnDOM = (
          <Parent
            position={this.position}
          />
        );
        break;
      case 'Siblings':
        returnDOM = (
          <Siblings
            position={this.position}
          />
        );
        break;
      case 'Education':
        returnDOM = (
          <Education
            position={this.position}
            data={education}
            locale={locale}
            setIsNothingData={setEducationIsNothingData}
            addArrayData={addEducationArrayData}
            removeArrayData={removeEducationArrayData}
            sortArrayData={sortEducationArrayData}
            setData={setEducationDataWrapper}
            setDuration={setEducationDuration}
          />
        );
        break;
      case 'Work Experience':
        returnDOM = (
          <WorkExperience
            visaType={visaType}
            position={this.position}
            data={workexperience}
            locale={locale}
            setIsNothingData={setWorkExperienceIsNothingData}
            addArrayData={addWorkExperienceArrayData}
            removeArrayData={removeWorkExperienceArrayData}
            sortArrayData={sortWorkExperienceArrayData}
            setData={setWorkExperienceDataWrapper}
            setDuration={setWorkExperienceDuration}
          />
        );
        break;
      case 'Visit Canada':
        returnDOM = (
          <VisitedCanada
            position={this.position}
          />
        );
        break;
      case 'Travel History':
        returnDOM = (
          <TravelHistory
            position={this.position}
            data={travelhistory}
            locale={locale}
            addArrayData={addTravelArrayData}
            removeArrayData={removeTravelArrayData}
            sortArrayData={sortTravelArrayData}
            setData={setTravelHistoryDataWrapper}
            setDuration={setTravelHistoryDuration}
          />
        );
        break;
      case 'Resident History':
        returnDOM = (
          <ResidentHistory
            position={this.position}
          />
        );
        break;
      case 'Previous Spouse':
        returnDOM = (
          <PreviousSpouse
            position={this.position}
            locale={locale}
            data={previousspouse}
            addArrayData={addPreviousSpouseArrayData}
            removeArrayData={removePreviousSpouseArrayData}
            setData={setPreviousSpouseData}
          />
        );
        break;
      default:
    }

    return returnDOM;
  };

  public render() {
    const { classes, person, locale } = this.props;
    const { activeStep, steps } = this.state;
    const { visaType, hasChild, marriageCondition } = person.condition;
    const formDOM = this.getForm(activeStep, visaType);
    const intl = new IntlMessage(locale.langCode);

    return (
      <div>
        <Grid container>
          <Hidden xsDown>
            <Grid item xs={12} sm={3}>
              <nav className={classes.control}>
                <p>{intl.format(ApplicantMessages.applicant)}</p>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>
                        <Button
                          onClick={this.handleStep(index)}
                          className={classes.controlButton}
                        >
                          {label}
                        </Button>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {
                  (
                    marriageCondition === 'married'
                    || marriageCondition === 'divorcedAndCurrentlyMarried'
                  )
                  && <p>{intl.format(ApplicantMessages.spouse)}</p>
                }
                {
                  hasChild
                  && <p>{intl.format(ApplicantMessages.children)}</p>
                }
              </nav>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={9} className={classes.formBg}>
            <div className={classes.main}>
              <Hidden smUp>
                <Caption text={intl.format(ApplicantMessages.applicantSection)} />
              </Hidden>
              {formDOM}
              <div>
                {activeStep === steps.length ? (
                  <div>
                    <Typography className={classes.instructions}>
                      {intl.format(ApplicantMessages.applicantCompleted)}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={this.gotoNextStep}>
                      {intl.format(ApplicantMessages.goNext)}
                    </Button>
                  </div>
                ) : (
                    <div className={classes.sectionBottom}>
                      <Hidden xsDown>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={this.handleBack}
                            className={classes.backButton}
                          >
                            {intl.format(ApplicantMessages.back)}
                          </Button>
                          <Button variant="contained" color="primary" onClick={this.handleNext}>
                            {intl.format(ApplicantMessages.next)}
                          </Button>
                        </div>
                      </Hidden>
                      <Hidden smUp>
                        <MobileStepper
                          variant="dots"
                          steps={steps.length}
                          position="static"
                          activeStep={activeStep}
                          nextButton={(
                            <Button size="small" onClick={this.handleNext}>
                              {intl.format(ApplicantMessages.next)}
                              <KeyboardArrowRight />
                            </Button>
                          )}
                          backButton={(
                            <Button
                              size="small"
                              onClick={this.handleBack}
                              disabled={activeStep === 0}
                            >
                              <KeyboardArrowLeft />
                              {intl.format(ApplicantMessages.back)}
                            </Button>
                          )}
                        />
                      </Hidden>
                    </div>
                  )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  {
    setGeneralData: setGeneral,
    setEnglishData: setEnglish,
    setFrenchData: setFrench,
    setEducationData: setEducation,
    setEducationIsNothingData: setEducationIsNothing,
    addEducationArrayData: addEducationArray,
    removeEducationArrayData: removeEducationArray,
    sortEducationArrayData: sortEducationArray,
    setWorkExperienceData: setWorkExperience,
    setWorkExperienceIsNothingData: setWorkExperienceIsNothing,
    addWorkExperienceArrayData: addWorkExperienceArray,
    removeWorkExperienceArrayData: removeWorkExperienceArray,
    sortWorkExperienceArrayData: sortWorkExperienceArray,
    setTravelHistoryData: setTravelHistory,
    addTravelArrayData: addTravelArray,
    removeTravelArrayData: removeTravelArray,
    sortTravelArrayData: sortTravelArray,
    setPreviousSpouseData: setPreviousSpouse,
    addPreviousSpouseArrayData: addPreviousSpouseArray,
    removePreviousSpouseArrayData: removePreviousSpouseArray,
  },
)(withStyles(commonStyle)(ApplicantForm));
