import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';
import { connect } from 'react-redux';
import IntlMessage from '../../../intl';
import { DurationMessages } from '../../../intl/messages';
import { AppState } from '../../../modules';
import { LocaleState } from '../../../modules/locale';

const mapStateToProps = (state: AppState) => ({
  locale: state.locale,
});

interface Props {
  setData: (name: 'start' | 'end') => (date: Date | null) => void;
  start?: string;
  end?: string;
  locale: LocaleState;
}

const Duration = (props: Props) => {
  const {
    setData, start, end, locale,
  } = props;
  const intl = new IntlMessage(locale.langCode);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormLabel>{intl.format(DurationMessages.duration)}</FormLabel>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: 0 }}>
        <Grid item xs={6} sm={6}>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            name="start"
            label={intl.format(DurationMessages.start)}
            variant="inline"
            inputVariant="outlined"
            fullWidth
            onChange={setData('start')}
            value={start ? `${start}T12:00:00Z` : null}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            name="end"
            label={intl.format(DurationMessages.end)}
            variant="inline"
            inputVariant="outlined"
            fullWidth
            onChange={setData('end')}
            value={end ? `${end}T12:00:00Z` : null}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default connect(mapStateToProps, {})(Duration);
