import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';
/* eslint-disable import/no-extraneous-dependencies */
import { EducationData } from 'common_parts';
/* eslint-enable import/no-extraneous-dependencies */
import React from 'react';

interface Props {
  data?: EducationData[] | 'none';
}

const useStyles = makeStyles((): StyleRules => createStyles({
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme: Theme): StyleRules => createStyles({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const EducationSummary = (props: Props): JSX.Element => {
  const { data } = props;
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <StyledTableCell align="center">School Name</StyledTableCell>
          <StyledTableCell align="center">Category</StyledTableCell>
          <StyledTableCell align="center">Major</StyledTableCell>
          <StyledTableCell align="center">Located At</StyledTableCell>
          <StyledTableCell align="center">Start</StyledTableCell>
          <StyledTableCell align="center">End</StyledTableCell>
          <StyledTableCell align="center">Visa</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          (
            data
            && data === 'none'
            && (
              <TableRow>
                None
              </TableRow>
            )
          ) || (
            data
            && Array.isArray(data)
            && data.map((education): JSX.Element => (
              <TableRow key={`${education.name}${education.type}`}>
                <StyledTableCell align="center">{education.name}</StyledTableCell>
                <StyledTableCell align="center">{education.type}</StyledTableCell>
                <StyledTableCell align="center">{education.major}</StyledTableCell>
                <StyledTableCell align="center">{education.country}</StyledTableCell>
                <StyledTableCell align="center">{education.start}</StyledTableCell>
                <StyledTableCell align="center">{education.end}</StyledTableCell>
                <StyledTableCell align="center">{education.visa}</StyledTableCell>
              </TableRow>
            ))
          )
        }
      </TableBody>
    </Table>
  );
};

export default EducationSummary;
