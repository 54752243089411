import { PreviousSpouseData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies

const SET_PREVIOUS_SPOUSE = 'SET_PREVIOUS_SPOUSE';
const ADD_ARRAY_PREVIOUS_SPOUSE = 'ADD_ARRAY_PREVIOUS_SPOUSE';
const REMOVE_ARRAY_PREVIOUS_SPOUSE = 'REMOVE_ARRAY_PREVIOUS_SPOUSE';
export type positionType = 'applicant' | 'spouse';

export interface PreviousSpouseState {
  applicant: {
    previousSpouses: PreviousSpouseData[];
  };
  spouse: {
    previousSpouses: PreviousSpouseData[];
  };
}

interface SetAction {
  type: typeof SET_PREVIOUS_SPOUSE;
  position: positionType;
  index: number;
  label: keyof PreviousSpouseData;
  payload: any;
}

interface AddArrayAction {
  type: typeof ADD_ARRAY_PREVIOUS_SPOUSE;
  position: positionType;
}

interface RemoveArrayAction {
  type: typeof REMOVE_ARRAY_PREVIOUS_SPOUSE;
  position: positionType;
  index: number;
}

type ActionTypes = SetAction | AddArrayAction | RemoveArrayAction;

export const initialPreviousSpouse = {
  lastName: '',
  firstName: '',
  middleName: '',
  lastNameInMotherLanguage: '',
  firstNameInMotherLanguage: '',
  middleNameInMotherLanguage: '',
  birthDate: '',
  marriageDate: '',
  divorceDate: '',
};

const initialState: PreviousSpouseState = {
  applicant: {
    previousSpouses: [{ ...initialPreviousSpouse }],
  },
  spouse: {
    previousSpouses: [{ ...initialPreviousSpouse }],
  },
};

function add(previousSpouseData: PreviousSpouseData[]): PreviousSpouseData[] {
  const newArray = previousSpouseData.slice();
  newArray.push({ ...initialPreviousSpouse });
  return newArray;
}

function remove(
  previousSpouseData: PreviousSpouseData[],
  index: number
): PreviousSpouseData[] {
  const newArray = previousSpouseData.slice();
  newArray.splice(index, 1);
  return newArray;
}

export function PreviousSpouseReducer(
  state = initialState,
  action: ActionTypes
): PreviousSpouseState {
  switch (action.type) {
    case SET_PREVIOUS_SPOUSE: {
      const { index, label, payload, position } = action;
      if (position === 'applicant') {
        const newState = state.applicant.previousSpouses.slice();
        newState[index] = {
          ...newState[index],
          [label]: payload,
        };
        return {
          applicant: {
            previousSpouses: newState,
          },
          spouse: state.spouse,
        };
      }
      const newState = state.spouse.previousSpouses.slice();
      newState[index] = {
        ...newState[index],
        [label]: payload,
      };
      return {
        applicant: state.applicant,
        spouse: {
          previousSpouses: newState,
        },
      };
    }
    case ADD_ARRAY_PREVIOUS_SPOUSE:
      if (action.position === 'applicant') {
        return {
          applicant: {
            previousSpouses: add(state.applicant.previousSpouses),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          previousSpouses: add(state.spouse.previousSpouses),
        },
      };
    case REMOVE_ARRAY_PREVIOUS_SPOUSE:
      if (action.position === 'applicant') {
        return {
          applicant: {
            previousSpouses: remove(
              state.applicant.previousSpouses,
              action.index
            ),
          },
          spouse: state.spouse,
        };
      }
      return {
        applicant: state.applicant,
        spouse: {
          previousSpouses: remove(state.spouse.previousSpouses, action.index),
        },
      };
    default:
      return state;
  }
}

export function setData(
  position: positionType,
  index: number,
  label: keyof PreviousSpouseData,
  value: any
): ActionTypes {
  return {
    type: SET_PREVIOUS_SPOUSE,
    position,
    index,
    label,
    payload: value,
  };
}

export function addArray(position: positionType): ActionTypes {
  return {
    type: ADD_ARRAY_PREVIOUS_SPOUSE,
    position,
  };
}

export function removeArray(
  position: positionType,
  index: number
): ActionTypes {
  return {
    type: REMOVE_ARRAY_PREVIOUS_SPOUSE,
    position,
    index,
  };
}
