import { ApplicantFormData } from 'common_parts'; // eslint-disable-line import/no-extraneous-dependencies
import { createContext } from 'react';

export interface PublicEditContextData {
  allData: ApplicantFormData;
  updateData: (data: ApplicantFormData) => void;
}

const PublicEditContext = createContext<PublicEditContextData>({
  allData: {} as ApplicantFormData,
  updateData: (data): void => {
    alert(data);
  },
});

export default PublicEditContext;
